import { Button, Drawer, FormControlLabel, RadioGroup, Radio, Tooltip, TextField, FormControl, Autocomplete } from "@mui/material";
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import "../../theme.styles.css";
import "../AwaitingActions/AwaitingActions.styles.css";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { checkoutConsolidation, markOrderPaid, orderCheckout } from "../../apis";
import { errorToast, successToast } from "../../components/toast/Toast";
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router";
import { Country, State, City } from "country-state-city";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Consolidation = ({
    open,
    handleClose,
    packages,
    packageFee
}) => {
    const navigate = useNavigate();
    const [selectedConsolidationType, setSelectedConsolidationType] = React.useState("");
    const [loading, setLoading] = useState(false);
    const [billingAdd, setBillingAdd] = useState({
        name: "",
        company: "",
        street1: "",
        street2: "",
        city: "",
        zip: "",
        state: "",
        country: "",
        email: "",
        phone: "",
        federal_tax_id: "",
    })
    const [countiresList, setCountriesList] = useState([]);
    const [billCitiesList, setBillCitiesList] = useState([]);
    const [billStatesList, setBillStatesList] = useState([]);
    const [selectedBillState, setSelectedBillState] = useState(null);
    const [selectBillCity, setSelectedBillCity] = useState(null);
    const [selectedBillCountry, setSelectedBillCountry] = useState(null);

    useEffect(() => {
        const list = Country.getAllCountries();
        if (list?.length) setCountriesList(list);
    }, [])

    const calculateInStoreDays = (receivedDate) => {
        let numberOfDays = 0;
        if (receivedDate) {
            const diff = Math.abs(new Date() - new Date(receivedDate));
            numberOfDays = Math.ceil(diff / (1000 * 3600 * 24))
        }
        return numberOfDays;
    }

    const calculateTotal = React.useCallback(() => {
        let total = 0;
        if (packageFee && packages?.length) {
            total = packages?.length * selectedConsolidationType;
        }

        return total;
    }, [selectedConsolidationType, packageFee, packages?.length])

    React.useEffect(() => {
        if (selectedConsolidationType) calculateTotal()
    }, [selectedConsolidationType, calculateTotal])

    const handleCheckout = (res, token) => {
        setLoading(true);
        const billObj = {
            ...billingAdd,
            country: selectedBillCountry?.isoCode,
            state: selectedBillState?.name || selectBillCity?.name,
            city: selectBillCity?.name || selectedBillState?.name
        }

        const data = {
            "packages": packages.map(elm => elm?._id),
            "consolidationFee": calculateTotal(),
            receiver: {
                billingAddress: billObj,
            }
        }

        checkoutConsolidation(data)
            .then((chk) => {
                if (chk.status === 200 && chk?.data?.orderNo) {
                    handleStripeCheckout({
                        orderNo: chk?.data?.orderNo,
                        source: token,
                        cardHolderName: res
                    })
                }
            })
            .catch(() => {
                errorToast("There was an error checking out. Please try again later!")
                setLoading(false);
            })

    }

    const handleStripeCheckout = (checkoutData) => {

        const data = {
            customer: {
                name: checkoutData?.cardHolderName
            },
            source: checkoutData.source,
            amount: calculateTotal(),
            orderNo: checkoutData.orderNo
        }

        orderCheckout(data)
            .then((chkout) => {
                if (chkout.status === 200) {
                    handleMarkOrderPaid(checkoutData.orderNo)
                }
            })
            .catch(() => {
                errorToast("There was an error checking out. Please try again later!")
                setLoading(false);
            })
    }

    const handleMarkOrderPaid = (orderNo) => {
        const data = {
            orderNo
        }
        markOrderPaid(data)
            .then((res) => {
                if (res?.status === 200) {
                    successToast("Order Paid Successfully")
                    navigate("/processing")
                    setLoading(false);
                }
            })
            .catch((err) => {
                setTimeout(() => {
                    navigate("/ship-consolidate")
                    setLoading(false);
                }, 2000)
            })
    }

    const handleBillingAddChange = (e) => {
        setBillingAdd((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    // Set states list for Biller
    useEffect(() => {
        if (selectedBillCountry) {
            const list = State.getStatesOfCountry(selectedBillCountry?.isoCode)
            if (list?.length) setBillStatesList(list);
        }
        else {
            setBillStatesList([])
            setSelectedBillState(null)
        }
    }, [selectedBillCountry])

    // Set cities list for Biller
    useEffect(() => {
        if (selectedBillState && selectedBillCountry) {
            const list = City.getCitiesOfState(selectedBillCountry?.isoCode, selectedBillState?.isoCode)
            if (list?.length) setBillCitiesList(list);
        }
        else {
            setBillCitiesList([])
            setSelectedBillCity(null)
        }
    }, [selectedBillState, selectedBillCountry])

    return (
        <Drawer
            anchor={"bottom"}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    height: 'calc(100vh - 80px)',
                    padding: '40px'
                }
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '32px' }}>
                        <h2 className='modal-heading'>Consolidation</h2>
                        <span style={{ cursor: 'pointer' }} onClick={handleClose}><CloseIcon /></span>
                    </div>
                    <div className="ship-consolidate-modal-body" style={{}}>
                        <div className='pkg-details-body' style={{ width: "100%" }}>
                            {
                                packages &&
                                packages.map((elm, ind) => (
                                    <div key={ind} className="row">
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Package No.</div>
                                            <div className='odr-de-value'>{elm.trackingNumber}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Package ID</div>
                                            <div className='odr-de-value'>{elm.packageId}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Date Received</div>
                                            <div className='odr-de-value'>{new Date(elm.dateReceived)?.toLocaleDateString("en-US")}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Instore (days)</div>
                                            <div className='odr-de-value'>{calculateInStoreDays(elm?.dateReceived)}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Customer ID</div>
                                            <div className='odr-de-value'>{elm.customer.userId}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Customer Name</div>
                                            <div className='odr-de-value'>{`${elm.customer.firstName} ${elm.customer.lastName}`}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Delivery Company</div>
                                            <div className='odr-de-value'>{elm.courierCompany.companyName}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Package Size</div>
                                            <div className='odr-de-value'>{`${elm.weight}kg ${elm.depth} x ${elm.width} x ${elm.height} cm`}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Package Content</div>
                                            <div className='odr-de-value'>Books</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Sender</div>
                                            <div className='odr-de-value'>{elm.sender?.senderName}</div>
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="right-sec" style={{ width: "400px" }}>
                            <div>
                                <div className='total-item'>
                                    No. of packages to be consolidated: {packages?.length || 0}
                                </div>
                                <div style={{ marginTop: "32px" }}>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="standard"
                                        name="radio-buttons-group"
                                        onChange={(e) => setSelectedConsolidationType(e.target.value)}
                                        value={selectedConsolidationType}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <FormControlLabel className="note" value={packageFee?.standardConsolidationFee?.fee} control={<Radio />} label={`Standard Consolidation - £${packageFee?.standardConsolidationFee?.fee}/package`} />
                                            <Tooltip disableFocusListener disableTouchListener title={packageFee?.standardConsolidationFee?.description || ""} placement='left' >
                                                <InfoOutlinedIcon sx={{ width: '16px', marginLeft: '6px', color: 'blue', cursor: 'pointer' }} />
                                            </Tooltip>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <FormControlLabel className="note" value={packageFee?.consolidationWithUnpack?.fee} control={<Radio />} label={`Consolidation with unpacking - £${packageFee?.consolidationWithUnpack?.fee}/package`} />
                                            <Tooltip disableFocusListener disableTouchListener title={packageFee?.consolidationWithUnpack?.description || ""} placement='left' >
                                                <InfoOutlinedIcon sx={{ width: '16px', marginLeft: '6px', color: 'blue', cursor: 'pointer' }} />
                                            </Tooltip>
                                        </div>
                                    </RadioGroup>
                                </div>
                            </div>
                            <>
                                <h4>Enter Billing Address</h4>
                                <div style={{ display: 'flex' }} >
                                    <TextField
                                        sx={{ width: '100%' }}
                                        id="outlined-basic"
                                        label="Biller's Name*"
                                        variant="outlined"
                                        defaultValue=""
                                        size="small"
                                        name="name"
                                        value={billingAdd?.name}
                                        onChange={handleBillingAddChange}
                                    />
                                    <TextField
                                        sx={{ width: '100%', marginLeft: "8px" }}
                                        id="outlined-basic"
                                        label="Biller's Company"
                                        variant="outlined"
                                        defaultValue=""
                                        size="small"
                                        name="company"
                                        value={billingAdd?.company}
                                        onChange={handleBillingAddChange}
                                    />
                                </div>
                                <div style={{ display: 'flex', marginTop: '16px' }} >
                                    <TextField
                                        sx={{ width: '100%' }}
                                        id="outlined-basic"
                                        label="Biller's Tax ID"
                                        variant="outlined"
                                        defaultValue=""
                                        size="small"
                                        name="federal_tax_id"
                                        value={billingAdd?.federal_tax_id}
                                        onChange={handleBillingAddChange}
                                    />
                                    <TextField
                                        sx={{ width: '100%', marginLeft: "8px" }}
                                        id="outlined-basic"
                                        label="Biller's Address 1*"
                                        variant="outlined"
                                        defaultValue=""
                                        size="small"
                                        name="street1"
                                        value={billingAdd?.street1}
                                        onChange={handleBillingAddChange}
                                    />
                                </div>
                                <div style={{ display: 'flex', marginTop: '16px' }} >
                                    <TextField
                                        sx={{ width: '100%' }}
                                        id="outlined-basic"
                                        label="Biller's Address 2"
                                        variant="outlined"
                                        defaultValue=""
                                        size="small"
                                        name="street2"
                                        value={billingAdd?.street2}
                                        onChange={handleBillingAddChange}
                                    />
                                    <TextField
                                        sx={{ width: '100%', marginLeft: "8px" }}
                                        id="outlined-basic"
                                        label="Biller's Email*"
                                        variant="outlined"
                                        defaultValue=""
                                        size="small"
                                        name="email"
                                        value={billingAdd?.email}
                                        onChange={handleBillingAddChange}
                                    />
                                </div>
                                <div style={{ display: 'flex', marginTop: '16px' }} >
                                    <FormControl fullWidth sx={{ width: '100%' }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={countiresList?.map(elm => ({
                                                label: elm.name,
                                                value: elm
                                            }))}
                                            name="country"
                                            value={selectedBillCountry?.name}
                                            renderInput={(params) => <TextField {...params} label="Biller's Country*" />}
                                            onChange={(e, val) => {
                                                if (!val) setSelectedBillCountry(null)
                                                else setSelectedBillCountry(val.value)
                                            }}
                                            size="small"
                                        />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ width: '100%', marginLeft: "8px" }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={selectedBillCountry ? billStatesList?.map(elm => ({
                                                label: elm.name,
                                                value: elm
                                            })) : []}
                                            name="state"
                                            value={selectedBillState?.name}
                                            renderInput={(params) => <TextField {...params} label="Biller's State" />}
                                            onChange={(e, val) => {
                                                if (!val) setSelectedBillState(null)
                                                else setSelectedBillState(val.value)
                                            }}
                                            size="small"
                                        />
                                    </FormControl>
                                </div>
                                <div style={{ display: 'flex', marginTop: '16px' }} >
                                    <FormControl fullWidth sx={{ width: '100%' }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={selectedBillState ? billCitiesList?.map(elm => ({
                                                label: elm.name,
                                                value: elm
                                            })) : []}
                                            name="city"
                                            value={selectBillCity?.name}
                                            renderInput={(params) => <TextField {...params} label="Biller's City" />}
                                            onChange={(e, val) => {
                                                if (!val) setSelectedBillCity(null)
                                                else setSelectedBillCity(val.value)
                                            }}
                                            size="small"
                                        />
                                    </FormControl>
                                    <TextField
                                        sx={{ width: '100%', marginLeft: "8px" }}
                                        id="outlined-basic"
                                        label="Biller's Zip*"
                                        variant="outlined"
                                        defaultValue=""
                                        size="small"
                                        name="zip"
                                        value={billingAdd?.zip}
                                        onChange={handleBillingAddChange}
                                    />
                                </div>
                                <TextField
                                    sx={{ width: '100%', margin: "16px 0" }}
                                    id="outlined-basic"
                                    label="Biller's Contact No.*"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="phone"
                                    value={billingAdd?.phone}
                                    onChange={handleBillingAddChange}
                                />
                            </>
                            <Elements stripe={stripePromise}>
                                <Checkout
                                    selectedConsolidationType={selectedConsolidationType}
                                    totalFee={calculateTotal()}
                                    loading={loading}
                                    handleCheckout={(res, token) => handleCheckout(res, token)}
                                />
                            </Elements>
                        </div>
                    </div>
                </div>
            </div>

        </Drawer>
    );
}

const Checkout = ({
    selectedConsolidationType,
    totalFee,
    loading,
    handleCheckout
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [cardHolderName, setCardHolderName] = useState("");

    const handlePayment = (e) => {
        e.preventDefault();

        if (!stripe || !elements || !cardHolderName) {
            return;
        }

        stripe.createToken(elements.getElement(CardElement))
            .then((res) => {
                if (res.error) {
                    console.log(res.error);
                }
                else {
                    handleCheckout(cardHolderName, res.token?.id)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <div >
            <h4>Enter Payment Details</h4>
            <form onSubmit={handlePayment} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", paddingBottom: "60px" }}>
                <div>
                    <TextField
                        sx={{ width: '100%', marginBottom: "16px" }}
                        id="outlined-basic"
                        label="Card Holder Name"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="name"
                        value={cardHolderName}
                        onChange={(e) => setCardHolderName(e.target.value)}
                        required
                    />
                    <div style={{ border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "4px", padding: "11px 6px" }}>
                        <CardElement options={{ hidePostalCode: true }} />
                    </div>
                </div>
                <div style={{ width: "400px", marginTop: "20px" }}>
                    <p className='total-item'>Total fee: <span style={{ fontSize: "22px" }}>£{totalFee}</span></p>
                    <Button onClick={handlePayment} variant="contained" disabled={!selectedConsolidationType || loading || !cardHolderName} disableElevation sx={{ backgroundColor: '#1565c0', width: "100%", mt: 2 }}>
                        {loading ? "Loading..." : "Make Payment"}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default Consolidation;