import { useNavigate } from 'react-router';
import { useState } from 'react';
import { resetPasswordEmail } from "../../apis";
import { Button, TextField } from '@mui/material';
import "./ResetPassword.styles.css";
import { errorToast, successToast, warningToast } from '../../components/toast/Toast';

const ResetPasswordEmail = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        if(email) {
            setLoading(true);
            const data = {
                email
             }
            resetPasswordEmail(data)
            .then((res)=>{
                if(res.status === 200) {
                    setLoading(false);
                    successToast("Request Sent Successfully")
                    navigate("/");
                }
            })
            .catch((err)=>{
                setLoading(false);
                errorToast(err.response?.data?.message)
            })
        }
        else  {
            warningToast("Please enter an email address")
        }
    }

    return (
        <div className="reset-pass-container">
            <h1 className='payment-heading'>Enter Your Email</h1>
            <div className='input-fields-container'>
            <TextField
                        sx={{ width: '100%', marginBottom: "22px" }}
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name='email'
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        type="text"
                    />
                    <Button onClick={handleSubmit} variant='contained' disabled={!email || loading} disableElevation sx={{ width: '200px', backgroundColor: '#1565c0' }}>Send Request</Button>
            </div>
        </div>
    )
};

export default ResetPasswordEmail;