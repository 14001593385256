import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getAllPackages, deletePackage } from "../../apis";
import { Box, Button, CircularProgress, Divider } from "@mui/material";
import "../../theme.styles.css";
import "./Packages.styles.css";
import EditPackage from "./EditPackage";
import { successToast } from "../../components/toast/Toast";
import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmation";

const PackageDetails = () => {
    const { id } = useParams();
    const [packageDetails, setPackage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchPackages();
        // eslint-disable-next-line
    }, [])

    const fetchPackages = () => {
        const queryParams = `?packageId=${id}`;
        getAllPackages(queryParams)
            .then((data) => {
                if (data.status === 200) {
                    setPackage(data.data?.packages[0])
                    setLoading(false);
                }
                else setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }

    const handleDeletePackage = () => {
        setDeleting(true);
        const data = {
            ids: [packageDetails?._id]
        }
        deletePackage(data)
            .then((data) => {
                if (data.status === 200) {
                    setDeleting(false);
                    successToast("Package deleted successfully!")
                    navigate("/packages");
                }
                else setDeleting(false);
            })
            .catch(() => {
                setDeleting(false);
            })
    }

    const calculateInStoreDays = (receivedDate) => {
        let numberOfDays = 0;
        if (receivedDate) {
            const diff = Math.abs(new Date() - new Date(receivedDate));
            numberOfDays = Math.ceil(diff / (1000 * 3600 * 24))
        }
        return numberOfDays;
    }

    return (
        <div>
            {
                loading ?
                    (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                        <CircularProgress />
                    </Box>)
                    :
                    <div className="card-container">
                        <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                            <h3 className='sub-heading'  > <span onClick={() => navigate("/packages")} style={{ textDecoration: "underline", cursor: "pointer" }}>Package Details</span> &#10095; {id}</h3>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Button variant="contained" disableElevation sx={{ backgroundColor: '#1565c0', marginRight: 2 }} onClick={() => setOpenEditModal(true)}>Edit</Button>
                                <Button variant="contained" disableElevation color="error" disabled={deleting || loading} onClick={()=>setOpenDeleteConfirmationDialog(true)}>Delete</Button>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: "space-between", marginTop: '14px' }}>
                            <div style={{ width: "50%" }} >
                                <div className="pkg-dtl-item">
                                    <label>Tracking No.:</label>
                                    <div>{packageDetails?.trackingNumber}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Date Received:</label>
                                    <div>{new Date(packageDetails?.dateReceived)?.toLocaleDateString("en-US")}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Instore (days):</label>
                                    <div>{calculateInStoreDays(packageDetails?.dateReceived)}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Status:</label>
                                    <div>{packageDetails?.status}</div>
                                </div>
                            </div>

                            <div style={{ width: "50%" }}>
                                <div className="pkg-dtl-item">
                                    <label>Handling Note:</label>
                                    <div>{packageDetails?.handlingNote || "-"}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Package Size:</label>
                                    <div>{`${packageDetails?.weight}kg ${packageDetails?.depth} x ${packageDetails?.width} x ${packageDetails?.height} cm`}</div>
                                </div>
                            </div>
                        </div>

                        <Divider sx={{ marginTop: "32px" }} />

                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>Customer Details</h3>

                        <div style={{ display: 'flex', justifyContent: "space-between", marginTop: '14px' }}>
                            <div style={{ width: "50%" }} >
                                <div className="pkg-dtl-item">
                                    <label>Customer ID:</label>
                                    <div>{packageDetails?.customer?.userId}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Customer Name:</label>
                                    <div>{`${packageDetails?.customer?.firstName} ${packageDetails?.customer?.lastName}`}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Customer Email:</label>
                                    <div>{packageDetails?.customer?.email}</div>
                                </div>
                            </div>
                        </div>

                        <Divider sx={{ marginTop: "32px" }} />

                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>Sender Details</h3>

                        <div style={{ display: 'flex', justifyContent: "space-between", marginTop: '14px' }}>
                            <div style={{ width: "50%" }} >
                                <div className="pkg-dtl-item">
                                    <label>Sender Name:</label>
                                    <div>{`${packageDetails?.sender?.senderName}`}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Address1:</label>
                                    <div>{packageDetails?.sender?.address1}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Address2:</label>
                                    <div>{packageDetails?.sender?.address2 || "-"}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>City:</label>
                                    <div>{packageDetails?.sender?.city || "-"}</div>
                                </div>
                            </div>
                            <div style={{ width: "50%" }} >
                                <div className="pkg-dtl-item">
                                    <label>State:</label>
                                    <div>{packageDetails?.sender?.state || "-"}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Country:</label>
                                    <div>{packageDetails?.sender?.country || "-"}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Zip:</label>
                                    <div>{packageDetails?.sender?.zip || "-"}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>VAT:</label>
                                    <div>{packageDetails?.sender?.vat || "-"}</div>
                                </div>
                            </div>
                        </div>

                        <Divider sx={{ marginTop: "32px" }} />

                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>UK Delivery Company</h3>

                        <div style={{ display: 'flex', justifyContent: "space-between", marginTop: '14px' }}>
                            <div style={{ width: "50%" }} >
                                <div className="pkg-dtl-item">
                                    <label>Company Name:</label>
                                    <div>{`${packageDetails?.courierCompany?.companyName}`}</div>
                                </div>
                                {/* <div className="pkg-dtl-item">
                                    <label>Delivery Time:</label>
                                    <div>{packageDetails?.courierCompany?.deliveryTime}</div>
                                </div> */}
                            </div>
                        </div>

                        <Divider sx={{ marginTop: "32px" }} />

                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>Package Items</h3>
                        {
                            packageDetails?.packageItems.map((elm, ind) => (
                                <div key={ind} className="row">
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Item No.</div>
                                        <div className='odr-de-value'>{ind + 1}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Export Code</div>
                                        <div className='odr-de-value'>{elm?.item?.exportCode}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Item Type</div>
                                        <div className='odr-de-value'>{elm?.item?.itemType}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Quantity</div>
                                        <div className='odr-de-value'>{elm.quantity}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Value Each</div>
                                        <div className='odr-de-value'>{elm.valueEach}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Item Description</div>
                                        <div className='odr-de-value'>{elm?.item?.itemDescription} - {elm.variant}</div>
                                    </span>
                                </div>
                            ))
                        }
                    </div>
            }

            <EditPackage
                open={openEditModal}
                handleClose={() => setOpenEditModal(false)}
                selectedPackage={packageDetails}
                fetchPackages={() => fetchPackages()}
            />
            <DeleteConfirmationDialog
                open={openDeleteConfirmationDialog}
                title={"Delete"}
                description={"Are you sure you want to delete this package?"}
                primaryCta={{
                    label: "Delete",
                    onClick: ()=>handleDeletePackage()
                }}
                secondaryCta={{
                    label: "Cancel",
                    onClick: ()=>setOpenDeleteConfirmationDialog(false)
                }}

            />
        </div>
    )
}

export default PackageDetails;