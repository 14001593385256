import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const userData = JSON.parse(localStorage.getItem("KToken"));

export const uploadUserCsv = (data) => axios.post(`${BASE_URL}/user/csv`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const uploadExportCodesCsv = (data) => axios.post(`${BASE_URL}/exportCodes/csv`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const uploadSendersCsv = (data) => axios.post(`${BASE_URL}/senders/csv`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const uploadRoyalMailRatesCsv = (data) => axios.post(`${BASE_URL}/royalMail/csv`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});