import { useEffect, useState } from "react"
import { getOrderByNo, markOrderComplete, cancelOrder, refundOrder, manualShippingOrder, getAllCourierCompanies, getCompanyData } from "../../apis";
import { useNavigate, useParams } from "react-router";
import { Button, Chip, Dialog, DialogActions, DialogTitle, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import LocationSearchingRoundedIcon from '@mui/icons-material/LocationSearchingRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import "./ClaimOrders.styles.css";
import { errorToast, successToast } from "../../components/toast/Toast";
import { handleUserRole } from '../../utils/userRole';
import { USER_ROLE } from '../../constants/userRole';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import axios from "axios";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ProformaInvoice } from "../../components/Invoices/ProformaInvoice";
import { OrderInvoice } from "../../components/Invoices/OrderInvoice";

const ClaimShippingOrders = () => {
    const { orderNo } = useParams();
    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogType, setDialogType] = useState("");
    const [openManualShippingModal, setOpenManualShippingModal] = useState(false);
    const [companyData, setCompanyData] = useState(null);
    const [scanComplete, setScanComplete] = useState(true);
    const [scanValue, setScanValue] = useState("");
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("KToken"));
    const userRole = userData?.user?.role || userData?.admin?.role

    useEffect(() => {
        if (orderNo) {
            fetchOrder()
            fetchCompanyData();
        }
        // eslint-disable-next-line
    }, [orderNo])

    function fetchOrder() {
        getOrderByNo(orderNo)
            .then((res) => {
                if (res.status === 200) {
                    setOrderDetails(res?.data?.order)
                }
            })
    }

    const fetchCompanyData = async () => {
        const data = await getCompanyData();
        if (data.status === 200) {
            setCompanyData(data.data.company);
        }
    }

    const handleCompleteOrder = async () => {
        setLoading(true)
        const data = {
            orderNo,
            orderType: orderDetails?.orderType
        }
        markOrderComplete(data)
            .then((res) => {
                if (res.status === 200) {
                    navigate("/orders")
                    successToast("Order Completed Successfuly")
                    setLoading(false)
                }
            })
            .catch(() => {
                errorToast("There was an error in completing order. Please try again later!")
                setLoading(false)
            })
    }

    const handleCancelOrder = () => {
        setCancelLoading(true)
        const data = {
            orderNo
        }
        cancelOrder(data)
            .then((res) => {
                if (res.status === 200) {
                    navigate("/orders")
                    successToast("Order Cancelled Successfuly")
                    setCancelLoading(false)
                }
            })
            .catch((err) => {
                errorToast(err?.response?.data?.message)
                setCancelLoading(false)
            })
    }

    const handleRefundOrder = () => {
        setLoading(true)
        const data = {
            orderNo
        }
        refundOrder(data)
            .then((res) => {
                if (res.status === 200) {
                    navigate("/orders")
                    successToast("Order Cancelled Successfuly and Refund In-process")
                    setLoading(false)
                }
            })
            .catch((err) => {
                errorToast(err?.response?.data?.message)
                setLoading(false)
            })
    }

    const handleDialogConfirmation = () => {
        if (dialogType === "refund") handleRefundOrder();
        else handleCancelOrder();
    }

    const handleOpenLabelUrls = (data) => {
        data?.forEach(elm => {
            window.open(elm, "_blank")
        })
    }

    const handleOpenTrackingUrls = (data) => {
        data?.forEach(elm => {
            window.open(elm, "_blank")
        })
    }

    const handleBarcodeScan = (e) => {
        if (e.target.value === orderDetails?.packages[0]?.packageId) setScanComplete(false);

        setScanValue("")
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '32px' }}>
                    <h2 className='modal-heading'>Order No: {orderNo}</h2>

                    {orderNo && <PDFDownloadLink
                        document={<OrderInvoice data={orderDetails} companyData={companyData} />}
                        fileName="order-invoice.pdf"
                        style={{
                            textDecoration: "none",
                            color: "#1565c0",
                            border: "1px solid #1565c0",
                            padding: "4px 12px",
                            borderRadius: "4px"
                        }}
                    >
                        {({ blob, url, loading, error }) =>
                            "Order Invoice"
                        }
                    </PDFDownloadLink>}
            </div>
            <div className="card-container" style={{ marginTop: '32px', display: 'flex', justifyContent: 'space-between' }}>
                <div className="user-details">
                    <h3>Ship To:</h3>
                    <p>{orderDetails?.receiver?.receivingAddress?.name}</p>
                    <p>{orderDetails?.receiver?.receivingAddress?.street1}</p>
                    <p>{orderDetails?.receiver?.receivingAddress?.street2}</p>
                    <p>{orderDetails?.receiver?.receivingAddress?.city}, {orderDetails?.receiver?.receivingAddress?.country}.</p>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button
                            sx={{ color: "#1565c0" }}
                            startIcon={<LocalPrintshopRoundedIcon />}
                            onClick={() => handleOpenLabelUrls(orderDetails?.shippingLabelUrls)}
                            disabled={!orderDetails?.shippingLabelUrls?.length}
                        >Labels</Button>
                        <PDFDownloadLink
                            document={<ProformaInvoice data={orderDetails} companyData={companyData} />}
                            fileName="performa-invoice.pdf"
                        >
                            {({ blob, url, loading, error }) =>
                                <Button
                                    sx={{ color: "#1565c0" }}
                                    startIcon={<LocalPrintshopRoundedIcon />}
                                    disabled={loading}
                                >Invoice</Button>
                            }
                        </PDFDownloadLink>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                            sx={{ mt: 2, color: "#1565c0", borderColor: "#1565c0" }}
                            variant="outlined"
                            startIcon={<LocationSearchingRoundedIcon />}
                            disabled={!orderDetails?.trackingUrls?.length}
                            onClick={() => handleOpenTrackingUrls(orderDetails?.trackingUrls)}
                        >Track Shipping</Button>
                        {orderDetails?.status !== "completed" && <Button
                            sx={{ mt: 2, color: "#1565c0", borderColor: "#1565c0" }}
                            onClick={() => setOpenManualShippingModal(true)}
                            variant="outlined"
                            startIcon={<LocalShippingRoundedIcon />}
                            disabled={orderDetails?.selectedCarrier?.carrier !== "RoyalMail"}
                        >Manual Shipping</Button>}

                    </div>
                </div>
            </div>
            <div className="card-container" style={{ marginTop: '32px' }}>
                {
                    orderDetails &&
                    orderDetails?.packages.map((elm, ind) => (
                        <div key={ind} className="row" style={{ flexWrap: "initial" }}>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Package No.</div>
                                    <div className='odr-de-value'>{ind + 1}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Package ID</div>
                                    <div className='odr-de-value'>{elm.packageId}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Date Received</div>
                                    <div className='odr-de-value'>{new Date(elm.dateReceived)?.toLocaleDateString("en-US")}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Customer ID</div>
                                    <div className='odr-de-value'>{orderDetails?.customer?.userId}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Customer Name</div>
                                    <div className='odr-de-value'>{orderDetails?.customer?.firstName} {orderDetails?.customer?.lastName}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Delivery Company</div>
                                    <div className='odr-de-value'>{orderDetails?.selectedCarrier?.service}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Package Content</div>
                                    <div className='odr-de-value'>
                                        {
                                            elm?.packageItems?.length ?
                                                <>
                                                    {
                                                        elm?.packageItems?.length > 1 ?
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <div className='text-wrap' style={{ maxWidth: "124px", marginRight: "6px" }}>{elm?.packageItems[0]?.item?.itemDescription}</div>
                                                                <Chip label={`+${elm?.packageItems?.length - 1}`} size="small" />
                                                            </div>
                                                            :
                                                            <div className='text-wrap' style={{ maxWidth: "172px" }}>{elm?.packageItems[0]?.item?.itemDescription}</div>
                                                    }
                                                </>
                                                :
                                                "-"
                                        }
                                    </div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Sender</div>
                                    <div className='odr-de-value'>{elm?.sender?.senderName}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Order type</div>
                                    <div className='odr-de-value'>{orderDetails?.orderType}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Package Size</div>
                                    <div className='odr-de-value'>{`${elm?.weight}kg ${elm?.depth} x ${elm?.width} x ${elm?.height} cm`}</div>
                                </span>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className="card-container" style={{ marginTop: '32px' }}>
                <div style={{ display: 'flex', justifyContent: "flex-end" }} >
                    <TextField label="Scan Package" onChange={handleBarcodeScan} value={scanValue} sx={{ width: '300px', marginRight: "18px" }} InputLabelProps={{ shrink: true }} />
                    {/* {handleUserRole([USER_ROLE.superAdmin], userRole) && <Button disabled={cancelLoading || loading} variant="outlined" disableElevation sx={{ width: '300px', borderWidth: "2px", fontWeight: 600, marginRight: "18px" }} onClick={() => { setOpenDialog(true); setDialogType("cancel") }}>
                        {cancelLoading ? "Loading..." : "Cancel Order"}
                    </Button>} */}
                    {handleUserRole([USER_ROLE.superAdmin], userRole) && <>
                        {orderDetails?.status === "completed" && <Button variant="outlined" disabled={loading || cancelLoading} disableElevation color="error" sx={{ width: '300px', border: "2px solid", fontWeight: 600 }} onClick={() => { setOpenDialog(true); setDialogType("refund") }}>
                            {loading ? "Loading..." : "Refund"}
                        </Button>}
                    </>}
                    {orderDetails?.status !== "completed" && <Button variant="contained" disabled={loading || cancelLoading || scanComplete} disableElevation sx={{ width: '300px', backgroundColor: '#1565c0', border: "2px solid", fontWeight: 600 }} onClick={handleCompleteOrder}>
                        {loading ? "Loading..." : "Complete Order"}
                    </Button>}
                </div>
            </div>

            <ConfirmationDialog
                handleClose={() => setOpenDialog(false)}
                handleAgree={handleDialogConfirmation}
                open={openDialog}
            />
            <ManualShippingModal
                open={openManualShippingModal}
                onClose={() => setOpenManualShippingModal(false)}
                onSave={() => { setOpenManualShippingModal(false); fetchOrder() }}
            />
        </div>
    )

};

const ConfirmationDialog = ({ handleClose, open, handleAgree }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{ display: "flex", alignItems: "center" }}>
                <DangerousRoundedIcon sx={{ fontSize: "54px", color: "#d92e2e" }} /> {"Are you sure, you want to cancel order?"}
            </DialogTitle>
            <DialogActions sx={{ padding: "16px 24px" }}>
                <Button onClick={handleClose}>Disagree</Button>
                <Button disableElevation variant="contained" onClick={handleAgree} autoFocus sx={{ backgroundColor: '#1565c0', borderRadius: "20px" }}>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const ManualShippingModal = ({ open, onClose, onSave }) => {

    const [manualData, setManualData] = useState({
        trackingUrl: "",
        shipmentTrackingCode: ""
    });
    const [loading, setLoading] = useState(false);
    const [disableCta, setDisableCta] = useState(true);
    const [courierCompaniesList, setCourierCompaniesList] = useState([]);
    const [deliveryCompany, setSelectedDC] = useState(null);
    const [file, setFile] = useState(null);
    const { orderNo } = useParams();

    useEffect(() => {
        if (manualData.shipmentTrackingCode && manualData.trackingUrl && file && deliveryCompany) setDisableCta(false)
        else setDisableCta(true);
    }, [manualData, file, deliveryCompany])

    useEffect(() => {
        fetchCourierCompanies();
    }, [])

    const fetchCourierCompanies = async () => {
        const data = await getAllCourierCompanies();
        if (data.status === 200) {
            setCourierCompaniesList(data.data.companies);
        }
    }

    const handleChange = (e) => {
        setManualData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleUpdate = (url) => {
        const data = {
            orderNo,
            shippingLabelUrl: url,
            selectedCarrier: {
                carrier: deliveryCompany?.service,
                service: deliveryCompany?.service
            },
            ...manualData
        }
        manualShippingOrder(data)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    successToast("User Updated Successfully")
                    setFile(null);
                    setSelectedDC(null);
                    setManualData({
                        trackingUrl: "",
                        shipmentTrackingCode: ""
                    })
                    onSave();
                }
            })
            .catch(() => {
                setLoading(false)
                errorToast("There was an error updating user. Please try again later!")
            })
    }

    const handleDCSelect = (event) => {
        event.preventDefault();
        setSelectedDC(event.target.value)
    }

    const uploadFile = async () => {
        setLoading(true)
        const data = new FormData()
        data.append("file", file)
        data.append("upload_preset", "uo23qjfh")
        data.append("cloud_name", "testing user")

        const upload = await axios.post("https://api.cloudinary.com/v1_1/dlbzp41cw/image/upload", data)
        if (upload?.status === 200) {
            handleUpdate(upload?.data?.url)
        }
        else {
            setLoading(false)
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <div style={{ backgroundColor: "white", padding: "32px 40px", borderRadius: "8px", width: "600px" }}>
                <h3 style={{ color: "#0e284d", marginBottom: "32px" }}>Manual Shipping</h3>
                <div className="file-upload">
                    {file ? file?.name : "Upload a File *"}
                    <input type="file" accept="image/png, image/jpeg, image/jpg" onChange={(e) => setFile(e.target.files[0])} />
                </div>
                <TextField
                    sx={{ width: '100%', marginTop: '32px' }}
                    id="outlined-basic"
                    label="Tracking URL *"
                    variant="outlined"
                    defaultValue=""
                    size="small"
                    name="trackingUrl"
                    value={manualData.trackingUrl}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    sx={{ width: '100%', marginTop: '32px' }}
                    id="outlined-basic"
                    label="Tracking Code *"
                    variant="outlined"
                    defaultValue=""
                    size="small"
                    name="shipmentTrackingCode"
                    value={manualData.shipmentTrackingCode}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                />
                <FormControl fullWidth sx={{ marginTop: '32px' }}>
                    <InputLabel id="demo-simple-select-label">Carrier *</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={deliveryCompany}
                        label="Carrier"
                        onChange={handleDCSelect}
                    >
                        {
                            courierCompaniesList?.map(elm => (
                                <MenuItem key={elm._id} value={elm}>{elm.service}</MenuItem>

                            ))
                        }
                    </Select>
                </FormControl>
                <div style={{ display: 'flex', marginTop: '32px', justifyContent: "flex-end" }} >
                    <Button sx={{ marginRight: "12px" }} onClick={onClose}>Cancel</Button>
                    <Button disabled={loading || disableCta} variant='contained' disableElevation onClick={() => uploadFile()}>{loading ? "Loading..." : "Save"}</Button>
                </div>
            </div>
        </Modal>
    )
}

export default ClaimShippingOrders;