import { useNavigate, useParams } from 'react-router';
import { useState } from 'react';
import { resetPassword } from "../../apis";
import { Button, TextField } from '@mui/material';
import "./ResetPassword.styles.css";
import { errorToast, successToast, warningToast } from '../../components/toast/Toast';

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [stateData, setStateData] = useState({
        password: "",
        confirmPassword: ""
    });
    const [loading, setLoading] = useState(false);

    const handleStateData = (event) => {
        event.preventDefault();
        setStateData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const handleSubmit = () => {
        if(stateData.password?.length >= 8 && stateData.confirmPassword?.length >= 8) {
            if(stateData.password === stateData.confirmPassword) {
                setLoading(true);
                const data = {
                    token,
                    password: stateData.password
                }
                resetPassword(data)
                .then((res)=>{
                    if(res.status === 200) {
                        setLoading(false);
                        successToast("Password Reset Successfull")
                        navigate("/");
                    }
                })
                .catch((err)=>{
                    setLoading(false);
                    errorToast("There was an error reseting password. Please try again later!")
                })
            }
            else  {
                warningToast("Password and confirm password doesn't match")
            }
        }
        else {
            errorToast("Password length must be atleat 8 characters long")
        }
    }

    return (
        <div className="reset-pass-container">
            <h1 className='payment-heading'>Reset Password</h1>
            <div className='input-fields-container'>
            <TextField
                        sx={{ width: '100%', marginBottom: "22px" }}
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name='password'
                        value={stateData.password}
                        onChange={handleStateData}
                        type="password"
                    />
                    <TextField
                        sx={{ width: '100%', marginBottom: "6px" }}
                        id="outlined-basic"
                        label="Confirm Password"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name='confirmPassword'
                        value={stateData.confirmPassword}
                        onChange={handleStateData}
                        type="password"
                    />
                    <p style={{textAlign: "left", fontSize: "13px", opacity: 0.6, marginBottom: "32px"}}>Password must be atleast 8 characters long</p>
                    <Button onClick={handleSubmit} variant='contained' disabled={(!stateData?.password && !stateData?.confirmPassword) || loading} disableElevation sx={{ width: '200px', backgroundColor: '#1565c0' }}>Reset Password</Button>
            </div>
        </div>
    )
};

export default ResetPassword;