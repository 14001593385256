import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const userData = JSON.parse(localStorage.getItem("KToken"));

export const getAllCourierCompanies = ()=> axios.get(`${BASE_URL}/courierCompany`, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const deleteCourierCompanies = (data) => axios.delete(`${BASE_URL}/courierCompany`, {headers: {
    "Authorization": `Bearer ${userData?.token}`
},
data
});

export const uploadCourierCompaniesCsv = (data) => axios.post(`${BASE_URL}/courierCompany/csv`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});