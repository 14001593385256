import React from 'react';
import { Button, TextField } from "@mui/material";
import "../../theme.styles.css";
import "../AwaitingActions/AwaitingActions.styles.css";

const Help = () => {

    return (
        <div>
            <div style={{ marginBottom: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 className="main-heading">Help</h1>
            </div>



            <div className="card-container">

                <div style={{ display: 'flex' }} >
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="Query About"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                    />
                </div>

                <div style={{ display: 'flex', marginTop: '32px' }} >
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        placeholder='Type your message here'
                        variant="outlined"
                        defaultValue=""
                        minRows={6}
                        multiline={true}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent:'flex-end', marginTop: '32px' }} >
                    <Button variant="contained" disableElevation sx={{ width: '200px', backgroundColor: '#1565c0' }} >
                        Submit
                    </Button>
                </div>

            </div>
        </div>
    )
}

export default Help;