import React from 'react';
import { InputAdornment, TextField, Box, Button, Checkbox, CircularProgress, Chip } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import "../../theme.styles.css";
import "../AwaitingActions/AwaitingActions.styles.css";
import Shipping from './Shipping';
import Consolidation from './Consolidation';
import { getAllPackages, getPackageFeeSize } from '../../apis';
import { errorToast } from '../../components/toast/Toast';
import PackageDetails from './PackageDetails';


const ShipOrConsolidate = () => {
    const [openConsolidation, setOpenConsolidation] = React.useState(false);
    const [openShipping, setOpenShipping] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [packages, setPackages] = React.useState([]);
    const [selectedPackages, setSelectedPackages] = React.useState([]);
    const [packageFee, setPackageFee] = React.useState(null);
    const [selectedPackage, setSelectedPackage] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setSelectedPackage(null);
        setOpen(false);
    }

    React.useEffect(() => {
        fetchPackages();
        fetchFee();
    }, [])

    const fetchFee = async () => {
        const data = await getPackageFeeSize();
        if (data.status === 200) {
            setPackageFee(data?.data?.packageFee)
        }
    }

    const fetchPackages = async () => {
        getAllPackages("")
        .then((data)=>{
            if (data.status === 200) {
                setPackages(data.data?.packages)
                setLoading(false);
            }
            else setLoading(false);
        })
        .catch(()=>{
            setLoading(false);
            errorToast("There was an error fetching packages. Please try again later!")
        })
    }

    const calculateInStoreDays = (receivedDate) => {
        let numberOfDays = 0;
        if (receivedDate) {
            const diff = Math.abs(new Date() - new Date(receivedDate));
            numberOfDays = Math.ceil(diff / (1000 * 3600 * 24))
        }
        return numberOfDays;
    }

    const handlePackageSelection = (pkg) => {
        const copyArr = [...selectedPackages];
        const exist = copyArr.findIndex(elm => elm?._id === pkg?._id)

        if (exist > -1) {
            copyArr.splice(exist, 1);
            setSelectedPackages([
                ...copyArr
            ])
        }
        else {
            setSelectedPackages([
                ...copyArr,
                pkg
            ])
        }
    }
    
    const showDetails = (pkg) => {
        setSelectedPackage(pkg)
    }

    React.useEffect(() => {
        if (selectedPackage) handleOpen();
    }, [selectedPackage])

    return (
        <div>
            <div style={{ marginBottom: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 className="main-heading">Ship / Consolidate</h1>
            </div>

            <div className="card-container">
                <TextField
                    sx={{ width: '100%' }}
                    id="outlined-basic"
                    label="Search"
                    variant="outlined"
                    defaultValue=""
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

            <div className="card-container" style={{ marginTop: '32px' }}>
                {
                    loading ?
                        (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>)
                        :
                        <>
                            {
                                packages.filter(elm => elm.status === "ready")?.length === 0 ?
                                    <Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                        <h1 style={{ color: "#0e284d" }}>No Packages Found!</h1>
                                    </Box>
                                    :
                                    null
                            }
                        </>
                }
                {
                    packages &&
                    packages.filter(elm => elm.status === "ready")
                        .map((elm, ind) => (
                            <div key={ind} className="row" style={{ flexWrap: "initial" }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Package No.</div>
                                        <div className='odr-de-value'>{ind+1}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Package ID</div>
                                        <div className='odr-de-value'>{elm.packageId}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Date Received</div>
                                        <div className='odr-de-value'>{new Date(elm.dateReceived)?.toLocaleDateString("en-US")}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Instore (days)</div>
                                        <div className='odr-de-value'>{calculateInStoreDays(elm?.dateReceived)}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Customer ID</div>
                                        <div className='odr-de-value'>{elm.customer.userId}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Customer Name</div>
                                        <div className='odr-de-value'>{`${elm.customer.firstName} ${elm.customer.lastName}`}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Delivery Company</div>
                                        <div className='odr-de-value'>{elm.courierCompany.companyName}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Package Size</div>
                                        <div className='odr-de-value'>{`${elm.weight}kg ${elm.depth} x ${elm.width} x ${elm.height} cm`}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Package Content</div>
                                        <div className='odr-de-value'>
                                        {
                                        elm?.packageItems?.length ?
                                            <>
                                                {
                                                    elm?.packageItems?.length > 1 ?
                                                    <div style={{display:"flex", alignItems:"center"}}>
                                                        <div className='text-wrap' style={{ maxWidth: "124px", marginRight: "6px" }}>{elm?.packageItems[0]?.item?.itemDescription}</div>
                                                        <Chip label={`+${elm?.packageItems?.length-1}`} size="small" onClick={()=>showDetails(elm)} />
                                                    </div>
                                        :
                                        <div className='text-wrap' style={{ maxWidth: "172px" }}>{elm?.packageItems[0]?.item?.itemDescription}</div>
                                                }
                                            </>
                                            :
                                            "-"
                                    }
                                        </div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Sender</div>
                                        <div className='odr-de-value'>{elm.sender?.senderName}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Status</div>
                                        <div className='odr-de-value'>{elm.status}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Is Consolidated</div>
                                        <div className='odr-de-value'>{elm.isConsolidated ? "Yes" : "No"}</div>
                                    </span>
                                </div>
                                {
                                    <div>
                                        <Checkbox checked={selectedPackages.findIndex(item => item?._id === elm?._id) !== -1} onChange={() => handlePackageSelection(elm)} />
                                    </div>
                                }
                            </div>
                        ))
                }
            </div>

            <div className="card-container" style={{ marginTop: '32px' }}>
                <p className='note'><span style={{ fontWeight: "bolder" }}>How would you like to Proceed?</span></p>
                <p className='note' style={{ marginTop: '6px' }}><span style={{ fontWeight: "bolder" }}>Note:</span> You can get a quote for international shipping or if multiple packages are selected, you can choose to consolidate (combine) it.</p>
                <div style={{ display: 'flex', marginTop: '32px' }} >
                    <Button disabled={!selectedPackages?.length} variant="contained" disableElevation sx={{ width: '300px', backgroundColor: '#1565c0', border: "2px solid", marginRight: "36px", fontWeight: 600 }} onClick={() => setOpenShipping(true)}>
                        Get a quote for Shipping
                    </Button>
                    {selectedPackages?.length > 1 ? <Button variant="outlined" disableElevation sx={{ width: '300px', borderWidth: "2px", fontWeight: 600 }} onClick={() => setOpenConsolidation(true)}>
                        Consolidate
                    </Button> : null}
                </div>
            </div>

            <Shipping
                open={openShipping}
                handleClose={() => {
                    fetchPackages();
                    setSelectedPackages([])
                    setOpenShipping(false)
                }}
                packages={selectedPackages}
                packageFee={packageFee}
            />

            <Consolidation
                open={openConsolidation}
                handleClose={() => {
                    fetchPackages();
                    setSelectedPackages([])
                    setOpenConsolidation(false);
                }}
                packages={selectedPackages}
                packageFee={packageFee}
            />
            
            <PackageDetails
                open={open}
                handleClose={handleClose}
                selectedPackage={selectedPackage}
            />
        </div>
    )
}

export default ShipOrConsolidate;