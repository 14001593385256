import { FormControl, InputLabel, MenuItem, Select, Button, Chip, CircularProgress, Box } from "@mui/material";
import { getAllOrders, claimOrder } from "../../apis";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { successToast, errorToast } from "../../components/toast/Toast";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { convertOrdersToCSV } from "../../utils/convertToCsv";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'

const handleChipColors = (label) => {
    let styles = {}
    switch (label) {
        case "open":
            styles = { backgroundColor: "#118ab2", color: "#fff" }
            break;
        case "processing":
            styles = { backgroundColor: "#ffd166", color: "#000" }
            break;
        case "completed":
            styles = { backgroundColor: "#06d6a0", color: "#fff" }
            break;
        case "cancelled":
            styles = { backgroundColor: "#ef476f", color: "#fff" }
            break;
        default:
            break;
    }
    return styles;
}

const Orders = () => {
    const [orderStatus, setOrderStatus] = useState("open")
    const [loading, setLoading] = React.useState(true);
    const [orders, setOrders] = React.useState([]);
    const [dateFrom, setDateFrom] = React.useState(null);
    const [dateTo, setDateTo] = React.useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchOrders();
    }, [])

    const fetchOrders = async () => {
        const data = await getAllOrders("");
        if (data.status === 200) {
            setOrders(data.data?.orders)
            setLoading(false);
        }
        else setLoading(false);
    }

    const handleClaimOrder = (orderNo, orderType) => {
        const data = {
            orderNo
        }
        claimOrder(data)
            .then((claimOrd) => {
                if (claimOrd.status === 200) {
                    successToast("Order Claimed Sccussefully")
                    navigateTo(orderNo, orderType)
                }
            })
            .catch((error) => {
                errorToast(error?.response?.data.message  || "There was an error claiming order. Please try again later!")
            })
    }

    const navigateTo = (orderNo, orderType) => {
        if (orderType === "shipping") {
            navigate(`/shipping-order/${orderNo}`)
        }
        else {
            navigate(`/consolidation-order/${orderNo}`)
        }
    }

    const handleRoyalMailOrders = async () => {
        getAllOrders(`?carrier=RoyalMail&dateFrom=${dayjs(dateFrom)}&dateTo=${dayjs(dateTo)}`)
            .then((res) => {
                if (res.status === 200 && res.data?.orders?.length > 0) {
                     convertOrdersToCSV(res.data?.orders)
                    }
                else {
                    errorToast("No Royal mail Orders found with given date range")
            }
            })
            .catch(() => {
                errorToast("There was an error fetching royal mail orders. Please try again later!")
            })
    }

    return (
        <div>
            <div style={{ marginBottom: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 className="main-heading">Orders</h1>

                <FormControl sx={{ minWidth: '200px' }}>
                    <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={orderStatus}
                        value={orderStatus}
                        label="Order Status"
                        onChange={(event) => { setOrderStatus(event.target.value) }}
                        size="small"
                    >
                        <MenuItem value={"open"}>Open</MenuItem>
                        <MenuItem value={"processing"}>Processing</MenuItem>
                        <MenuItem value={"completed"}>Completed</MenuItem>
                        <MenuItem value={"canceled"}>Canceled</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <div className="card-container" style={{ marginTop: '32px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker value={dateFrom} onChange={(e) => setDateFrom(e)} label="Date From" className='date-picker' sx={{ marginRight: "16px" }} />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker value={dateTo} minDate={dateFrom} disabled={!dateFrom} onChange={(e) => setDateTo(e)} label="Date To" className='date-picker' sx={{ marginRight: "16px" }} />
                    </LocalizationProvider>
                    <Button disabled={!dateFrom || !dateTo} variant="outlined" disableElevation sx={{ width: '200px', borderColor: '#1565c0', color: "#1565c0" }} onClick={handleRoyalMailOrders} >
                        <DownloadOutlinedIcon sx={{ width: '20px', marginRight: '4px' }} />
                        Royal Mail
                    </Button>
                </div>
                {
                    loading ?
                        (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>)
                        :
                        <>
                            {
                                orders?.filter(elm => elm.status === orderStatus)?.length === 0 ?
                                    <Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                        <h1 style={{ color: "#0e284d" }}>No {orderStatus} orders Here!</h1>
                                    </Box>
                                    :
                                    null
                            }
                        </>
                }
                {
                    orders &&
                    orders.filter(elm => elm.status === orderStatus)
                        .map((elm, ind) => (
                            <div key={ind} className={`row ${elm?.status === "processing" || elm?.status === "completed" ? "row-hover" : ""}`} style={{ flexWrap: "initial" }} onClick={() => { elm?.status === "processing" || elm?.status === "completed" ? navigateTo(elm?.orderNo, elm?.orderType) : void 0 }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Order No.</div>
                                        <div className='odr-de-value'>{elm?.orderNo}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Package ID</div>
                                        <div className='odr-de-value'>
                                        {
                                        elm?.packages?.length ?
                                            <>
                                                {
                                                    elm?.packages?.length > 1 ?
                                                    <div style={{display:"flex", alignItems:"center"}}>
                                                        <div className='text-wrap' style={{ maxWidth: "124px", marginRight: "6px" }}>{elm?.packages[0]?.packageId}</div>
                                                        <Chip label={`+${elm?.packages?.length-1}`} size="small" />
                                                    </div>
                                        :
                                        <div className='text-wrap' style={{ maxWidth: "172px" }}>{elm?.packages[0]?.packageId}</div>
                                                }
                                            </>
                                            :
                                            "-"
                                    }
                                            </div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Date Received</div>
                                        <div className='odr-de-value'>{new Date(elm?.dateReceived || elm?.createdAt)?.toLocaleDateString("en-US")}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Customer ID</div>
                                        <div className='odr-de-value'>{elm?.customer?.userId}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Customer Name</div>
                                        <div className='odr-de-value'>{`${elm?.customer?.firstName} ${elm?.customer?.lastName}`}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Shipping Company</div>
                                        <div className='odr-de-value'>{elm?.selectedCarrier?.carrier || "-"}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Sender</div>
                                        <div className='odr-de-value'>{elm?.sender?.senderName || "-"}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Status</div>
                                        <div className='odr-de-value'>
                                            <Chip sx={handleChipColors(elm?.status)} label={elm?.status} size="small" />
                                        </div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Order type</div>
                                        <div className='odr-de-value'>{elm?.orderType}</div>
                                    </span>
                                    {elm.status === "processing" &&
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Processing By</div>
                                            <div className='odr-de-value'>{elm?.processing_by || "Super Admin"}</div>
                                        </span>
                                    }
                                </div>
                                {
                                    elm.status === "open" &&
                                    <div>
                                        <Button onClick={() => handleClaimOrder(elm?.orderNo, elm?.orderType)} sx={{ backgroundColor: '#1565c0', color: '#fff' }} disableElevation variant="contained" size="small">Claim</Button>
                                    </div>}
                            </div>
                        ))
                }
            </div>
        </div>
    )
};

export default Orders;