import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Orders from "./pages/Orders/Orders";
import Packages from "./pages/Packages/Packages";
import PackageDetails from "./pages/Packages/PackageDetails";
import ExportImport from "./pages/Settings/ExportImport";
import Fees from "./pages/Settings/Fees";
import Package from "./pages/Settings/Package";
import Shipping from "./pages/Settings/Shipping";
import Site from "./pages/Settings/Site";
import Users from "./pages/Settings/Users";
import ShippingCalculator from "./pages/ShippingCalculator/ShippingCalculator";
import AwaitingActions from "./pages/AwaitingActions/AwaitingActions";
import Completed from "./pages/Completed/Completed";
import Processing from "./pages/Processing/Processing";
import Help from "./pages/Help/Help";
import ShipOrConsolidate from "./pages/ShipOrConsolidate/ShipOrConsolidate";
import UserLogin from "./pages/LoginPage/UserLoginPage";
import AdminLogin from "./pages/LoginPage/AdminLoginPage";
import ConfirmPayment from "./pages/ConfirmPayment/ConfirmPayment";
import ClaimShippingOrders from "./pages/ClaimOrders/ClaimShippingOrders";
import ClaimConsolidationOrder from "./pages/ClaimOrders/ClaimConsolidationOrder";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ResetPasswordEmail from "./pages/ResetPassword/ResetPasswordEmail";
import OrderDetails from "./pages/Processing/OrderDetails";

const Protected = ({ children }) => {

    const userData = JSON.parse(localStorage.getItem("KToken"));
    const isSignedIn = !!userData?.token

    if (!isSignedIn) {
        return <Navigate to="/" replace />
    }
    return children
}

const NonProtected = ({ children }) => {

    const userData = JSON.parse(localStorage.getItem("KToken"));
    const isSignedIn = !!userData?.token
    const redirectTo = userData?.user?.role === "Customer" ? "/awaiting-actions" : "/packages";

    if (!isSignedIn) {
        return children
    }
    return <Navigate to={redirectTo} replace />
}


const AppRoutes = () => {

    return (
        <Router>
            <Routes>
                <Route path="/" element={<NonProtected><UserLogin /></NonProtected>} />
                <Route path="/admin-login" element={<NonProtected><AdminLogin /></NonProtected>} />
                <Route path="/reset-password/:token" element={<NonProtected><ResetPassword /></NonProtected>} />
                <Route path="/reset-password-email" element={<NonProtected><ResetPasswordEmail /></NonProtected>} />

                <Route path="/payment/:status/:orderNo" element={<Protected><ConfirmPayment /></Protected>} />
                <Route path="/packages" element={<Protected><Layout children={<Packages />} /></Protected>} />
                <Route path="/package-details/:id" element={<Protected><Layout children={<PackageDetails />} /></Protected>} />
                <Route path="/orders" element={<Protected><Layout children={<Orders />} /></Protected>} />
                <Route path="/shipping-order/:orderNo" element={<Protected><Layout children={<ClaimShippingOrders />} /></Protected>} />
                <Route path="/consolidation-order/:orderNo" element={<Protected><Layout children={<ClaimConsolidationOrder />} /></Protected>} />
                <Route path="/settings-users" element={<Protected><Layout children={<Users />} /></Protected>} />
                <Route path="/settings-shipping" element={<Protected><Layout children={<Shipping />} /></Protected>} />
                <Route path="/settings-package" element={<Protected><Layout children={<Package />} /></Protected>} />
                <Route path="/settings-fees" element={<Protected><Layout children={<Fees />} /></Protected>} />
                <Route path="/settings-site" element={<Protected><Layout children={<Site />} /></Protected>} />
                <Route path="/settings-export-import" element={<Protected><Layout children={<ExportImport />} /></Protected>} />
                <Route path="/shipping-calculator" element={<Protected><Layout children={<ShippingCalculator />} /></Protected>} />

                <Route path="/awaiting-actions" element={<Protected><Layout children={<AwaitingActions />} /></Protected>} />
                <Route path="/ship-consolidate" element={<Protected><Layout children={<ShipOrConsolidate />} /></Protected>} />
                <Route path="/completed" element={<Protected><Layout children={<Completed />} /></Protected>} />
                <Route path="/processing" element={<Protected><Layout children={<Processing />} /></Protected>} />
                <Route path="/order-details/:id" element={<Protected><Layout children={<OrderDetails />} /></Protected>} />
                <Route path="/shipping-calculator" element={<Protected><Layout children={<ShippingCalculator />} /></Protected>} />
                <Route path="/help" element={<Protected><Layout children={<Help />} /></Protected>} />
            </Routes>
        </Router>
    );
}

export default AppRoutes;