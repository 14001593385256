import React, { useState, useEffect } from 'react';
import { Button, TextField, CircularProgress, Box, FormControl, Autocomplete } from "@mui/material";
import "../../theme.styles.css";
import "../Packages/Packages.styles.css";
import { shippingCalculator, getPackageFeeSize } from "../../apis";
import { Country, City } from "country-state-city";

const ShippingCalculator = () => {
    const [stateData, setStateData] = useState({
        width: 0,
        height: 0,
        weight: 0,
        length: 0,
        city: "",
        zip: "",
        country: null
    });
    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disableCta, setDisableCta] = useState(true);
    const [packageFee, setPackageFee] = useState(null);
    const [countiresList, setCountriesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);

    useEffect(() => {
        fetchFee();
        const list = Country.getAllCountries();
        if (list?.length) setCountriesList(list);
    }, [])

    const fetchFee = async () => {
        const data = await getPackageFeeSize();
        if (data.status === 200) {
            setPackageFee(data?.data?.packageFee)
        }
    }

    const handleStateData = (event) => {
        event.preventDefault();
        setStateData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const handleCalculateRates = () => {
        setRates([])
        setLoading(true);
        const data = {
            parcel: {
                width: Number(stateData?.width),
                height: Number(stateData?.height),
                weight: Number(stateData?.weight),
                length: Number(stateData?.length),
            },
            city: stateData?.city?.name,
            zip: stateData?.zip,
            country: stateData?.country?.isoCode
        }
        shippingCalculator(data)
            .then((res) => {
                if (res.status === 200) {
                    const royalMailRates = res.data.royalMailRates || [];
                    const rates = res.data.rates || [];
                    const allRates = [...royalMailRates, ...rates]
                    setRates(allRates)
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);

            })
    }

    useEffect(() => {
        if (stateData.city && stateData.zip &&
            stateData.country && stateData.width && stateData.height && stateData.weight && stateData.length) setDisableCta(false);
        else setDisableCta(true);

    }, [stateData])

    const handleTotalCost = (value) => {
        let total = 0;
        const surchargeFee = packageFee?.surchageFee?.find(elm => elm.provider === value?.carrier)
        const countryTax = packageFee?.countryTax.find(elm => elm?.countries.includes(stateData?.country?.isoCode?.toUpperCase()))

        if (value && packageFee) {
            if (stateData?.weight <= packageFee?.small?.weight) {
                total = ((Number(value?.rate) + (surchargeFee?.perPackage || 0) + (stateData?.weight * (surchargeFee?.perKg || 1)) + packageFee?.small?.fee) * ((packageFee?.markup / 100) + 1) * ((packageFee?.cardFee / 100) + 1) * (((countryTax?.rate / 100) + 1) || 1));
            }
            else if (stateData?.weight <= packageFee?.medium?.weight) {
                total = ((Number(value?.rate) + (surchargeFee?.perPackage || 0) + (stateData?.weight * (surchargeFee?.perKg || 1)) + packageFee?.medium?.fee) * ((packageFee?.markup / 100) + 1) * ((packageFee?.cardFee / 100) + 1) * (((countryTax?.rate / 100) + 1) || 1));
            }
            else if (stateData?.weight <= packageFee?.large?.weight) {
                total = ((Number(value?.rate) + (surchargeFee?.perPackage || 0) + (stateData?.weight * (surchargeFee?.perKg || 1)) + packageFee?.large?.fee) * ((packageFee?.markup / 100) + 1) * ((packageFee?.cardFee / 100) + 1) * (((countryTax?.rate / 100) + 1) || 1));
            }
            else {
                total = ((Number(value?.rate) + (surchargeFee?.perPackage || 0) + (stateData?.weight * (surchargeFee?.perKg || 1)) + packageFee?.extraLarge?.fee) * ((packageFee?.markup / 100) + 1) * ((packageFee?.cardFee / 100) + 1) * (((countryTax?.rate / 100) + 1) || 1));
            }
        }

        return Number(total);
    }

    // Set cities list
    useEffect(() => {
        if (stateData?.country) {
            const list = City.getCitiesOfCountry(stateData?.country?.isoCode)
            if (list?.length) {
                setCitiesList(list);
            }
        }
        else {
            setCitiesList([])
            setStateData((prev) => ({
                ...prev,
                city: ""
            }))
        }
    }, [stateData?.country])

    return (
        <div>
            <div style={{ marginBottom: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 className="main-heading">Shipping Calculator</h1>
            </div>



            <div className="card-container">
                <p className='note'><span style={{ fontWeight: "bolder" }}>Shipping calculator note:</span> Complete the details as accurately as possible to generate a price. Note: Shipping
                    rates shown exclude VAT, local import duties and taxes,
                    advancement fee levied by the destination country are
                    the responsibility of the receiver.
                    Note: The actual shipping cost depends on the weight
                    and dimension of the package when we measure it.
                    Some carriers are not available for certain goods due to restrictions.
                </p>

                <div style={{ display: 'flex', marginTop: '32px' }} >
                    <FormControl fullWidth sx={{ width: '100%', marginRight: '16px' }}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={countiresList?.map(elm => ({
                                label: elm.name,
                                value: elm
                            }))}
                            name="country"
                            value={stateData.country?.name}
                            renderInput={(params) => <TextField {...params} label="Shipping Country" />}
                            onChange={(e, val) => {
                                if (!val) setStateData((prev) => ({ ...prev, country: null }))
                                else setStateData((prev) => ({ ...prev, country: val.value }))
                            }}
                            size="small"
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ width: '100%' }}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={stateData.country ?citiesList?.map(elm => ({
                                label: elm.name,
                                value: elm
                            })) : []}
                            name="country"
                            value={stateData.city.name}
                            renderInput={(params) => <TextField {...params} label="Shipping City" />}
                            onChange={(e, val) => {
                                if (!val) setStateData((prev) => ({ ...prev, city: null }))
                                else setStateData((prev) => ({ ...prev, city: val.value }))
                            }}
                            size="small"
                        />
                    </FormControl>
                </div>

                <div style={{ display: 'flex', marginTop: '32px' }} >
                    <TextField
                        sx={{ width: '100%', marginRight: '16px' }}
                        id="outlined-basic"
                        label="Shipping Zip"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name='zip'
                        value={stateData.zip}
                        onChange={handleStateData}
                    />
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="Package Weight (kg)"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name='weight'
                        value={stateData.weight}
                        onChange={handleStateData}
                    />
                </div>

                <div style={{ display: 'flex', marginTop: '32px' }} >
                    <TextField
                        sx={{ width: '100%', marginRight: '16px' }}
                        id="outlined-basic"
                        label="Package Width (cm)"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name='width'
                        value={stateData.width}
                        onChange={handleStateData}
                    />
                    <TextField
                        sx={{ width: '100%', marginRight: '16px' }}
                        id="outlined-basic"
                        label="Package Depth (cm)"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name='length'
                        value={stateData.length}
                        onChange={handleStateData}
                    />
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="Package Height (cm)"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name='height'
                        value={stateData.height}
                        onChange={handleStateData}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }} >
                    <Button disabled={loading || disableCta} variant="contained" disableElevation sx={{ width: '200px', backgroundColor: '#1565c0' }} onClick={handleCalculateRates}>
                        Estimate rates
                    </Button>
                </div>

            </div>

            <div className="card-container" style={{ marginTop: '32px' }}>
                <h3 style={{ marginBottom: '32px' }} className='sub-heading'>Rates:</h3>
                {
                    loading ?
                        (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>)
                        :
                        <>
                            {
                                rates?.length === 0 ?
                                    <Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                        <h1 style={{ color: "#0e284d" }}>No Rates Yet!</h1>
                                    </Box>
                                    :
                                    null
                            }
                        </>
                }
                {
                    rates &&
                    rates?.sort((a, b) => a?.rate - b?.rate)
                        .map((elm, ind) => (
                            <div key={elm?.id} className="row">
                                <span className='odr-de-item-2' style={{ minWidth: "182px" }}>
                                    <div className='odr-de-label'>Rate No.</div>
                                    <div className='odr-de-value'>{ind + 1}</div>
                                </span>
                                <span className='odr-de-item-2' style={{ minWidth: "182px" }}>
                                    <div className='odr-de-label'>Carrier</div>
                                    <div className='odr-de-value'>{elm?.carrier}</div>
                                </span>
                                <span className='odr-de-item-2' style={{ minWidth: "300px" }}>
                                    <div className='odr-de-label'>Service</div>
                                    <div className='odr-de-value'>{elm?.service}</div>
                                </span>
                                <span className='odr-de-item-2' style={{ minWidth: "182px" }}>
                                    <div className='odr-de-label'>Rate</div>
                                    <div className='odr-de-value'>£{handleTotalCost(elm)?.toLocaleString()}</div>
                                </span>
                            </div>
                        ))
                }
            </div>
        </div>
    )
}

export default ShippingCalculator;