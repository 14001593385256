import { Drawer } from "@mui/material";
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import "../../theme.styles.css";

const PackageDetails = ({
    open,
    handleClose,
    selectedPackage,
}) => {
    const [packageDetails, setPackageDetails] = React.useState(null);

    React.useEffect(() => {
        if (selectedPackage) setPackageDetails(selectedPackage);
    }, [selectedPackage])

    return (
        <Drawer
            anchor={"bottom"}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    height: 'calc(100vh - 80px)',
                    padding: '40px'
                }
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '32px' }}>
                        <h2 className='modal-heading'>Package Details &#10095; {packageDetails?.packageId}</h2>
                        <span style={{ cursor: 'pointer' }} onClick={handleClose}><CloseIcon /></span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <div className='pkg-details-body' style={{width: "100%"}}>
                            {
                                packageDetails &&
                                packageDetails?.packageItems?.map((elm, ind) => (
                                    <div key={elm?.item?._id + ind} className="row">
                                        <span className='odr-de-item-2'>
                                            <div className='odr-de-label'>Item No.</div>
                                            <div className='odr-de-value'>{ind + 1}</div>
                                        </span>
                                        <span className='odr-de-item-2'>
                                            <div className='odr-de-label'>Export Code</div>
                                            <div className='odr-de-value'>{elm?.item?.exportCode}</div>
                                        </span>
                                        <span className='odr-de-item-2'>
                                            <div className='odr-de-label'>*Quantity</div>
                                            <div className='odr-de-value'>{elm?.quantity}</div>
                                        </span>
                                        <span className='odr-de-item-2'>
                                            <div className='odr-de-label'>*Value Each (£)</div>
                                            <div className='odr-de-value'>{elm?.valueEach}</div>
                                        </span>
                                        <span className='odr-de-item-2'>
                                            <div className='odr-de-label'>Item Type</div>
                                            <div className='odr-de-value'>{elm?.item?.itemType}</div>
                                        </span>
                                        <span className='odr-de-item-2'>
                                            <div className='odr-de-label'>Item Details</div>
                                            <div className='odr-de-value text-wrap' >{elm?.item?.itemDescription}</div>
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

        </Drawer>
    );
}

export default PackageDetails;