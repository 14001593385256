import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Button, TextField, Drawer, FormControl, InputLabel, Select, MenuItem, Autocomplete } from "@mui/material";
import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { getAllUsers, getAllSenders, getAllExportCodes, addPackage, getUkAllDeliveryCompanies } from "../../apis";
import { errorToast, successToast, warningToast } from "../../components/toast/Toast";
import Barcode from 'react-barcode';

const steps = [
    "Customer's Data",
    "Sender's Data",
    "Package Details",
];

const CreatePackage = ({
    open,
    handleClose,
    fetchPackages
}) => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [usersList, setUsersList] = React.useState([]);
    const [sendersList, setSendersList] = React.useState([]);
    // const [courierCompaniesList, setCourierCompaniesList] = React.useState([]);
    const [ukDeliveryCompanies, setUkCompanies] = React.useState([]);
    const [exportCodesList, setExportCodesList] = React.useState([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const [selectedSender, setSelectedSender] = React.useState(null);
    const [packageDetails, setPackageDetails] = React.useState({
        trackingNumber: "",
        packageWeight: 0,
        packageWidth: 0,
        packageDepth: 0,
        packageHeight: 0,
        packageHandlingNote: ""
    });
    // const [selectedCourierCompany, setSelectedCourierCompany] = React.useState(null);
    const [selectedDeliveryCompany, setSelectedDeliveryCompany] = React.useState(null);
    const [disableNextCta, setDisableNextCta] = React.useState(true);
    const [selectedExportCode, setSelectedExportCode] = React.useState(null);
    const [addedItemList, setAddedItemList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [packageId, setPackageId] = React.useState("");

    React.useEffect(() => {
        fetchCustomers();
        fetchSenders();
        // fetchCourierCompanies();
        fetchUkDeliveryCompanies();
        fetchExportCodes();
    }, [])

    const fetchCustomers = async () => {
        const data = await getAllUsers(`?role=Customer`);
        if (data.status === 200) {
            setUsersList(data.data.users);
        }
    }

    const fetchSenders = async () => {
        const data = await getAllSenders();
        if (data.status === 200) {
            setSendersList(data.data.sender);
        }
    }

    // const fetchCourierCompanies = async () => {
    //     const data = await getAllCourierCompanies();
    //     if (data.status === 200) {
    //         setCourierCompaniesList(data.data.companies);
    //     }
    // }

    const fetchUkDeliveryCompanies = async () => {
        const data = await getUkAllDeliveryCompanies();
        console.log("DATA", data)
        if (data.status === 200) {
            setUkCompanies(data.data.deliveryCompanies);
        }
    }

    const fetchExportCodes = async () => {
        const data = await getAllExportCodes();
        if (data.status === 200) {
            setExportCodesList(data.data.exportCodes);
        }
    }

    const handleCustomerSelect = (event) => {
        event.preventDefault();
        setSelectedCustomer(event.target.value)
    }

    const handleSenderSelect = (event) => {
        event.preventDefault();
        setSelectedSender(event.target.value)
    }

    const handleCourierCompanySelect = (event) => {
        event.preventDefault();
        setSelectedDeliveryCompany(event.target.value)
    }

    React.useEffect(() => {
        if (activeStep === 0 && selectedCustomer) setDisableNextCta(false);
        else if (activeStep === 1 && selectedSender) setDisableNextCta(false);
        else if (activeStep === 2 && (packageDetails.trackingNumber && packageDetails.packageWeight &&
            packageDetails.packageDepth && packageDetails.packageWidth && packageDetails.packageHeight && addedItemList?.length)) setDisableNextCta(false);
        else setDisableNextCta(true);

    }, [activeStep, selectedCustomer, selectedSender, packageDetails, addedItemList])

    const handlePackageDetails = (event) => {
        event.preventDefault();
        setPackageDetails((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const handleSavePackage = () => {
        if (selectedCustomer && selectedSender && selectedDeliveryCompany && packageDetails.trackingNumber && packageDetails.packageWeight &&
            packageDetails.packageDepth && packageDetails.packageWidth && packageDetails.packageHeight && addedItemList?.length) {
            setLoading(true);

            const data = {
                package: {
                    customer: selectedCustomer._id,
                    sender: selectedSender._id,
                    courierCompany: selectedDeliveryCompany._id,
                    trackingNumber: packageDetails.trackingNumber,
                    weight: packageDetails.packageWeight,
                    height: packageDetails.packageHeight,
                    depth: packageDetails.packageDepth,
                    width: packageDetails.packageWidth,
                    handlingNote: packageDetails.packageHandlingNote,
                    packageItems: !addedItemList.length ? [] : addedItemList.map((elm) => ({
                        item: elm._id,
                        quantity: elm.quantity,
                        valueEach: elm.valueEach,
                        variant: elm.variant,
                        description: elm.itemDescription.split(',')[0],
                        hsTariffNumber: Number(elm.exportCode.replace('.', '')),
                    }))
                },
                isConsolidate: false
            }

            addPackage(data)
                .then((createPackage) => {
                    if (createPackage.status === 200 && createPackage?.data?.package?._id) {
                        console.log(createPackage?.data?.package);
                        setPackageId(createPackage?.data?.package?.packageId)
                    }
                })
                .catch(() => {
                    errorToast("There was an error creating package. Please try again later!")
                    setLoading(false)
                })
        }
        else {
            warningToast("Some mandatory fields are empty!");
        }
    }

    const handleExportCodeSelection = (event, value) => {
        event.preventDefault();
        setSelectedExportCode(value.value)
    }

    const handleAddItem = () => {
        if (selectedExportCode) {
            const exportCodeExist = addedItemList.findIndex((elm) => elm.exportCode === selectedExportCode.exportCode);
            const tempObj = JSON.parse(JSON.stringify(selectedExportCode));
            if (exportCodeExist === -1) {
                const newItem = {
                    ...tempObj,
                    quantity: 1,
                    valueEach: 0
                }
                setAddedItemList([...addedItemList, newItem]);
                setSelectedExportCode(null);
            }
            else {
                const tempArr = JSON.parse(JSON.stringify(addedItemList));
                tempArr[exportCodeExist].quantity = Number(tempArr[exportCodeExist].quantity) + 1;
                setAddedItemList([...tempArr]);
                setSelectedExportCode(null);
            }
        }
    }

    const handleItemQuantityChange = (e, item, key) => {
        if (item) {
            const exportCodeExist = addedItemList.findIndex((elm) => elm.exportCode === item.exportCode);
            const tempArr = JSON.parse(JSON.stringify(addedItemList));
            tempArr[exportCodeExist][key] = e.target.name === "variant" ? e.target.value : Number(e.target.value)
            setAddedItemList([...tempArr]);
        }
    }

    const handleModalClose = () => {
        setSelectedCustomer(null);
        setSelectedSender(null);
        // setSelectedCourierCompany(null);
        setSelectedDeliveryCompany(null);
        setPackageDetails({
            trackingNumber: "",
            packageWeight: 0,
            packageWidth: 0,
            packageDepth: 0,
            packageHeight: 0,
            packageHandlingNote: ""
        });
        setSelectedExportCode(null);
        setAddedItemList([]);
        setActiveStep(0);
        fetchPackages();
        handleClose();
    }

    const printBarCode = () => {
        const printableContent = document.getElementById("printable-content");
        const printWindow = window.open("", "", "height=1000,width=1000");
        printWindow.document.write(printableContent.innerHTML);
        printWindow.print();
        printWindow.location.reload();
        printWindow.close();
        setPackageId("");
        handleModalClose();
        successToast("Package Created Successfully")
        setLoading(false)
    }

    useEffect(()=>{
        if(packageId) printBarCode();
        // eslint-disable-next-line
    },[packageId])


    return (
        <Drawer
            anchor={"bottom"}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    height: 'calc(100vh - 80px)',
                    padding: '40px'
                }
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '32px' }}>
                        <h2 className='modal-heading'>Add a Package</h2>
                        <span style={{ cursor: 'pointer' }} onClick={handleClose}><CloseIcon /></span>
                    </div>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <div className='stepper-body'>
                            {
                                activeStep === 0 &&
                                <div>
                                    <FormControl fullWidth sx={{ marginRight: '16px', marginBottom: '42px' }}>
                                        <InputLabel id="demo-simple-select-label">Search Customer</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedCustomer}
                                            label="Search Customer"
                                            onChange={handleCustomerSelect}
                                        >
                                            {
                                                usersList && usersList.map(elm => (
                                                    <MenuItem key={elm._id} value={elm}>{`${elm.firstName} ${elm.lastName}`}</MenuItem>

                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        required
                                        sx={{ width: '100%', marginRight: '16px', marginBottom: '22px' }}
                                        id="outlined-basic"
                                        label="Customer Name"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={selectedCustomer && `${selectedCustomer?.firstName} ${selectedCustomer?.lastName}`}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        required
                                        sx={{ width: '100%', marginRight: '16px', marginBottom: '22px' }}
                                        id="outlined-basic"
                                        label="Customer ID"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={selectedCustomer && selectedCustomer?.userId}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        required
                                        sx={{ width: '100%', marginRight: '16px', marginBottom: '22px' }}
                                        id="outlined-basic"
                                        label="Customer Email"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={selectedCustomer && selectedCustomer?.email}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            }
                            {
                                activeStep === 1 &&
                                <div >
                                    <FormControl fullWidth sx={{ marginRight: '16px', marginBottom: '42px' }}>
                                        <InputLabel id="demo-simple-select-label">Search Sender</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedSender}
                                            label="Search Sender"
                                            onChange={handleSenderSelect}
                                        >
                                            {
                                                sendersList && sendersList.map(elm => (
                                                    <MenuItem key={elm._id} value={elm}>{elm.senderName}</MenuItem>

                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        required
                                        sx={{ width: '100%', marginRight: '16px', marginBottom: '22px' }}
                                        id="outlined-basic"
                                        label="Sender/Company Name"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        value={selectedSender && selectedSender?.senderName}
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '22px' }}>
                                        <TextField
                                            sx={{ width: '49%' }}
                                            id="outlined-basic"
                                            label="Sender's Address 1"
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={selectedSender && selectedSender?.address1}
                                        />
                                        <TextField
                                            sx={{ width: '49%' }}
                                            id="outlined-basic"
                                            label="Sender's Address 2"
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={selectedSender && selectedSender?.address1}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '22px' }}>
                                        <TextField
                                            sx={{ width: '49%' }}
                                            id="outlined-basic"
                                            label="Sender's Country"
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={selectedSender && selectedSender?.country}
                                        />
                                        <TextField
                                            sx={{ width: '49%' }}
                                            id="outlined-basic"
                                            label="Sender's City"
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={selectedSender && selectedSender?.city}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '22px' }}>
                                        <TextField
                                            sx={{ width: '49%' }}
                                            id="outlined-basic"
                                            label="Sender's Zip"
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={selectedSender && selectedSender?.zip}
                                        />
                                        <TextField
                                            sx={{ width: '49%' }}
                                            id="outlined-basic"
                                            label="Sender's VAT"
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            value={selectedSender && selectedSender?.vat}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                activeStep === 2 &&
                                <div >
                                    <FormControl fullWidth sx={{ marginRight: '16px', marginBottom: '42px' }}>
                                        <InputLabel id="demo-simple-select-label">Search Delivery Company</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedDeliveryCompany}
                                            label="Search Delivery Company"
                                            onChange={handleCourierCompanySelect}
                                        >
                                            {
                                                ukDeliveryCompanies && ukDeliveryCompanies.map(elm => (
                                                    <MenuItem key={elm._id} value={elm}>{elm.companyName}</MenuItem>

                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        required
                                        sx={{ width: '100%', marginRight: '16px', marginBottom: '22px' }}
                                        id="outlined-basic"
                                        label="Delivery Company"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={selectedDeliveryCompany && selectedDeliveryCompany?.companyName}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '22px' }}>
                                        <TextField
                                            required
                                            sx={{ width: '49%' }}
                                            id="outlined-basic"
                                            label="Tracking Number"
                                            variant="outlined"
                                            name="trackingNumber"
                                            size="small"
                                            value={packageDetails.trackingNumber}
                                            onChange={handlePackageDetails}
                                        />
                                        <TextField
                                            type="number"
                                            required
                                            sx={{ width: '49%' }}
                                            id="outlined-basic"
                                            label="Package Weight (kg)"
                                            variant="outlined"
                                            name="packageWeight"
                                            size="small"
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            value={packageDetails.packageWeight}
                                            onChange={handlePackageDetails}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '22px' }}>
                                        <TextField
                                            type="number"
                                            required
                                            sx={{ width: '32%' }}
                                            id="outlined-basic"
                                            label="Package Width (cm)"
                                            variant="outlined"
                                            name="packageWidth"
                                            size="small"
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            value={packageDetails.packageWidth}
                                            onChange={handlePackageDetails}
                                        />
                                        <TextField
                                            type="number"
                                            required
                                            sx={{ width: '32%' }}
                                            id="outlined-basic"
                                            label="Package Depth (cm)"
                                            variant="outlined"
                                            name="packageDepth"
                                            size="small"
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            value={packageDetails.packageDepth}
                                            onChange={handlePackageDetails}
                                        />
                                        <TextField
                                            type="number"
                                            required
                                            sx={{ width: '32%' }}
                                            id="outlined-basic"
                                            label="Package Height (cm)"
                                            variant="outlined"
                                            name="packageHeight"
                                            size="small"
                                            value={packageDetails.packageHeight}
                                            onChange={handlePackageDetails}
                                        />
                                    </div>
                                    <TextField
                                        sx={{ width: '100%', marginBottom: '22px' }}
                                        id="outlined-basic"
                                        label="Special Handling Note"
                                        variant="outlined"
                                        name="packageHandlingNote"
                                        size="small"
                                        minRows={2}
                                        value={packageDetails.packageHandlingNote}
                                        onChange={handlePackageDetails}
                                    />
                                    <div className='add-item-container'>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '16px', borderBottom: "1px dashed" }}>
                                            <h3>Add Items</h3>
                                            <div style={{ width: '50%', display: 'flex' }}>
                                                <FormControl fullWidth sx={{ width: '100%', marginRight: '16px' }}>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={exportCodesList && exportCodesList.map(elm => ({
                                                            label: elm.itemDescription,
                                                            value: elm
                                                        }))}
                                                        value={selectedExportCode?.itemDescription}
                                                        renderInput={(params) => <TextField {...params} label="Search Item" />}
                                                        onChange={handleExportCodeSelection}
                                                        size="small"
                                                    />
                                                </FormControl>
                                                <Button onClick={handleAddItem} disableElevation={true} size="small" variant='contained' sx={{ whiteSpace: 'nowrap', padding: "0 26px" }}>Add to Items</Button>
                                            </div>
                                        </div>
                                        <div style={{ overflowY: 'auto', height: '150px' }}>
                                            <table className='item-table'>
                                                <tbody>
                                                    {
                                                        addedItemList &&
                                                        addedItemList.map((item, ind) => (
                                                            <tr key={item.exportCode}>
                                                                <td className='item-table-cell'>
                                                                    <div className='item-table-cell-label'>Item No.</div>
                                                                    <div className='item-table-cell-value'>{ind + 1}</div>
                                                                </td>
                                                                <td className='item-table-cell'>
                                                                    <div className='item-table-cell-label'>Export Code</div>
                                                                    <div className='item-table-cell-value'>{item.exportCode}</div>
                                                                </td>
                                                                <td className='item-table-cell' style={{ maxWidth: "196px" }}>
                                                                    <div className='item-table-cell-label'>Item Details</div>
                                                                    <div className='item-table-cell-value text-wrap' style={{ maxWidth: "172px" }}>{item.itemDescription}</div>
                                                                </td>
                                                                <td className='item-table-cell'>
                                                                    <div className='item-table-cell-label'>Variant</div>
                                                                    <div className='item-table-cell-value'>
                                                                        <TextField
                                                                            required
                                                                            sx={{ width: '112px' }}
                                                                            id="outlined-basic"
                                                                            variant="outlined"
                                                                            name="variant"
                                                                            size="small"
                                                                            value={item?.variant}
                                                                            onChange={(e) => handleItemQuantityChange(e, item, "variant")}
                                                                            placeholder='Variant'
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className='item-table-cell'>
                                                                    <div className='item-table-cell-label'>Quantity</div>
                                                                    <div className='item-table-cell-value'>
                                                                        <TextField
                                                                            type="number"
                                                                            required
                                                                            sx={{ width: '72px' }}
                                                                            id="outlined-basic"
                                                                            variant="outlined"
                                                                            name="quantity"
                                                                            size="small"
                                                                            value={item?.quantity}
                                                                            onChange={(e) => handleItemQuantityChange(e, item, "quantity")}
                                                                            placeholder='Quantity'
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className='item-table-cell'>
                                                                    <div className='item-table-cell-label'>Value Each</div>
                                                                    <div className='item-table-cell-value'>
                                                                        <TextField
                                                                            type="number"
                                                                            required
                                                                            sx={{ width: '72px' }}
                                                                            id="outlined-basic"
                                                                            variant="outlined"
                                                                            name="packageHeight"
                                                                            size="small"
                                                                            value={item?.valueEach}
                                                                            onChange={(e) => handleItemQuantityChange(e, item, "valueEach")}
                                                                            placeholder='Value Each'
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className='item-table-cell'>
                                                                    <div className='item-table-cell-label'>Item Type</div>
                                                                    <div className='item-table-cell-value'>{item.itemType}</div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div style={{display:"none"}} id="printable-content" onClick={printBarCode}>
                                        <div>{packageId}</div>
                                        <div>{selectedCustomer && `${selectedCustomer?.firstName} ${selectedCustomer?.lastName}`}</div>
                                        <Barcode id="barcode" value={packageId} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='modal-bottom-ctas'>
                    <Button variant="contained" disabled={activeStep === 0} disableElevation sx={{ width: '146px', backgroundColor: '#1565c0' }} onClick={() => { setActiveStep(activeStep > 1 ? activeStep - 1 : 0) }}>
                        Back
                    </Button>
                    <Button disabled={disableNextCta || loading} variant="contained" disableElevation sx={{ width: '146px', backgroundColor: '#1565c0' }} onClick={() => { activeStep < (steps.length - 1) ? setActiveStep(activeStep < steps.length ? activeStep + 1 : steps.length - 1) : handleSavePackage() }}>
                        {activeStep < (steps.length - 1) ? "Next" : "Save"}
                    </Button>
                </div>
            </div>

        </Drawer>
    );
}

export default CreatePackage;