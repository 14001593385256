import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const userData = JSON.parse(localStorage.getItem("KToken"));

export const getCompanyData = ()=> axios.get(`${BASE_URL}/company`, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const updateCompanyData = (data)=> axios.post(`${BASE_URL}/company`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});