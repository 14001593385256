import React from 'react';
import { Button, TextField } from "@mui/material";
import "../../theme.styles.css";
import "../Packages/Packages.styles.css";

const Site = () => {

    return (
        <div>
            <div style={{ marginBottom: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 className="main-heading">Site Settings</h1>
            </div>



            <div className="card-container">
                <h3 className='sub-heading'>Terms and Conditions</h3>

                <div style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                    <TextField
                        sx={{ width: '100%', marginRight: '16px' }}
                        id="outlined-basic"
                        label="Terms and Conditions link"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                    />
                    <p style={{ marginRight: '16px' }}>or</p>
                    <Button variant="outlined" component="label" disableElevation sx={{ width: '100%', border: '1px solid #1565c0', color: "#1565c0" }} >
                        Upload PDF
                        <input hidden accept="image/*" multiple type="file" />
                    </Button>
                </div>

                {/* <Divider sx={{ marginTop: "32px" }} />

                <h3 style={{ marginTop: '32px' }} className='sub-heading'>Logo</h3>

                <div style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                    <Button variant="outlined" component="label" disableElevation sx={{ width: '50%', border: '1px solid #1565c0', color: "#1565c0" }} >
                        Upload Logo
                        <input hidden accept="image/*" multiple type="file" />
                    </Button>
                </div> */}

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }} >
                    <Button variant="contained" disableElevation sx={{ width: '200px', backgroundColor: '#1565c0' }} >
                        Save
                    </Button>
                </div>

            </div>
        </div>
    )
}

export default Site;