import React from 'react';
import { Button, TextField, Box, CircularProgress } from "@mui/material";
import "../../theme.styles.css";
import "../Packages/Packages.styles.css";
import { getCompanyData, updateCompanyData } from '../../apis';

const Shipping = () => {
    const [companyData, setCompanyData] = React.useState({
        name: "",
        email: "",
        phone:"",
        address1: "",
        address2: "",
        city: "",
        zip: "",
        country: "",
        vat: "",
        _id: ""
    });
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetchCompanyData();
    }, [])

    const fetchCompanyData = async () => {
        const data = await getCompanyData();
        if (data.status === 200) {
            setLoading(false);
            setCompanyData({
                company: data.data?.company?.company,
                email: data.data?.company?.email,
                phone: data.data?.company?.phone,
                street1: data.data?.company?.street1,
                street2: data.data?.company?.street2,
                city: data.data?.company?.city,
                zip: data.data?.company?.zip,
                country: data.data?.company?.country,
                vat: data.data?.company?.vat,
                _id: data.data?.company?._id
            })
        }
        else setLoading(false);
    }

    const handleCompanyDetails = (event) => {
        event.preventDefault();
        setCompanyData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const handleSave = async () => {
        setLoading(true)
        const company = await updateCompanyData(companyData);

        if(company.status === 200) {
            fetchCompanyData();
        }else {
            setLoading(false);
        }
    }

    return (
        <div>
            <div style={{ marginBottom: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 className="main-heading">Shipping Settings</h1>
            </div>



            <div className="card-container">
                <h3 className='sub-heading'>Shipper Address</h3>

                {
                    loading ?
                    (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                        <CircularProgress />
                    </Box>): <>

                    <div style={{ display: 'flex', marginTop: '32px' }} >
                        <TextField
                            sx={{ width: '100%', marginRight: '16px' }}
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            defaultValue=""
                            size="small"
                            name="company"
                            value={companyData?.company}
                            onChange={handleCompanyDetails}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            sx={{ width: '100%' }}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            defaultValue=""
                            size="small"
                            name="email"
                            value={companyData?.email}
                            onChange={handleCompanyDetails}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>

                    <div style={{ display: 'flex', marginTop: '32px' }} >
                        <TextField
                            sx={{ width: '100%', marginRight: '16px' }}
                            id="outlined-basic"
                            label="Address 1"
                            variant="outlined"
                            defaultValue=""
                            size="small"
                            name="street1"
                            value={companyData?.street1}
                            onChange={handleCompanyDetails}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            sx={{ width: '100%' }}
                            id="outlined-basic"
                            label="Address 2"
                            variant="outlined"
                            defaultValue=""
                            size="small"
                            name="street2"
                            value={companyData?.street2}
                            onChange={handleCompanyDetails}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>

                    <div style={{ display: 'flex', marginTop: '32px' }} >
                        <TextField
                            sx={{ width: '100%', marginRight: '16px' }}
                            id="outlined-basic"
                            label="City"
                            variant="outlined"
                            defaultValue=""
                            size="small"
                            name="city"
                            value={companyData?.city}
                            onChange={handleCompanyDetails}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            sx={{ width: '100%' }}
                            id="outlined-basic"
                            label="Zip"
                            variant="outlined"
                            defaultValue=""
                            size="small"
                            name="zip"
                            value={companyData?.zip}
                            onChange={handleCompanyDetails}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>

                    <div style={{ display: 'flex', marginTop: '32px' }} >
                        <TextField
                            sx={{ width: '100%', marginRight: '16px' }}
                            id="outlined-basic"
                            label="Country"
                            variant="outlined"
                            defaultValue=""
                            size="small"
                            name="country"
                            value={companyData?.country}
                            onChange={handleCompanyDetails}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            sx={{ width: '100%' }}
                            id="outlined-basic"
                            label="Phone"
                            variant="outlined"
                            defaultValue=""
                            size="small"
                            name="phone"
                            value={companyData?.phone}
                            onChange={handleCompanyDetails}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div style={{ display: 'flex', marginTop: '32px' }} >
                        <TextField
                            sx={{ width: '50%' }}
                            id="outlined-basic"
                            label="Shipper's VAT"
                            variant="outlined"
                            defaultValue=""
                            size="small"
                            name="vat"
                            value={companyData?.vat}
                            onChange={handleCompanyDetails}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }} >
                        <Button onClick={handleSave} variant="contained" disableElevation sx={{ width: '200px', backgroundColor: '#1565c0' }} >
                            Save
                        </Button>
                    </div>

                </>}
            </div>
        </div>
    )
}

export default Shipping;