import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const userData = JSON.parse(localStorage.getItem("KToken"));

export const getAllPackages = (queryParams)=> axios.get(`${BASE_URL}/package${queryParams}`, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const addPackage = (data) => axios.post(`${BASE_URL}/package`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const getPackageFeeSize = () => axios.get(`${BASE_URL}/packageFee`, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const updatePackageFeeSize = (data) => axios.post(`${BASE_URL}/packageFee`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const addPackageItems = (data) => axios.put(`${BASE_URL}/package/items`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const releasePackage = (data) => axios.post(`${BASE_URL}/order/releasePackage`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const editPackage = (data) => axios.patch(`${BASE_URL}/package`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const deletePackage = (data) => axios.delete(`${BASE_URL}/package`, {headers: {
    "Authorization": `Bearer ${userData?.token}`
},
data
});