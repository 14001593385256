import React from 'react';
import { Button, TextField, Divider, Box, CircularProgress, Pagination } from "@mui/material";
import "../../theme.styles.css";
import "../Packages/Packages.styles.css";
import { getPackageFeeSize, updatePackageFeeSize } from '../../apis';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

const Fees = () => {
    const [wholePkgData, setWholePkgData] = React.useState(null);
    const [pkgData, setPkgData] = React.useState({
        smallPkg: 0,
        medPkg: 0,
        largePkg: 0,
        xLarge: 0,
        markup: 0,
        cardFee: 0,
        storageFee: 0,
        standardConsolidationFee: {
            description: "",
            fee: 0
        },
        consolidationWithUnpack: {
            description: "",
            fee: 0
        },
        insuranceMinFee: 0,
        insurancePer: 0,
        surchageFee: []
    });
    const [loading, setLoading] = React.useState(true);
    const [surchargeFeepage, setSurchargeFeePage] = React.useState(1);
    const [countryTaxpage, setCountryTaxPage] = React.useState(1);
    const [surchargeFeeList, setSurchargeFeeList] = React.useState([]);
    const [countryTaxList, setCountryTaxList] = React.useState([]);
    const ITEMS_PER_PAGE = 5;

    const fetchPackageFeeSizeData = React.useCallback(async () => {
        const pkgData = await getPackageFeeSize();
        if (pkgData.status === 200) {
            setLoading(false);
            setWholePkgData(pkgData?.data?.packageFee);
            const tempData = pkgData?.data?.packageFee;
            setPkgData({
                smallPkg: tempData?.small?.fee,
                medPkg: tempData?.medium?.fee,
                largePkg: tempData?.large?.fee,
                xLarge: tempData?.extraLarge?.fee,
                markup: tempData?.markup,
                cardFee: tempData?.cardFee,
                storageFee: tempData?.storageFee,
                standardConsolidationFee: tempData?.standardConsolidationFee,
                consolidationWithUnpack: tempData?.consolidationWithUnpack,
                insuranceMinFee: tempData?.insuranceFee?.fixed,
                insurancePer: tempData?.insuranceFee?.percent
            })
            setSurchargeFeeList(formatData(tempData?.surchageFee))
            setCountryTaxList(formatData(tempData?.countryTax))
            setLoading(false);
        }
    }, [])

    React.useEffect(() => {
        fetchPackageFeeSizeData();
    }, [fetchPackageFeeSizeData])

    const formatData = (data) => {
        if (data?.length) {
            const result = data.map(elm => ({
                ...elm,
                countries: elm.countries.join(", ")
            }))
            return result
        }
        else return []
    }

    const handlePkgData = (event) => {
        event.preventDefault();

        if (event.target.name === "consolidationWithUnpackFee") {
            setPkgData((prevState) => ({
                ...prevState,
                consolidationWithUnpack: {
                    ...prevState.consolidationWithUnpack,
                    fee: Number(event.target.value)
                }
            }))
        }
        else if (event.target.name === "consolidationWithUnpackDesc") {
            setPkgData((prevState) => ({
                ...prevState,
                consolidationWithUnpack: {
                    ...prevState.consolidationWithUnpack,
                    description: event.target.value
                }
            }))
        }
        else if (event.target.name === "standardConsolidationFee") {
            setPkgData((prevState) => ({
                ...prevState,
                standardConsolidationFee: {
                    ...prevState.standardConsolidationFee,
                    fee: Number(event.target.value)
                }
            }))
        }
        else if (event.target.name === "standardConsolidationDesc") {
            setPkgData((prevState) => ({
                ...prevState,
                standardConsolidationFee: {
                    ...prevState.standardConsolidationFee,
                    description: event.target.value
                }
            }))
        }
        else {
            setPkgData((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value
            }))
        }
    }

    const handleSave = async () => {
        setLoading(true);
        const data = {
            ...wholePkgData,
            small: {
                ...wholePkgData.small,
                fee: Number(pkgData?.smallPkg)
            },
            medium: {
                ...wholePkgData.medium,
                fee: Number(pkgData?.medPkg)
            },
            large: {
                ...wholePkgData.large,
                fee: Number(pkgData?.largePkg)
            },
            extraLarge: {
                ...wholePkgData.extraLarge,
                fee: Number(pkgData?.xLarge)
            },
            markup: Number(pkgData?.markup),
            cardFee: Number(pkgData?.cardFee),
            storageFee: Number(pkgData?.storageFee),
            standardConsolidationFee: pkgData?.standardConsolidationFee,
            consolidationWithUnpack: pkgData?.consolidationWithUnpack,
            insuranceFee: {
                fixed: Number(pkgData?.insuranceMinFee),
                percent: Number(pkgData?.insurancePer)
            },
            surchageFee: surchargeFeeList?.map(elm => ({
                ...elm,
                countries: elm.countries.split(", ")
            })),
            countryTax: countryTaxList?.map(elm => ({
                ...elm,
                countries: elm.countries.split(", ")
            })),
        }

        const tempData = await updatePackageFeeSize(data);
        if (tempData.status === 200) {
            fetchPackageFeeSizeData();
        }
        else setLoading(false);
    }

    const handleItemCountiresChange = (e, item, type, index) => {
        if (item) {
            if (type === "surchargeFee") {
                const providerExist = surchargeFeeList?.findIndex((elm) => elm.provider === item.provider);
                const tempArr = JSON.parse(JSON.stringify(surchargeFeeList));
                tempArr[providerExist].countries = e.target.value
                setSurchargeFeeList(tempArr);
            }
            else {
                const tempArr = JSON.parse(JSON.stringify(countryTaxList));
                tempArr[index].countries = e.target.value
                setCountryTaxList(tempArr);
            }
        }
    }

    const handleSurchargeItemChange = (e, ind) => {
        e.preventDefault();
        const tempArr = JSON.parse(JSON.stringify(surchargeFeeList));
        tempArr[ind][e.target.name] = e.target.value
        setSurchargeFeeList(tempArr);
    }

    const handleCountryRateChange = (e, ind) => {
        e.preventDefault();
        const tempArr = JSON.parse(JSON.stringify(countryTaxList));
        tempArr[ind][e.target.name] = e.target.value
        setCountryTaxList(tempArr);
    }

    const AddNewRow = (type) => {
        if (type === "surchargeFee") {
            const obj = {
                countries: "",
                perKg: 0,
                perPackage: 0,
                provider: ""
            }
            setSurchargeFeeList((prev) => [...prev, obj])
        }
        else {
            const obj = {
                countries: "",
                rate: 0
            }
            setCountryTaxList((prev) => [...prev, obj])
        }
    }

    const removeRow = (e, type, ind) => {
        e.preventDefault();
        if (type === "surchargeFee") {
            const tempArr = JSON.parse(JSON.stringify(surchargeFeeList));
            tempArr.splice(ind, 1);
            setSurchargeFeeList(tempArr);
        }
        else {
            const tempArr = JSON.parse(JSON.stringify(countryTaxList));
            tempArr.splice(ind, 1);
            setCountryTaxList(tempArr);
        }
    }

    return (
        <div>
            <div style={{ marginBottom: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 className="main-heading">Fees Settings</h1>
            </div>



            <div className="card-container">
                <h3 className='sub-heading'>Shipping Base Fee</h3>


                {
                    loading ?
                        (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>)
                        :
                        <>


                            <div style={{ display: 'flex', marginTop: '32px' }} >
                                <TextField
                                    sx={{ width: '100%', marginRight: '16px' }}
                                    id="outlined-basic"
                                    label="Small Package (£)"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="smallPkg"
                                    value={pkgData?.smallPkg}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="outlined-basic"
                                    label="Medium Package (£)"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="medPkg"
                                    value={pkgData?.medPkg}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>

                            <div style={{ display: 'flex', marginTop: '32px' }} >
                                <TextField
                                    sx={{ width: '100%', marginRight: '16px' }}
                                    id="outlined-basic"
                                    label="Large Package (£)"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="largePkg"
                                    value={pkgData?.largePkg}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="outlined-basic"
                                    label="XL Package (£)"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="xLarge"
                                    value={pkgData?.xLarge}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>

                            <div style={{ display: 'flex', marginTop: '32px' }} >
                                <TextField
                                    sx={{ width: '100%', marginRight: '16px' }}
                                    id="outlined-basic"
                                    label="Markup (%)"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="markup"
                                    value={pkgData?.markup}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="outlined-basic"
                                    label="Card Fee (%)"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="cardFee"
                                    value={pkgData?.cardFee}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>

                            <Divider sx={{ marginTop: "32px" }} />

                            <h3 style={{ marginTop: '32px' }} className='sub-heading'>Storage Fee</h3>

                            <div style={{ display: 'flex', marginTop: '32px' }} >
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="outlined-basic"
                                    label="Storage Charges Per Day"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="storageFee"
                                    value={pkgData?.storageFee}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>

                            <Divider sx={{ marginTop: "32px" }} />

                            <h3 style={{ marginTop: '32px' }} className='sub-heading'>Consolidation Fee</h3>

                            <div style={{ display: 'flex', marginTop: '32px' }} >
                                <TextField
                                    sx={{ width: '100%', marginRight: '16px' }}
                                    id="outlined-basic"
                                    label="Standard Consolidation (per package)"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="standardConsolidationFee"
                                    value={pkgData?.standardConsolidationFee?.fee}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="outlined-basic"
                                    label="Description"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="standardConsolidationDesc"
                                    value={pkgData?.standardConsolidationFee?.description}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>

                            <div style={{ display: 'flex', marginTop: '32px' }} >
                                <TextField
                                    sx={{ width: '100%', marginRight: '16px' }}
                                    id="outlined-basic"
                                    label="Consolidation with unpacking (per package)"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="consolidationWithUnpackFee"
                                    value={pkgData?.consolidationWithUnpack?.fee}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="outlined-basic"
                                    label="Description"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="consolidationWithUnpackDesc"
                                    value={pkgData?.consolidationWithUnpack?.description}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>

                            <Divider sx={{ marginTop: "32px" }} />

                            <h3 style={{ marginTop: '32px' }} className='sub-heading'>Extende Insurance Fee</h3>

                            <div style={{ display: 'flex', marginTop: '32px', alignItems: "center" }} >
                                <TextField
                                    sx={{ width: '100%', marginRight: '16px' }}
                                    id="outlined-basic"
                                    label="Minimum Fee"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="insuranceMinFee"
                                    value={pkgData?.insuranceMinFee}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <p style={{ marginRight: '16px' }}>or</p>
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="outlined-basic"
                                    label='of the "Total item cost", whichever is greater'
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="insurancePer"
                                    value={pkgData?.insurancePer}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>

                            <Divider sx={{ marginTop: "32px" }} />

                            <div style={{ marginTop: '32px', display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                <h3 className='sub-heading'>Shipping Surcharge Fee</h3>
                                <Button onClick={() => AddNewRow("surchargeFee")} variant="contained" disableElevation >
                                    <AddIcon />
                                </Button >
                            </div>

                            <div style={{ marginTop: '32px' }} >
                                {
                                    surchargeFeeList?.slice((surchargeFeepage - 1) * ITEMS_PER_PAGE, surchargeFeepage * ITEMS_PER_PAGE)
                                        .map((elm, ind) => (
                                            <div key={ind} className="row" style={{ flexWrap: "initial" }}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <span className='odr-de-item'>
                                                        <div className='odr-de-label'>Shipping Provider</div>
                                                        <div className='odr-de-value'>
                                                            <TextField
                                                                required
                                                                sx={{ width: '186px' }}
                                                                id="outlined-basic"
                                                                variant="outlined"
                                                                name="provider"
                                                                size="small"
                                                                value={elm?.provider}
                                                                onChange={(e) => handleSurchargeItemChange(e, ind)}
                                                                placeholder='Provider'
                                                            />
                                                        </div>
                                                    </span>
                                                    <span className='odr-de-item'>
                                                        <div className='odr-de-label'>Countires (2 letter ISO)</div>
                                                        <TextField
                                                            required
                                                            sx={{ width: '186px' }}
                                                            id="outlined-basic"
                                                            variant="outlined"
                                                            name="countires"
                                                            size="small"
                                                            value={elm?.countries}
                                                            onChange={(e) => handleItemCountiresChange(e, elm, "surchargeFee", ind)}
                                                            placeholder='Countires'
                                                        />
                                                    </span>
                                                    <span className='odr-de-item'>
                                                        <div className='odr-de-label'>Per Package(£)</div>
                                                        <div className='odr-de-value'>
                                                            <TextField
                                                                required
                                                                sx={{ width: '186px' }}
                                                                id="outlined-basic"
                                                                variant="outlined"
                                                                name="perPackage"
                                                                size="small"
                                                                value={elm?.perPackage}
                                                                onChange={(e) => handleSurchargeItemChange(e, ind)}
                                                                placeholder='Per Package(£)'
                                                            />
                                                        </div>
                                                    </span>
                                                    <span className='odr-de-item'>
                                                        <div className='odr-de-label'>Per Kg(£)</div>
                                                        <div className='odr-de-value'>
                                                            <TextField
                                                                required
                                                                sx={{ width: '186px' }}
                                                                id="outlined-basic"
                                                                variant="outlined"
                                                                name="perKg"
                                                                size="small"
                                                                value={elm?.perKg}
                                                                onChange={(e) => handleSurchargeItemChange(e, ind)}
                                                                placeholder='Per perKg(£)'
                                                            />
                                                        </div>
                                                    </span>
                                                </div>
                                                <div>
                                                    <Button onClick={(e) => removeRow(e, "surchargeFee", ind)} variant="outlined" sx={{borderWidth: 0, padding: "5px", minWidth: 0, borderRadius: "50px"}} disableElevation>
                                                        <DeleteOutlineRoundedIcon />
                                                    </Button>
                                                </div>
                                            </div>
                                        ))
                                }
                                {(surchargeFeeList?.length > ITEMS_PER_PAGE) && <div style={{ marginTop: "32px" }}>
                                    <Pagination page={surchargeFeepage} count={Math.ceil(surchargeFeeList?.length / ITEMS_PER_PAGE)} shape="rounded" onChange={(e, page) => { e.preventDefault(); setSurchargeFeePage(page) }} />
                                </div>}
                            </div>

                            <Divider sx={{ marginTop: "32px" }} />

                            <div style={{ marginTop: '32px', display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                <h3 className='sub-heading'>Country Tax</h3>
                                <Button onClick={() => AddNewRow("countryTax")} variant="contained" disableElevation >
                                    <AddIcon />
                                </Button >
                            </div>

                            <div style={{ marginTop: '32px' }} >
                                {
                                    countryTaxList?.slice((countryTaxpage - 1) * ITEMS_PER_PAGE, countryTaxpage * ITEMS_PER_PAGE)
                                        .map((elm, ind) => (
                                            <div key={ind} className="row" style={{ flexWrap: "initial", justifyContent: "space-between" }}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <span className='odr-de-item'>
                                                        <div className='odr-de-label'>Countires (2 letter ISO)</div>
                                                        <div className='odr-de-value'>
                                                            <TextField
                                                                required
                                                                sx={{ width: '186px' }}
                                                                id="outlined-basic"
                                                                variant="outlined"
                                                                name="countires"
                                                                size="small"
                                                                value={elm?.countries}
                                                                onChange={(e) => handleItemCountiresChange(e, elm, "countryTax", ind)}
                                                                placeholder='Countires'
                                                            />
                                                        </div>
                                                    </span>
                                                    <span className='odr-de-item'>
                                                        <div className='odr-de-label'>Tax Rate(%)</div>
                                                        <div className='odr-de-value'>
                                                            <TextField
                                                                required
                                                                sx={{ width: '186px' }}
                                                                id="outlined-basic"
                                                                variant="outlined"
                                                                name="rate"
                                                                size="small"
                                                                value={elm?.rate}
                                                                onChange={(e) => handleCountryRateChange(e, ind)}
                                                                placeholder='Rate'
                                                            />
                                                        </div>
                                                    </span>
                                                </div>
                                                <div>
                                                    <Button onClick={(e) => removeRow(e, "countryTax", ind)} variant="outlined" sx={{borderWidth: 0, padding: "5px", minWidth: 0, borderRadius: "50px"}} disableElevation>
                                                        <DeleteOutlineRoundedIcon />
                                                    </Button>
                                                </div>
                                            </div>
                                        ))
                                }
                                {(countryTaxList?.length > ITEMS_PER_PAGE) && <div style={{ marginTop: "32px" }}>
                                    <Pagination page={countryTaxpage} count={Math.ceil(countryTaxList?.length / ITEMS_PER_PAGE)} shape="rounded" onChange={(e, page) => { e.preventDefault(); setCountryTaxPage(page) }} />
                                </div>}
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }} >
                                <Button onClick={handleSave} variant="contained" disableElevation sx={{ width: '200px', backgroundColor: '#1565c0' }} >
                                    Save
                                </Button>
                            </div>
                        </>
                }

            </div>
        </div>
    )
}

export default Fees;