import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import "./Layout.styles.css";
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import CalculateIcon from '@mui/icons-material/Calculate';
import InventoryIcon from '@mui/icons-material/Inventory';
import BallotIcon from '@mui/icons-material/Ballot';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Collapse, Drawer } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

const TopBar = () => {
  const location = useLocation();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const userData = JSON.parse(localStorage.getItem("KToken"));
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    
    localStorage.removeItem("KToken");
    const redirectTo = userData?.user?.role === "Customer" ? "/" : "/admin-login";
    navigate(redirectTo);
    handleCloseUserMenu();
  }

  return (
    <>
      <AppBar position="static" className='top-bar' sx={{ backgroundColor: '#fff', position: 'sticky', top: 0, zIndex: 1 }}>
        <Toolbar disableGutters>
          <MenuRoundedIcon onClick={() => { setOpenSidebar(true) }} className="mobile-menu-icon" />
          <img className='logo' src={require("../../assets/logo.png")} alt="" width={"48px"} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 5,
              ml: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: '#071E55',
              textDecoration: 'none',
            }}
          >
            KShip
          </Typography>

          <Box sx={{ flexGrow: 1, display: 'flex' }}>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip>
              <div onClick={handleOpenUserMenu} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <IconButton sx={{ p: 0 }}>
                  <Avatar alt={userData?.admin?.role === "SuperAdmin" ? "Super Admin" : `${userData?.user?.firstName} ${userData?.user?.lastName}`} src="/static/images/avatar/2.jpg" />
                </IconButton>
                <Typography sx={{ marginLeft: '12px', color: '#0e284d', fontWeight: '600' }}>
                  {userData?.admin?.role === "SuperAdmin" ? "Super Admin" : `${userData?.user?.firstName} ${userData?.user?.lastName}`}
                </Typography>
              </div>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>


      <Drawer
        anchor={"left"}
        open={openSidebar}
        onClose={() => { setOpenSidebar(false) }}
        className="mobile-side-nav"
      >
        <div style={{ display: 'flex', alignItems: 'center', padding: '12px 32px' }}>
          <img src={require("../../assets/logo.png")} alt="" width={"38px"} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 5,
              ml: 2,
              display: { md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: '#071E55',
              textDecoration: 'none',
            }}
          >
            KShip
          </Typography>
        </div>
        <MenuList>
          <Link to={'/'} style={{ textDecoration: 'none' }}>
            <MenuItem className={location.pathname === "/" ? 'active-item' : 'sidebar-menu-item'} selected={location.pathname === "/"}>
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText>Packages</ListItemText>
            </MenuItem>
          </Link>
          <Link to={'/orders'} style={{ textDecoration: 'none' }}>
            <MenuItem className={location.pathname === "/orders" ? 'active-item' : 'sidebar-menu-item'} selected={location.pathname === "/orders"}>
              <ListItemIcon>
                <BallotIcon />
              </ListItemIcon>
              <ListItemText>Orders</ListItemText>
            </MenuItem>
          </Link>
          <MenuItem className='sidebar-menu-item' onClick={handleClick}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
            {open ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
          </MenuItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <MenuList>
              <Link to={'/settings-users'} style={{ textDecoration: 'none' }}>
                <MenuItem className={location.pathname === "/settings-users" ? 'active-sub-item' : 'sidebar-sub-menu-item'} selected={location.pathname === "/settings-users"}>
                  <ListItemText>Users</ListItemText>
                </MenuItem>
              </Link>
              <Link to={'/settings-shipping'} style={{ textDecoration: 'none' }}>
                <MenuItem className={location.pathname === "/settings-shipping" ? 'active-sub-item' : 'sidebar-sub-menu-item'} selected={location.pathname === "/settings-shipping"}>
                  <ListItemText>Shipping</ListItemText>
                </MenuItem>
              </Link>
              <Link to={'/settings-export-import'} style={{ textDecoration: 'none' }}>
                <MenuItem className={location.pathname === "/settings-export-import" ? 'active-sub-item' : 'sidebar-sub-menu-item'} selected={location.pathname === "/settings-export-import"}>
                  <ListItemText>Export/Import</ListItemText>
                </MenuItem>
              </Link>
              <Link to={'/settings-package'} style={{ textDecoration: 'none' }}>
                <MenuItem className={location.pathname === "/settings-package" ? 'active-sub-item' : 'sidebar-sub-menu-item'} selected={location.pathname === "/settings-package"}>
                  <ListItemText>Package</ListItemText>
                </MenuItem>
              </Link>
              <Link to={'/settings-fees'} style={{ textDecoration: 'none' }}>
                <MenuItem className={location.pathname === "/settings-fees" ? 'active-sub-item' : 'sidebar-sub-menu-item'} selected={location.pathname === "/settings-fees"}>
                  <ListItemText>Fees</ListItemText>
                </MenuItem>
              </Link>
              <Link to={'/settings-site'} style={{ textDecoration: 'none' }}>
                <MenuItem className={location.pathname === "/settings-site" ? 'active-sub-item' : 'sidebar-sub-menu-item'} selected={location.pathname === "/settings-site"}>
                  <ListItemText>Site</ListItemText>
                </MenuItem>
              </Link>
            </MenuList>
          </Collapse>
          <Link to={'/shipping-calculator'} style={{ textDecoration: 'none' }}>
            <MenuItem className={location.pathname === "/shipping-calculator" ? 'active-item' : 'sidebar-menu-item'} selected={location.pathname === "/shipping-calculator"}>
              <ListItemIcon>
                <CalculateIcon />
              </ListItemIcon>
              <ListItemText>Shipping Calculator</ListItemText>
            </MenuItem>
          </Link>
        </MenuList>
      </Drawer>
    </>
  );
}
export default TopBar;