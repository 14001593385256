import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import ImageLogo from "../../assets/logo.png"

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: 16,
        justifyContent: "flex-start",
    },
    invoiceHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 62,
        height: "0px"
    },
    invoiceNumber: {
        fontSize: 16
    },
    logo: {
        width: "36px",
        height: "36px",
        marginRight: 6
    },
    addressSection: {
        flexDirection: "row",
        width: "100%"
    },
    customerDetails: {
        borderWidth: "1px",
        borderColor: "#000",
        padding: 16,
        paddingTop: 26,
        flexGrow: 1
    },
    BillingAddress: {
        borderWidth: "1px",
        borderColor: "#000",
        borderLeftWidth: 0,
        padding: 16,
        paddingTop: 26,
        flexGrow: 1,
    },
    boldHeading: {
        fontWeight: "bold",
        fontSize: 12,
        marginBottom: 12
    },
    directionRow: {
        flexDirection: "row",
        marginBottom: "6"
    },
    label: {
        fontWeight: 700,
        fontSize: 10,
        marginRight: 6
    },
    value: {
        fontSize: 10
    },
    itemDetails: {
        borderWidth: "1px",
        borderColor: "#000",
        padding: 4,
        width: "15%"
    }
});

// Create Document Component
export const OrderInvoice = ({data, companyData}) => {
    
   return( <Document>
        <Page size="A3" style={styles.page}>
            {/* Invoice Heade */}
            <View style={styles.invoiceHeader}>
                <View style={{flexDirection:'row', alignItems:"center", marginTop: 16}}>
                    <Image style={styles.logo} src={`${ImageLogo}`} />
                    <Text>KShip</Text>
                </View>
                <Text style={styles.invoiceNumber} >Order {data?.orderNo}</Text>
            </View>

            {/* Customer Details & Address Section */}
            <View style={styles.addressSection}>
                <View style={styles.customerDetails}>
                    <Text style={styles.boldHeading} >Details</Text>
                    <View style={styles.directionRow}>
                        <Text style={styles.label}>Customer:</Text>
                        <Text style={styles.value}>{`${data?.customer?.firstName} ${data?.customer?.lastName}`}</Text>
                    </View>
                    <View style={styles.directionRow}>
                        <Text style={styles.label}>Email:</Text>
                        <Text style={styles.value}>{data?.customer?.email}</Text>
                    </View>
                    <View style={styles.directionRow}>
                        <Text style={styles.label}>Total:</Text>
                        <Text style={styles.value}>{`£${(data?.shippingFee || data?.consolidationFee || 0)?.toFixed(2)}`}</Text>
                    </View>
                    <View style={styles.directionRow}>
                        <Text style={styles.label}>Date:</Text>
                        <Text style={styles.value}>{new Date(data?.createdAt)?.toLocaleString()}</Text>
                    </View>
                    <View style={styles.directionRow}>
                        <Text style={styles.label}>Status:</Text>
                        <Text style={styles.value}>{data?.consolidationFeeStatus=== "paid" ? data?.consolidationFeeStatus : data?.shippingPaymentStatus}</Text>
                    </View>
                    <View style={styles.directionRow}>
                        <Text style={styles.label}>Package Ref:</Text>
                        <Text style={styles.value}>{data?.packages.map(elm=>(`${elm?.packageId}${data?.packages?.length > 1 ? ", ": ""}`))}</Text>
                    </View>
                    <View style={styles.directionRow}>
                        <Text style={styles.label}>Terms and conditions:</Text>
                        <Text style={styles.value}>I agree to terms and conditions checkbox selected.</Text>
                    </View>
                </View>
                <View style={styles.BillingAddress}>
                    <Text style={styles.boldHeading} >Billing Address</Text>
                    <View style={styles.directionRow}>
                        <Text style={styles.value}>{data?.receiver?.billingAddress?.street1 || "-"}</Text>
                    </View>
                    {data?.receiver?.billingAddress?.street2 && <View style={styles.directionRow}>
                        <Text style={styles.value}>{data?.receiver?.billingAddress?.street2 || "-"}</Text>
                    </View>}
                    <View style={styles.directionRow}>
                        <Text style={styles.value}>{data?.receiver?.billingAddress?.city || "-"}</Text>
                    </View>
                    <View style={styles.directionRow}>
                        <Text style={styles.value}>{data?.receiver?.billingAddress?.zip || "-"}</Text>
                    </View>
                    <View style={styles.directionRow}>
                        <Text style={styles.value}>{data?.receiver?.billingAddress?.country || "-"}</Text>
                    </View>
                </View>
                <View style={styles.BillingAddress}>
                    <Text style={styles.boldHeading} >Receiver's address (Delivery address)</Text>
                    <View style={styles.directionRow}>
                        <Text style={styles.value}>{data?.receiver?.receivingAddress?.street1 || "-"}</Text>
                    </View>
                    {data?.receiver?.receivingAddress?.street2 && <View style={styles.directionRow}>
                        <Text style={styles.value}>{data?.receiver?.receivingAddress?.street2 || "-"}</Text>
                    </View>}
                    <View style={styles.directionRow}>
                        <Text style={styles.value}>{data?.receiver?.receivingAddress?.city || "-"}</Text>
                    </View>
                    <View style={styles.directionRow}>
                        <Text style={styles.value}>{data?.receiver?.receivingAddress?.zip || "-"}</Text>
                    </View>
                    <View style={styles.directionRow}>
                        <Text style={styles.value}>{data?.receiver?.receivingAddress?.country || "-"}</Text>
                    </View>
                </View>
            </View>

            {/* Item Details */}
            <View style={{...styles.addressSection, marginTop: 16}}>
                <View style={{...styles.itemDetails, width: "60%"}}>
                    <Text style={{...styles.boldHeading, marginBottom: 0}}>Item</Text>
                </View>
                <View style={styles.itemDetails}><Text style={{...styles.boldHeading, marginBottom: 0}}>Price</Text></View>
                <View style={styles.itemDetails}><Text style={{...styles.boldHeading, marginBottom: 0}}>Quantity</Text></View>
                <View style={styles.itemDetails}><Text style={{...styles.boldHeading, marginBottom: 0}}>Total</Text></View>
            </View>
            <View style={{...styles.addressSection}}>
                <View style={{...styles.itemDetails, width: "60%"}}><Text style={styles.value}>
                Standard Insurance</Text>
                </View>
                <View style={styles.itemDetails}><Text style={styles.value}>£{data?.extendedInsuranceFee?.toFixed(2) || 0}</Text></View>
                <View style={styles.itemDetails}><Text style={styles.value}>1</Text></View>
                <View style={styles.itemDetails}><Text style={styles.value}>£{data?.extendedInsuranceFee?.toFixed(2) || 0}</Text></View>
            </View>
            {data?.selectedCarrier?.carrier && <View style={{...styles.addressSection}}>
                <View style={{...styles.itemDetails, width: "60%"}}>
                <Text style={styles.value}>Shipping - {data?.selectedCarrier?.carrier} ({data?.selectedCarrier?.service}) - Package ref or ID {data?.packages.map(elm=>(`${elm?.packageId}${data?.packages?.length > 1 ? ", ": ""}`))}</Text>
                </View>
                <View style={styles.itemDetails}><Text style={styles.value}>£{data?.selectedCarrier?.rate?.toFixed(2)}</Text></View>
                <View style={styles.itemDetails}><Text style={styles.value}>1</Text></View>
                <View style={styles.itemDetails}><Text style={styles.value}>£{data?.selectedCarrier?.rate?.toFixed(2)}</Text></View>
            </View>}
            <View style={{...styles.addressSection}}>
                <View style={{...styles.itemDetails, width: "60%"}}>
                <Text style={styles.value}>Combined Package Storage</Text>
                </View>
                <View style={styles.itemDetails}><Text style={styles.value}>£{data?.storageFee?.toFixed(2) || 0}</Text></View>
                <View style={styles.itemDetails}><Text style={styles.value}>1</Text></View>
                <View style={styles.itemDetails}><Text style={styles.value}>£{data?.storageFee?.toFixed(2) || 0}</Text></View>
            </View>
            <View style={{...styles.addressSection}}>
                <View style={{...styles.itemDetails, width: "60%", borderWidth: 0}}></View>
                <View style={{...styles.itemDetails, borderWidth: 0}}></View>
                <View style={styles.itemDetails}><Text style={{...styles.value}}>Sub Total</Text></View>
                <View style={styles.itemDetails}><Text style={styles.value}>£{((data?.extendedInsuranceFee || 0) + (data?.selectedCarrier?.rate || 0) + (data?.storageFee || 0))?.toFixed(2)}</Text></View>
            </View>
            <View style={{...styles.addressSection}}>
                <View style={{...styles.itemDetails, width: "60%", borderWidth: 0}}></View>
                <View style={{...styles.itemDetails, borderWidth: 0}}></View>
                <View style={styles.itemDetails}><Text style={{...styles.value}}>Taxes</Text></View>
                <View style={styles.itemDetails}><Text style={styles.value}>£{((data?.shippingFee || 0) - ((data?.extendedInsuranceFee || 0) + (data?.selectedCarrier?.rate || 0) + (data?.storageFee || 0)))?.toFixed(2)}</Text></View>
            </View>
            <View style={{...styles.addressSection}}>
                <View style={{...styles.itemDetails, width: "60%", borderWidth: 0}}></View>
                <View style={{...styles.itemDetails, borderWidth: 0}}></View>
                <View style={styles.itemDetails}><Text style={{...styles.value}}>Total</Text></View>
                <View style={styles.itemDetails}><Text style={styles.value}>£{(data?.shippingFee || data?.consolidationFee || 0)?.toFixed(2)}</Text></View>
            </View>

            {/* Company Details */}
            <View>
                <Text style={{...styles.boldHeading, marginTop: 24}}>Company (Shipper details)</Text>
                <Text style={styles.value}>{companyData?.company}</Text>
                <Text style={styles.value}>{companyData?.street1}</Text>
                <Text style={styles.value}>{companyData?.street2}</Text>
                <Text style={styles.value}>{companyData?.zip}</Text>
                <Text style={styles.value}>{companyData?.country}</Text>
                <Text style={styles.value}>{companyData?.email}</Text>
            </View>
        </Page>
    </Document>)
};