import React, { useEffect } from 'react';
import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress, Box, Chip, Pagination } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import "../../theme.styles.css";
import "./Packages.styles.css";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CreatePackage from './CreatePackage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getAllPackages, getUkAllDeliveryCompanies } from "../../apis";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { convertPackagesToCSV } from '../../utils/convertToCsv';
import { useNavigate } from 'react-router';

const Packages = () => {

    const [open, setOpen] = React.useState(false);
    const [packages, setPackages] = React.useState([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedCourierCompany, setSelectedCourierCompany] = React.useState(null);
    const [courierCompaniesList, setCourierCompaniesList] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [dateFrom, setDateFrom] = React.useState(null);
    const [dateTo, setDateTo] = React.useState(null);
    const [searchText, setSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);
    const ITEMS_PER_PAGE = 50;
    const navigate = useNavigate();

    useEffect(() => {
        fetchPackages();
        fetchCourierCompanies();
    }, [])

    const fetchPackages = (params) => {
        const queryParams = params || "";
        getAllPackages(queryParams)
            .then((data) => {
                if (data.status === 200) {
                    setPackages(data.data?.packages)
                    setLoading(false);
                }
                else setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }

    const fetchCourierCompanies = async () => {
        const data = await getUkAllDeliveryCompanies();
        if (data.status === 200) {
            setCourierCompaniesList(data.data.deliveryCompanies);
        }
    }

    const handleCourierCompanySelect = (event) => {
        event.preventDefault();
        setSelectedCourierCompany(event.target.value)
    }

    const calculateInStoreDays = (receivedDate) => {
        let numberOfDays = 0;
        if (receivedDate) {
            const diff = Math.abs(new Date() - new Date(receivedDate));
            numberOfDays = Math.ceil(diff / (1000 * 3600 * 24))
        }
        return numberOfDays;
    }

    const handleSearch = () => {
        let queryParams = "?";
        const searchTextParam = searchText ? `searchKey=${searchText}&` : "";
        const courierCompany = selectedCourierCompany ?  `courierCompany=${selectedCourierCompany?._id}&` : "";
        const dataFromParam = dateFrom ? `dateFrom=${new Date(dateFrom)}&` : "";
        const dataToParam = dateTo ? `dateTo=${new Date(dateTo)}&` : "";
        queryParams = `${queryParams}${searchTextParam}${dataFromParam}${dataToParam}${courierCompany}`;
        setPackages([]);
        setLoading(true);
        fetchPackages(queryParams);
    }

    const handleSearchClear = () => {
        setDateFrom(null);
        setDateTo(null);
        setSelectedCourierCompany(null);
        setSearchText("");
        setPackages([]);
        setLoading(true);
        fetchPackages();
    }

    return (
        <div>
            <div style={{ marginBottom: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 className="main-heading">Packages</h1>
                <Button sx={{ fontWeight: 'bold', color: '#1565c0' }} onClick={handleOpen} >
                    <AddOutlinedIcon />
                    Add a Package
                </Button>
            </div>
            <div className="filter-card">
                <TextField
                    sx={{ width: '100%' }}
                    id="outlined-basic"
                    label="Search Package"
                    variant="outlined"
                    defaultValue=""
                    size="small"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />

                <div className='web-filter'>
                    <FormControl fullWidth sx={{ width: '100%', marginRight: '16px' }}>
                        <InputLabel id="demo-simple-select-label" sx={{ top: "-6px" }}>Filter By Delivery Company</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedCourierCompany}
                            label="Filter By Delivery Company"
                            onChange={handleCourierCompanySelect}
                            size="small"
                        >
                            {
                                courierCompaniesList && courierCompaniesList.map(elm => (
                                    <MenuItem key={elm._id} value={elm}>{elm.companyName}</MenuItem>

                                ))
                            }
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker value={dateFrom} onChange={(e) => setDateFrom(e)} label="Date From" className='date-picker' sx={{ width: '100%', marginRight: '16px', height: "0.4375em" }} />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker value={dateTo} minDate={dateFrom} disabled={!dateFrom} onChange={(e) => setDateTo(e)} label="Date To" className='date-picker' sx={{ width: '100%', marginRight: '16px', height: "0.4375em" }} />
                    </LocalizationProvider>
                    <Button variant="outlined" disableElevation sx={{ width: '215px', color: '#1565c0', marginRight: '16px' }} onClick={handleSearchClear}>
                        Clear
                    </Button>
                    <Button variant="contained" disableElevation sx={{ width: '215px', backgroundColor: '#1565c0' }} onClick={handleSearch} >
                        Search
                    </Button>
                </div>

                <Accordion className='mobile-filter'>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Filters</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl fullWidth sx={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Filter By Delivery Company</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedCourierCompany}
                                label="Filter By Delivery Company"
                                onChange={handleCourierCompanySelect}
                                size="small"
                            >
                                {
                                    courierCompaniesList && courierCompaniesList.map(elm => (
                                        <MenuItem key={elm._id} value={elm}>{elm.companyName}</MenuItem>

                                    ))
                                }
                            </Select>
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker value={dateFrom} onChange={(e) => setDateFrom(e)} label="Date From" className='date-picker' sx={{ width: '100%', marginTop: '16px', height: "0.4375em" }} />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker value={dateTo} minDate={dateFrom} disabled={true} onChange={(e) => setDateTo(e)} label="Date To" className='date-picker' sx={{ width: '100%', marginTop: '48px', height: "0.4375em" }} />
                        </LocalizationProvider>
                        <Button variant="outlined" disableElevation sx={{ width: '100%', color: '#1565c0', marginTop: '48px' }} onClick={handleSearchClear}>
                            Clear
                        </Button>
                        <Button variant="contained" disableElevation sx={{ width: '100%', backgroundColor: '#1565c0', marginTop: '16px' }} onClick={handleSearch} >
                            Search
                        </Button>
                    </AccordionDetails>
                </Accordion>

            </div>

            <div className="card-container" style={{ marginTop: '32px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button disabled={packages?.length === 0} variant="outlined" disableElevation sx={{ width: '200px', borderColor: '#1565c0', color: "#1565c0" }} onClick={() => convertPackagesToCSV(packages)} >
                        <DownloadOutlinedIcon sx={{ width: '20px', marginRight: '4px' }} />
                        Export
                    </Button>
                </div>
                {
                    loading ?
                        (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>)
                        :
                        <>
                            {
                                packages?.length === 0 ?
                                    <Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                        <h1 style={{ color: "#0e284d" }}>No Packages Found!</h1>
                                    </Box>
                                    :
                                    null
                            }
                        </>
                }
                {
                    packages &&
                    packages.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
                    .map((elm, ind) => (
                        <div key={ind} className="row row-hover" onClick={()=>navigate(`/package-details/${elm.packageId}`)}>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Package No.{
                                    (elm.handlingNote) &&
                                    <Tooltip disableFocusListener disableTouchListener title={elm.handlingNote} placement='right' >
                                        <InfoOutlinedIcon sx={{ width: '16px', marginLeft: '6px', color: 'red', cursor: 'pointer' }} />
                                    </Tooltip>
                                }</div>
                                <div className='odr-de-value'>{ind+1}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Package ID</div>
                                <div className='odr-de-value'>{elm.packageId}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Date Received</div>
                                <div className='odr-de-value'>{new Date(elm.dateReceived)?.toLocaleDateString("en-US")}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Instore (days)</div>
                                <div className='odr-de-value'>{calculateInStoreDays(elm?.dateReceived)}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Customer ID</div>
                                <div className='odr-de-value'>{elm.customer?.userId}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Customer Name</div>
                                <div className='odr-de-value'>{`${elm.customer?.firstName} ${elm.customer?.lastName}`}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Delivery Company</div>
                                <div className='odr-de-value'>{elm.courierCompany?.companyName}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Package Size</div>
                                <div className='odr-de-value'>{`${elm.weight}kg ${elm.depth} x ${elm.width} x ${elm.height} cm`}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Package Content</div>
                                <div className='odr-de-value'>
                                    {
                                        elm?.packageItems?.length ?
                                            <>
                                                {
                                                    elm?.packageItems?.length > 1 ?
                                                    <div style={{display:"flex", alignItems:"center"}}>
                                                        <div className='text-wrap' style={{ maxWidth: "124px", marginRight: "6px" }}>{elm?.packageItems[0]?.item?.itemDescription}</div>
                                                        <Chip label={`+${elm?.packageItems?.length-1}`} size="small" />
                                                    </div>
                                        :
                                        <div className='text-wrap' style={{ maxWidth: "172px" }}>{elm?.packageItems[0]?.item?.itemDescription}</div>
                                                }
                                            </>
                                            :
                                            "-"
                                    }
                                </div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Sender</div>
                                <div className='odr-de-value'>{elm.sender?.senderName}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Status</div>
                                <div className='odr-de-value'>{elm.status}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Is Consolidated</div>
                                <div className='odr-de-value'>{elm.isConsolidated ? "Yes" : "No"}</div>
                            </span>
                        </div>
                    ))
                }
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "32px" }}>
                    <Pagination page={page} count={Math.ceil(packages?.length / ITEMS_PER_PAGE)} shape="rounded" onChange={(e, page) => { e.preventDefault(); setPage(page) }} />
                </div>
            </div>

            <CreatePackage
                open={open}
                handleClose={handleClose}
                fetchPackages={fetchPackages}
            />

        </div>
    );
}

export default Packages;