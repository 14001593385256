import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getOrderByNo } from "../../apis";
import { Box, CircularProgress, Divider, Tooltip } from "@mui/material";
import "../../theme.styles.css";
import "../Packages/Packages.styles.css";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const OrderDetails = () => {
    const { id } = useParams();
    const [packageDetails, setPackage] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchPackages();
        // eslint-disable-next-line
    }, [])

    const fetchPackages = () => {
            getOrderByNo(id)
            .then((res) => {
                if (res.status === 200) {
                    setPackage(res.data?.order)
                    setLoading(false);
                }
                else setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }

    const calculateInStoreDays = (receivedDate) => {
        let numberOfDays = 0;
        if (receivedDate) {
            const diff = Math.abs(new Date() - new Date(receivedDate));
            numberOfDays = Math.ceil(diff / (1000 * 3600 * 24))
        }
        return numberOfDays;
    }

    return (
        <div>
            {
                loading ?
                    (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                        <CircularProgress />
                    </Box>)
                    :
                    <div className="card-container">
                        <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                            <h3 className='sub-heading'  > <span onClick={() => navigate("/processing")} style={{ textDecoration: "underline", cursor: "pointer" }}>Order Details</span> &#10095; {id}</h3>
                        </div>

                        <div style={{ display: 'flex', justifyContent: "space-between", marginTop: '14px' }}>
                            <div style={{ width: "50%" }} >
                                <div className="pkg-dtl-item">
                                    <label>OrderNo No.:</label>
                                    <div>{packageDetails?.orderNo}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Date Received:</label>
                                    <div>{new Date(packageDetails?.updatedAt)?.toLocaleDateString("en-US")}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Order Type:</label>
                                    <div>{packageDetails?.orderType}</div>
                                </div>
                            </div>

                            <div style={{ width: "50%" }}>
                                <div className="pkg-dtl-item">
                                    <label>Purpose:</label>
                                    <div>{packageDetails?.purpose || "-"}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Status:</label>
                                    <div>{packageDetails?.status}</div>
                                </div>
                                {/* <div className="pkg-dtl-item">
                                    <label>Package Size:</label>
                                    <div>{`${packageDetails?.weight}kg ${packageDetails?.depth} x ${packageDetails?.width} x ${packageDetails?.height} cm`}</div>
                                </div> */}
                            </div>
                        </div>

                        <Divider sx={{ marginTop: "32px" }} />

                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>Customer Details</h3>

                        <div style={{ display: 'flex', justifyContent: "space-between", marginTop: '14px' }}>
                            <div style={{ width: "50%" }} >
                                <div className="pkg-dtl-item">
                                    <label>Customer ID:</label>
                                    <div>{packageDetails?.customer?.userId}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Customer Name:</label>
                                    <div>{`${packageDetails?.customer?.firstName} ${packageDetails?.customer?.lastName}`}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Customer Email:</label>
                                    <div>{packageDetails?.customer?.email}</div>
                                </div>
                            </div>
                        </div>

                        <Divider sx={{ marginTop: "32px" }} />

                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>Sender Details</h3>

                        <div style={{ display: 'flex', justifyContent: "space-between", marginTop: '14px' }}>
                            <div style={{ width: "50%" }} >
                                <div className="pkg-dtl-item">
                                    <label>Sender Name:</label>
                                    <div>{`${packageDetails?.packages[0]?.sender?.senderName}`}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Address1:</label>
                                    <div>{packageDetails?.packages[0]?.sender?.address1}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Address2:</label>
                                    <div>{packageDetails?.packages[0]?.sender?.address2 || "-"}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>City:</label>
                                    <div>{packageDetails?.packages[0]?.sender?.city || "-"}</div>
                                </div>
                            </div>
                            <div style={{ width: "50%" }} >
                                <div className="pkg-dtl-item">
                                    <label>State:</label>
                                    <div>{packageDetails?.packages[0]?.sender?.state || "-"}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Country:</label>
                                    <div>{packageDetails?.packages[0]?.sender?.country || "-"}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>Zip:</label>
                                    <div>{packageDetails?.packages[0]?.sender?.zip || "-"}</div>
                                </div>
                                <div className="pkg-dtl-item">
                                    <label>VAT:</label>
                                    <div>{packageDetails?.packages[0]?.sender?.vat || "-"}</div>
                                </div>
                            </div>
                        </div>

                        <Divider sx={{ marginTop: "32px" }} />

                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>UK Delivery Company</h3>

                        <div style={{ display: 'flex', justifyContent: "space-between", marginTop: '14px' }}>
                            <div style={{ width: "50%" }} >
                                <div className="pkg-dtl-item">
                                    <label>Company Name:</label>
                                    <div>{`${packageDetails?.selectedCarrier?.carrier}`}</div>
                                </div>
                                {/* <div className="pkg-dtl-item">
                                    <label>Delivery Time:</label>
                                    <div>{packageDetails?.courierCompany?.deliveryTime}</div>
                                </div> */}
                            </div>
                        </div>

                        <Divider sx={{ marginTop: "32px" }} />

                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>Packages</h3>
                        {
                            packageDetails?.packages.map((elm, ind) => (
                                <div key={ind} className="row">
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Package No.{
                                    (elm.handlingNote) &&
                                    <Tooltip disableFocusListener disableTouchListener title={elm.handlingNote} placement='right' >
                                        <InfoOutlinedIcon sx={{ width: '16px', marginLeft: '6px', color: 'red', cursor: 'pointer' }} />
                                    </Tooltip>
                                }</div>
                                <div className='odr-de-value'>{ind+1}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Package ID</div>
                                <div className='odr-de-value'>{elm.packageId}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Date Received</div>
                                <div className='odr-de-value'>{new Date(elm.dateReceived)?.toLocaleDateString("en-US")}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Instore (days)</div>
                                <div className='odr-de-value'>{calculateInStoreDays(elm?.dateReceived)}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Customer ID</div>
                                <div className='odr-de-value'>{packageDetails.customer?.userId}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Customer Name</div>
                                <div className='odr-de-value'>{`${packageDetails.customer?.firstName} ${packageDetails.customer?.lastName}`}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Delivery Company</div>
                                <div className='odr-de-value'>{elm.courierCompany?.companyName}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Package Size</div>
                                <div className='odr-de-value'>{`${elm.weight}kg ${elm.depth} x ${elm.width} x ${elm.height} cm`}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Sender</div>
                                <div className='odr-de-value'>{elm.sender?.senderName}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Status</div>
                                <div className='odr-de-value'>{elm.status}</div>
                            </span>
                            <span className='odr-de-item'>
                                <div className='odr-de-label'>Is Consolidated</div>
                                <div className='odr-de-value'>{elm.isConsolidated ? "Yes" : "No"}</div>
                            </span>
                            <span className='odr-de-item' style={{width: "100%"}}>
                                <div className='odr-de-label'>Package Content</div>
                                <div className='odr-de-value'>
                                    <ul>
                                    {
                                        elm?.packageItems?.map(item=>(
                                            <li>
                                                {item?.description}
                                            </li>
                                        ))
                                    }

                                    </ul>
                                </div>
                            </span>
                        </div>
                            ))
                        }
                    </div>
            }
        </div>
    )
}

export default OrderDetails;