import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const userData = JSON.parse(localStorage.getItem("KToken"));

export const getAllOrders = (queryParams="") => axios.get(`${BASE_URL}/order${queryParams}`, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const checkoutConsolidation = (data) => axios.post(`${BASE_URL}/order/checkoutConsolidation`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const checkoutShipment = (data) => axios.post(`${BASE_URL}/order/checkoutShipment`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const getShippingRates = (data) => axios.post(`${BASE_URL}/order/rates`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const markOrderPaid = (data) => axios.post(`${BASE_URL}/order/payOrder`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const getOrderByNo = (orderNo) => axios.get(`${BASE_URL}/order/find?orderNo=${orderNo}`, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const printOrderLabel = (data) => axios.post(`${BASE_URL}/order/printLabel`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const claimOrder = (data) => axios.post(`${BASE_URL}/order/claim`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const markOrderComplete = (data) => axios.post(`${BASE_URL}/order/complete`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const cancelOrder = (data) => axios.post(`${BASE_URL}/order/cancel`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const refundOrder = (data) => axios.put(`${BASE_URL}/order/cancelAndRefund`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const manualShippingOrder = (data) => axios.put(`${BASE_URL}/order/manualShipment`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const orderCheckout = (data) => axios.post(`${BASE_URL}/order/checkoutOrder`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const exportRoyalMailHistory = (queryParams="") => axios.get(`${BASE_URL}/order/export${queryParams}`, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});