import React, { useEffect } from 'react';
import { Button, TextField, Divider, Tabs, Tab, Pagination, CircularProgress, Box, Checkbox, Modal } from "@mui/material";
import "../../theme.styles.css";
import "../Packages/Packages.styles.css";
import { uploadUserCsv, uploadExportCodesCsv, uploadSendersCsv, getAllExportCodes, getAllCourierCompanies, getAllSenders, deleteCourierCompanies, deleteExportCodes, deleteSenders, updateExportCode, getAllUsers, getUkAllDeliveryCompanies, deleteUkDeliveryCompanies, uploadUkDeliveryCompaniesCsv, uploadCourierCompaniesCsv, getAllOrders, exportRoyalMailHistory, uploadRoyalMailRatesCsv } from '../../apis';
import { errorToast, successToast } from '../../components/toast/Toast';
import { handleUserRole } from '../../utils/userRole';
import { USER_ROLE } from '../../constants/userRole';
import { convertExportCodesToCSV, convertSendersToCSV, convertUsersToCSV, convertShippingCompaniesToCSV, convertUKDeliveryCompaniesToCSV, convertOrdersToCSV } from '../../utils/convertToCsv';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'
import DeleteConfirmationDialog from '../../components/dialogs/DeleteConfirmation';

const ExportImport = () => {
    const userData = JSON.parse(localStorage.getItem("KToken"));
    const userRole = userData?.user?.role || userData?.admin?.role
    const [activeTab, setActiveTab] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [exportCodesList, setExportCodesList] = React.useState([]);
    const [courierCompaniesList, setCourierCompaniesList] = React.useState([]);
    const [sendersList, setSendersList] = React.useState([]);
    const [numberOfItems, setNumberOfItems] = React.useState(0);
    const [exportCodesLoading, setExportCodeLoading] = React.useState(true);
    const [courierCompaniesLoading, setCourierCompaniesLoading] = React.useState(true);
    const [sendersLoading, setSendersLoading] = React.useState(true);
    const [selectedExportCodes, setSelectedExportCodes] = React.useState([]);
    const [selectedDeliveryCompanies, setSelectedDeliveryCompanies] = React.useState([]);
    const [selectedSenders, setSelectedSenders] = React.useState([]);
    const [deleting, setDeleting] = React.useState(false);
    const [searchExportCode, setSearchExportCode] = React.useState("");
    const [exportCode, setExportCode] = React.useState(null);
    const [users, setUsers] = React.useState([]);
    const [usersLoading, setUsersLoading] = React.useState(true);
    const [ukDeliveryCompanies, setUkDeliveryCompanies] = React.useState([]);
    const [ukDeliveryCompaniesLoading, setUkDeliveryCompaniesLoading] = React.useState(true);
    const [selectedUkDeliveryCompanies, setSelectedUkDeliveryCompanies] = React.useState([]);
    const [dateFrom, setDateFrom] = React.useState(null);
    const [dateTo, setDateTo] = React.useState(null);
    const [royalMailDateFrom, setRoyalMailDateFrom] = React.useState(null);
    const [royalMaildateTo, setRoyalMailDateTo] = React.useState(null);
    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = React.useState(false);
    const [enableDeleteCta, setEnableDeletCta] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState("");
    const ITEMS_PER_PAGE = 10;

    useEffect(() => {
        if (activeTab === 0 && selectedExportCodes?.length > 0) setEnableDeletCta(true);
        else if (activeTab === 1 && selectedDeliveryCompanies?.length > 0) setEnableDeletCta(true);
        else if (activeTab === 2 && selectedSenders?.length > 0) setEnableDeletCta(true);
        else if (activeTab === 3 && selectedUkDeliveryCompanies?.length > 0) setEnableDeletCta(true);
        else setEnableDeletCta(false);
    }, [selectedDeliveryCompanies, selectedExportCodes, selectedSenders, selectedUkDeliveryCompanies, activeTab])

    useEffect(() => {
        fetchExportCodes();
        fetchCourierCompanies();
        fetchSenders();
        fetchUsers();
        fetchUKDeliveryCompanies();
    }, [])

    async function fetchUsers() {
        const data = await getAllUsers();
        if (data.status === 200) {
            setUsersLoading(false);
            setUsers(data.data?.users)
        }
        else setUsersLoading(false);
    }

    const fetchSenders = () => {
        getAllSenders()
            .then((data) => {
                if (data.status === 200) {
                    setSendersLoading(false);
                    setSendersList(data.data.sender);
                }
            })
            .catch(() => {
                setSendersLoading(false);
            })
    }

    const fetchUKDeliveryCompanies = () => {
        getUkAllDeliveryCompanies()
            .then((data) => {
                if (data.status === 200) {
                    setUkDeliveryCompaniesLoading(false);
                    setUkDeliveryCompanies(data.data.deliveryCompanies);
                }
            })
            .catch(() => {
                setUkDeliveryCompaniesLoading(false);
            })
    }

    const fetchExportCodes = () => {
        getAllExportCodes()
            .then((data) => {
                if (data.status === 200) {
                    setExportCodeLoading(false);
                    setExportCodesList(data.data.exportCodes);
                }
            })
            .catch(() => {
                setExportCodeLoading(false);
            })
    }

    const fetchCourierCompanies = () => {
        getAllCourierCompanies()
            .then((data) => {
                if (data.status === 200) {
                    setCourierCompaniesLoading(false);
                    setCourierCompaniesList(data.data.companies);
                }
            })
            .catch(() => {
                setCourierCompaniesLoading(false);
            })
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setSelectedDeliveryCompanies([]);
        setSelectedExportCodes([]);
        setSelectedSenders([]);
    };

    const handleUserCsvUpload = (e) => {
        const formData = new FormData();
        formData.append("users", e.target.files[0]);

        uploadUserCsv(formData)
            .then(() => {
                successToast("User CSV Uploaded Successfully")
            })
            .catch(() => {
                errorToast("There was an error uploading csv. Please try again later!")
            })
    }

    const handleExportCodesCsvUpload = (e) => {
        const formData = new FormData();
        formData.append("exportCodes", e.target.files[0]);

        uploadExportCodesCsv(formData)
            .then(() => {
                successToast("Export Codes CSV Uploaded Successfully")
                setExportCodeLoading(true);
                fetchExportCodes();
            })
            .catch(() => {
                errorToast("There was an error uploading csv. Please try again later!")
            })
    }

    const handleSendersCsvUpload = (e) => {
        const formData = new FormData();
        formData.append("senders", e.target.files[0]);

        uploadSendersCsv(formData)
            .then(() => {
                successToast("Senders CSV Uploaded Successfully")
                setSendersLoading(true);
                fetchSenders();
                setSelectedFile("");
            })
            .catch((err) => {
                errorToast(err?.response?.data?.error)
                setSelectedFile("");
            })
    }

    const handleShippingCompaniesUpload = (e) => {
        const formData = new FormData();
        formData.append("courierCompany", e.target.files[0]);

        uploadCourierCompaniesCsv(formData)
            .then(() => {
                successToast("Shipping Companies CSV Uploaded Successfully")
                setCourierCompaniesLoading(true);
                fetchCourierCompanies();
                setSelectedFile("");
            })
            .catch(() => {
                setSelectedFile("");
                errorToast("There was an error uploading csv. Please try again later!")
            })
    }

    const handleUkDeliveryCompaniesUpload = (e) => {
        const formData = new FormData();
        formData.append("deliveryCompany", e.target.files[0]);

        uploadUkDeliveryCompaniesCsv(formData)
            .then(() => {
                successToast("Uk Delivery Companies CSV Uploaded Successfully")
                setUkDeliveryCompaniesLoading(true);
                fetchUKDeliveryCompanies();
            })
            .catch(() => {
                errorToast("There was an error uploading csv. Please try again later!")
            })
    }

    const handleRoyalMailRatesUpload = (e) => {
        const formData = new FormData();
        formData.append("royalMail", e.target.files[0]);

        uploadRoyalMailRatesCsv(formData)
            .then(() => {
                successToast("Royal Mail Rates Uploaded Successfully")
            })
            .catch((err) => {
                errorToast(err?.response?.data?.error)
            })
    }

    useEffect(() => {
        if (activeTab === 0) setNumberOfItems(exportCodesList?.length)
        else if (activeTab === 1) setNumberOfItems(courierCompaniesList?.length)
        else if (activeTab === 2) setNumberOfItems(sendersList?.length)
        else setNumberOfItems(ukDeliveryCompanies?.length)

        setPage(1);
    }, [activeTab, exportCodesList, courierCompaniesList, sendersList, ukDeliveryCompanies])

    const handleExportCodesSelection = (codes) => {
        const copyArr = [...selectedExportCodes];
        const exist = copyArr.findIndex(elm => elm?._id === codes?._id)

        if (exist > -1) {
            copyArr.splice(exist, 1);
            setSelectedExportCodes([
                ...copyArr
            ])
        }
        else {
            setSelectedExportCodes([
                ...copyArr,
                codes
            ])
        }
    }

    const handleDeliveryCompanySelection = (company) => {
        const copyArr = [...selectedDeliveryCompanies];
        const exist = copyArr.findIndex(elm => elm?._id === company?._id)

        if (exist > -1) {
            copyArr.splice(exist, 1);
            setSelectedDeliveryCompanies([
                ...copyArr
            ])
        }
        else {
            setSelectedDeliveryCompanies([
                ...copyArr,
                company
            ])
        }
    }

    const handleUKDeliveryCompanySelection = (company) => {
        const copyArr = [...selectedUkDeliveryCompanies];
        const exist = copyArr.findIndex(elm => elm?._id === company?._id)

        if (exist > -1) {
            copyArr.splice(exist, 1);
            setSelectedUkDeliveryCompanies([
                ...copyArr
            ])
        }
        else {
            setSelectedUkDeliveryCompanies([
                ...copyArr,
                company
            ])
        }
    }

    const handleSendersSelection = (sender) => {
        const copyArr = [...selectedSenders];
        const exist = copyArr.findIndex(elm => elm?._id === sender?._id)

        if (exist > -1) {
            copyArr.splice(exist, 1);
            setSelectedSenders([
                ...copyArr
            ])
        }
        else {
            setSelectedSenders([
                ...copyArr,
                sender
            ])
        }
    }

    const handleDelete = () => {

        if (activeTab === 0) handleExportCodesDelete();
        else if (activeTab === 1) handleDeliveryCompaniesDelete();
        else if (activeTab === 2) handleSendersDelete();
        else handleUkDeliveryCompaniesDelete();
    }

    const handleExportCodesDelete = () => {
        setDeleting(true);
        const data = {
            ids: selectedExportCodes.map(elm => elm?._id)
        }
        deleteExportCodes(data)
            .then(() => {
                setDeleting(false);
                setExportCodeLoading(true);
                successToast("Export Codes Deleted Successfully");
                setSelectedSenders([])
                fetchExportCodes();
                setOpenDeleteConfirmationDialog(false)
            })
            .catch(() => {
                setDeleting(false);
                errorToast("There was an error deleting export codes. Please try again later!")
            })
    }

    const handleDeliveryCompaniesDelete = () => {
        setDeleting(true);
        const data = {
            ids: selectedDeliveryCompanies.map(elm => elm?._id)
        }
        deleteCourierCompanies(data)
            .then(() => {
                setDeleting(false);
                setCourierCompaniesLoading(true);
                successToast("Delivery Companies Deleted Successfully");
                setSelectedDeliveryCompanies([])
                fetchCourierCompanies();
                setOpenDeleteConfirmationDialog(false)
            })
            .catch(() => {
                setDeleting(false);
                errorToast("There was an error deleting delivery companies. Please try again later!")
            })
    }

    const handleSendersDelete = () => {
        setDeleting(true);
        const data = {
            ids: selectedSenders.map(elm => elm?._id)
        }
        deleteSenders(data)
            .then(() => {
                setDeleting(false);
                setSendersLoading(true);
                successToast("Senders Deleted Successfully");
                setSelectedSenders([])
                fetchSenders();
                setOpenDeleteConfirmationDialog(false)
            })
            .catch(() => {
                setDeleting(false);
                errorToast("There was an error deleting senders. Please try again later!")
            })
    }

    const handleUkDeliveryCompaniesDelete = () => {
        setDeleting(true);
        const data = {
            ids: selectedUkDeliveryCompanies.map(elm => elm?._id)
        }
        deleteUkDeliveryCompanies(data)
            .then(() => {
                setDeleting(false);
                setUkDeliveryCompaniesLoading(true);
                successToast("Uk Delivery Companies Deleted Successfully");
                setUkDeliveryCompanies([])
                fetchUKDeliveryCompanies();
                setOpenDeleteConfirmationDialog(false)
            })
            .catch(() => {
                setDeleting(false);
                errorToast("There was an error deleting Uk delivery companies. Please try again later!")
            })
    }

    const handleOrdersExport = async () => {
        getAllOrders(`?dateFrom=${dayjs(dateFrom)}&dateTo=${dayjs(dateTo)}`)
            .then((res) => {
                if (res.status === 200) convertOrdersToCSV(res.data?.orders)
            })
            .catch(() => {
                errorToast("There was an error fetching orders. Please try again later!")
            })
    }

    const handleRoyalMailExport = async () => {
        exportRoyalMailHistory(`?carrier=RoyalMail&dateFrom=${dayjs(royalMailDateFrom)}&dateTo=${dayjs(royalMaildateTo)}`)
            .then((res) => {
                if (res.status === 200) convertOrdersToCSV(res.data?.orders)
            })
            .catch(() => {
                errorToast("There was an error fetching royal mail orders. Please try again later!")
            })
    }

    return (
        <div>
            <div style={{ marginBottom: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 className="main-heading">Export/Import Settings</h1>
            </div>



            <div className="card-container">
                {
                    handleUserRole([USER_ROLE.superAdmin], userRole) &&
                    <>
                        <h3 className='sub-heading'>User CSV</h3>

                        <div style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                            <Button onChange={handleUserCsvUpload} variant="contained" component="label" disableElevation sx={{ width: '100%', marginRight: '16px' }} >
                                Import User
                                <input id='user-file-input' hidden accept=".csv" multiple type="file" value={selectedFile} />
                            </Button>
                            <Button disabled={usersLoading} onClick={() => convertUsersToCSV(users)} variant="outlined" component="label" disableElevation sx={{ width: '100%', border: '1px solid #1565c0', color: "#1565c0" }} >
                                Export User
                            </Button>
                        </div>

                        <Divider sx={{ marginTop: "32px" }} />
                    </>}

                <h3 style={{ marginTop: '32px' }} className='sub-heading'>Export Code</h3>

                <div style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                    <Button onChange={handleExportCodesCsvUpload} variant="contained" component="label" disableElevation sx={{ width: '100%', marginRight: '16px' }} >
                        Import Code
                        <input id='export-codes-file-input' hidden accept=".csv" multiple type="file" value={selectedFile} />
                    </Button>
                    <Button disabled={exportCodesLoading} onClick={() => convertExportCodesToCSV(exportCodesList)} variant="outlined" component="label" disableElevation sx={{ width: '100%', border: '1px solid #1565c0', color: "#1565c0" }} >
                        Export Code
                    </Button>
                </div>

                {handleUserRole([USER_ROLE.superAdmin], userRole) &&
                    <>
                        <Divider sx={{ marginTop: "32px" }} />

                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>Sender's Name/Company</h3>

                        <div style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                            <Button onChange={handleSendersCsvUpload} variant="contained" component="label" disableElevation sx={{ width: '100%', marginRight: '16px' }} >
                                Import Sender
                                <input id="sender-file-input" hidden accept=".csv" multiple type="file" value={selectedFile} />
                            </Button>
                            <Button disabled={sendersLoading} onClick={() => convertSendersToCSV(sendersList)} variant="outlined" component="label" disableElevation sx={{ width: '100%', border: '1px solid #1565c0', color: "#1565c0" }} >
                                Export Sender
                            </Button>
                        </div>

                        <Divider sx={{ marginTop: "32px" }} />

                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>Shipping Companies</h3>

                        <div style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                            <Button onChange={handleShippingCompaniesUpload} variant="contained" component="label" disableElevation sx={{ width: '100%', marginRight: '16px' }} >
                                Import Shipping Companies
                                <input id="shipping-file-input" hidden accept=".csv" multiple type="file" value={selectedFile} />
                            </Button>
                            <Button disabled={sendersLoading} onClick={() => convertShippingCompaniesToCSV(courierCompaniesList)} variant="outlined" component="label" disableElevation sx={{ width: '100%', border: '1px solid #1565c0', color: "#1565c0" }} >
                                Export Shipping Companies
                            </Button>
                        </div>

                        <Divider sx={{ marginTop: "32px" }} />

                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>UK Delivery Companies</h3>

                        <div style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                            <Button onChange={handleUkDeliveryCompaniesUpload} variant="contained" component="label" disableElevation sx={{ width: '100%', marginRight: '16px' }} >
                                Import Delivery Companies
                                <input id="delivery-file-input" hidden accept=".csv" multiple type="file" value={selectedFile} />
                            </Button>
                            <Button disabled={ukDeliveryCompaniesLoading} onClick={() => convertUKDeliveryCompaniesToCSV(ukDeliveryCompanies)} variant="outlined" component="label" disableElevation sx={{ width: '100%', border: '1px solid #1565c0', color: "#1565c0" }} >
                                Export Delivery Companies
                            </Button>
                        </div>

                        <Divider sx={{ marginTop: "32px" }} />

                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>Order Report</h3>

                        <div style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                            <div style={{ width: '100%', marginRight: '16px', display: 'flex', alignItems: 'center' }} >
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker value={dateFrom} onChange={(e) => setDateFrom(e)} label="Date From" className='date-picker' sx={{ width: "100%", marginRight: "16px" }} />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker value={dateTo} minDate={dateFrom} disabled={!dateFrom} onChange={(e) => setDateTo(e)} label="Date To" className='date-picker' sx={{ width: "100%" }} />
                                </LocalizationProvider>
                            </div>
                            <Button onClick={handleOrdersExport} disabled={!dateFrom || !dateTo} variant="outlined" component="label" disableElevation sx={{ width: '100%', border: '1px solid #1565c0', color: "#1565c0" }} >
                                Export Order Data
                            </Button>
                        </div>

                        <Divider sx={{ marginTop: "32px" }} />

                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>Royal Mail</h3>

                        <div style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                            <div style={{ width: '100%', marginRight: '16px', display: 'flex', alignItems: 'center' }} >
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker value={royalMailDateFrom} onChange={(e) => setRoyalMailDateFrom(e)} label="Date From" className='date-picker' sx={{ width: "100%", marginRight: "16px" }} />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker value={royalMaildateTo} minDate={royalMailDateFrom} disabled={!royalMailDateFrom} onChange={(e) => setRoyalMailDateTo(e)} label="Date To" className='date-picker' sx={{ width: "100%" }} />
                                </LocalizationProvider>
                            </div>
                            <Button onClick={handleRoyalMailExport} disabled={!royalMailDateFrom || !royalMaildateTo} variant="outlined" component="label" disableElevation sx={{ width: '100%', border: '1px solid #1565c0', color: "#1565c0" }} >
                                Export Royal Mail Data
                            </Button>
                            <Button onChange={handleRoyalMailRatesUpload} variant="contained" component="label" disableElevation sx={{ width: '100%', marginLeft: '16px' }} >
                                Import Royal Mail Data
                                <input id='royal-file-input' hidden accept=".csv" multiple type="file" value={selectedFile} />
                            </Button>
                        </div>
                    </>}
            </div>

            <div className="card-container" style={{ marginTop: '32px' }}>

                <div style={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={handleTabChange}>
                        <Tab label="Export Codes" />
                        {handleUserRole([USER_ROLE.superAdmin], userRole) &&
                            <Tab label="Shipping Companies" />
                        }
                        {handleUserRole([USER_ROLE.superAdmin], userRole) &&
                            <Tab label="Sender's Name/Company" />
                        }
                        {handleUserRole([USER_ROLE.superAdmin], userRole) &&
                            <Tab label="UK Delivery Companies" />
                        }
                    </Tabs>
                </div>

                {
                    activeTab === 0 && (
                        <div>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <TextField
                                    label="Search By Item Details"
                                    value={searchExportCode}
                                    onChange={(e) => setSearchExportCode(e.target.value)}
                                    size='small'
                                    sx={{ marginTop: "16px" }}
                                />

                            </div>
                            {
                                exportCodesLoading ?
                                    (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                        <CircularProgress />
                                    </Box>)
                                    :
                                    <>
                                        {
                                            exportCodesList?.length === 0 ?
                                                <Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                                    <h1 style={{ color: "#0e284d" }}>No Export Codes Found!</h1>
                                                </Box>
                                                :
                                                null
                                        }
                                    </>
                            }
                            {
                                exportCodesList.filter(elm => elm?.itemDescription.includes(searchExportCode))
                                    .slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
                                    .map((elm, ind) => (
                                        <div key={ind} className="row row-hover" style={{ flexWrap: "initial", justifyContent: "space-between" }} onClick={(e) => { if (e.target === e.currentTarget) setExportCode(elm) }}>
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }} onClick={(e) => { if (e.target === e.currentTarget) setExportCode(elm) }}>
                                                <span className='odr-de-item' onClick={(e) => { if (e.target === e.currentTarget) setExportCode(elm) }}>
                                                    <div className='odr-de-label' onClick={(e) => { if (e.target === e.currentTarget) setExportCode(elm) }}>Export Code</div>
                                                    <div className='odr-de-value' onClick={(e) => { if (e.target === e.currentTarget) setExportCode(elm) }}>{elm.exportCode}</div>
                                                </span>
                                                <span className='odr-de-item' onClick={(e) => { if (e.target === e.currentTarget) setExportCode(elm) }}>
                                                    <div className='odr-de-label' onClick={(e) => { if (e.target === e.currentTarget) setExportCode(elm) }}>Item Details</div>
                                                    <div className='odr-de-value text-wrap' onClick={(e) => { if (e.target === e.currentTarget) setExportCode(elm) }} style={{ maxWidth: "172px" }}>{elm.itemDescription}</div>
                                                </span>
                                                <span className='odr-de-item' onClick={(e) => { if (e.target === e.currentTarget) setExportCode(elm) }}>
                                                    <div className='odr-de-label' onClick={(e) => { if (e.target === e.currentTarget) setExportCode(elm) }}>Item Type</div>
                                                    <div className='odr-de-value' onClick={(e) => { if (e.target === e.currentTarget) setExportCode(elm) }}>{elm.itemType}</div>
                                                </span>
                                            </div>
                                            {handleUserRole([USER_ROLE.superAdmin], userRole) &&
                                                <div>
                                                    <Checkbox checked={selectedExportCodes.findIndex(item => item?._id === elm?._id) !== -1} onChange={() => handleExportCodesSelection(elm)} />
                                                </div>
                                            }
                                        </div>
                                    ))
                            }
                        </div>
                    )
                }
                {
                    activeTab === 1 && (
                        <div>
                            {
                                courierCompaniesLoading ?
                                    (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                        <CircularProgress />
                                    </Box>)
                                    :
                                    <>
                                        {
                                            courierCompaniesList?.length === 0 ?
                                                <Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                                    <h1 style={{ color: "#0e284d" }}>No Shipping Companies Found!</h1>
                                                </Box>
                                                :
                                                null
                                        }
                                    </>
                            }
                            {
                                courierCompaniesList.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
                                    .map((elm, ind) => (
                                        <div key={ind} className="row" style={{ flexWrap: "initial", justifyContent: "space-between" }}>
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Shipping Company</div>
                                                    <div className='odr-de-value'>{elm.service}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Service</div>
                                                    <div className='odr-de-value'>{elm.service}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Delivery Time</div>
                                                    <div className='odr-de-value'>{elm.deliveryTime}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Tracked</div>
                                                    <div className='odr-de-value'>{elm.tracked ? "Tracked" : "Not Tracked"}</div>
                                                </span>
                                            </div>
                                            <div>
                                                <Checkbox checked={selectedDeliveryCompanies.findIndex(item => item?._id === elm?._id) !== -1} onChange={() => handleDeliveryCompanySelection(elm)} />
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    )
                }
                {
                    activeTab === 2 && (
                        <div>
                            {
                                sendersLoading ?
                                    (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                        <CircularProgress />
                                    </Box>)
                                    :
                                    <>
                                        {
                                            sendersList?.length === 0 ?
                                                <Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                                    <h1 style={{ color: "#0e284d" }}>No Sender Found!</h1>
                                                </Box>
                                                :
                                                null
                                        }
                                    </>
                            }
                            {
                                sendersList.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
                                    .map((elm, ind) => (
                                        <div key={ind} className="row" style={{ flexWrap: "initial", justifyContent: "space-between" }}>
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Name</div>
                                                    <div className='odr-de-value'>{elm.senderName}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Address 1</div>
                                                    <div className='odr-de-value'>{elm.address1}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Address 2 Time</div>
                                                    <div className='odr-de-value'>{elm.address1}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>City</div>
                                                    <div className='odr-de-value'>{elm.city}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Zip</div>
                                                    <div className='odr-de-value'>{elm.zip}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Country</div>
                                                    <div className='odr-de-value'>{elm.country}</div>
                                                </span>
                                            </div>
                                            <div>
                                                <Checkbox checked={selectedSenders.findIndex(item => item?._id === elm?._id) !== -1} onChange={() => handleSendersSelection(elm)} />
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    )
                }
                {
                    activeTab === 3 && (
                        <div>
                            {
                                ukDeliveryCompaniesLoading ?
                                    (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                        <CircularProgress />
                                    </Box>)
                                    :
                                    <>
                                        {
                                            ukDeliveryCompanies?.length === 0 ?
                                                <Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                                    <h1 style={{ color: "#0e284d" }}>No UK Delivery Companies Found!</h1>
                                                </Box>
                                                :
                                                null
                                        }
                                    </>
                            }
                            {
                                ukDeliveryCompanies.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
                                    .map((elm, ind) => (
                                        <div key={ind} className="row" style={{ flexWrap: "initial", justifyContent: "space-between" }}>
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Delivery Company</div>
                                                    <div className='odr-de-value'>{elm.companyName}</div>
                                                </span>
                                            </div>
                                            <div>
                                                <Checkbox checked={selectedUkDeliveryCompanies.findIndex(item => item?._id === elm?._id) !== -1} onChange={() => handleUKDeliveryCompanySelection(elm)} />
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    )
                }

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "32px" }}>
                    <Pagination page={page} count={Math.ceil(numberOfItems / ITEMS_PER_PAGE)} shape="rounded" onChange={(e, page) => { e.preventDefault(); setPage(page) }} />
                    <Button disabled={deleting || !enableDeleteCta} variant='contained' disableElevation color="error" onClick={() => setOpenDeleteConfirmationDialog(true)}>{deleting ? "Deleting..." : "Delete"}</Button>
                </div>
            </div>

            <EditExportCode
                open={!!exportCode}
                exportCodeData={exportCode}
                onClose={() => setExportCode(null)}
                onSave={() => { fetchExportCodes(); setExportCode(null) }}
            />
            <DeleteConfirmationDialog
                open={openDeleteConfirmationDialog}
                title={"Delete"}
                description={`Are you sure you want to delete selected ${activeTab === 0 ? "Export Codes" : activeTab === 1 ? "Shipping Companies" : activeTab === 2 ? "Senders" : "Uk Delivery Companies"}?`}
                primaryCta={{
                    label: "Delete",
                    onClick: () => handleDelete()
                }}
                secondaryCta={{
                    label: "Cancel",
                    onClick: () => setOpenDeleteConfirmationDialog(false)
                }}

            />
        </div>
    )
}

const EditExportCode = ({ open, onClose, onSave, exportCodeData }) => {

    const [exportCode, setExportCode] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (exportCodeData) {
            setExportCode({
                exportCode: exportCodeData?.exportCode,
                itemDescription: exportCodeData?.itemDescription,
                itemType: exportCodeData?.itemType
            })
        }
    }, [exportCodeData])

    const handleChange = (e) => {
        setExportCode(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleUpdate = () => {
        setLoading(true)
        const data = {
            _id: exportCodeData?._id,
            ...exportCode
        }
        updateExportCode(data)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    successToast("Export Code Updated Successfully")
                    onSave();
                }
            })
            .catch(() => {
                setLoading(false)
                errorToast("There was an error updating export code. Please try again later!")
            })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <div style={{ backgroundColor: "white", padding: "32px 40px", borderRadius: "8px" }}>
                <h3 style={{ color: "#0e284d", marginBottom: "32px" }}>Edit Export Code</h3>
                <TextField
                    sx={{ width: '100%', marginTop: '32px' }}
                    id="outlined-basic"
                    label="Export Code"
                    variant="outlined"
                    defaultValue=""
                    size="small"
                    name="exportCode"
                    value={exportCode?.exportCode || ""}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    sx={{ width: '100%', marginTop: '32px' }}
                    id="outlined-basic"
                    label="Item Description"
                    variant="outlined"
                    defaultValue=""
                    size="small"
                    name="itemDescription"
                    value={exportCode?.itemDescription || ""}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    sx={{ width: '100%', marginTop: '32px' }}
                    id="outlined-basic"
                    label="Item Type"
                    variant="outlined"
                    defaultValue=""
                    size="small"
                    name="itemType"
                    value={exportCode?.itemType || ""}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                />
                <div style={{ display: 'flex', marginTop: '32px', justifyContent: "flex-end" }} >
                    <Button sx={{ marginRight: "12px" }} onClick={onClose}>Cancel</Button>
                    <Button disabled={loading} variant='contained' disableElevation onClick={handleUpdate}>{loading ? "Loading..." : "Save"}</Button>
                </div>
            </div>
        </Modal>
    )
}

export default ExportImport;