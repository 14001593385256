import SideBar from "./SideBar";
import TopBar from "./TopBar";

const Layout = ({children}) => {
    return (
        <div>
            <TopBar />
            <div className="sidebar-children-cont">
                <SideBar />
                <div className="child-cont">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Layout;