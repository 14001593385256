import React, { useState, useEffect } from 'react';
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Box, CircularProgress, Chip, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import "../../theme.styles.css";
import "../AwaitingActions/AwaitingActions.styles.css";
import { getAllOrders, getAllCourierCompanies } from "../../apis";
import { useNavigate } from 'react-router';

const Processing = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [courierCompaniesList, setCourierCompaniesList] = React.useState([]);
    const [selectedCourierCompany, setSelectedCourierCompany] = React.useState(null);
    const [textSearch, setTextSearch] = React.useState("");

    useEffect(() => {
        fetchOrders();
        fetchCourierCompanies();
    }, [])

    const fetchOrders = async () => {
        const data = await getAllOrders("");
        if (data.status === 200) {
            setOrders(data.data?.orders)
            setLoading(false);
        }
        else setLoading(false);
    }

    const fetchCourierCompanies = async () => {
        const data = await getAllCourierCompanies();
        if (data.status === 200) {
            console.log(data.data);
            setCourierCompaniesList(data.data.companies);
        }
    }

    const handleCourierCompanySelect = (event) => {
        event.preventDefault();
        setSelectedCourierCompany(event.target.value)
    }

    return (
        <div>
            <div style={{ marginBottom: '32px' }}>
                <h1 className="main-heading">Processing Orders</h1>
            </div>
            <div className="filter-card">


                <div style={{ display: "flex" }}>
                    <TextField
                        sx={{ width: '70%' }}
                        id="outlined-basic"
                        label="Search by Order No."
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        onChange={(e) => setTextSearch(e.target.value)}
                        value={textSearch}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormControl fullWidth sx={{ width: '30%', marginLeft: '16px' }}>
                        <InputLabel id="demo-simple-select-label" sx={{ top: "-6px" }}>Filter By Shipping Company</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedCourierCompany}
                            label="Filter By Shipping Company"
                            onChange={handleCourierCompanySelect}
                            size="small"
                        >
                            {
                                courierCompaniesList && courierCompaniesList.map(elm => (
                                    <MenuItem key={elm._id} value={elm}>{elm.service}</MenuItem>

                                ))
                            }
                        </Select>
                    </FormControl>
                    <Button variant="contained" onClick={() => {
                        setSelectedCourierCompany(null)
                        setTextSearch("");
                    }} sx={{ marginLeft: '16px' }} disableElevation>Reset</Button>
                </div>
            </div>

            <div className="card-container" style={{ marginTop: '32px' }}>
                {
                    loading ?
                        (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>)
                        :
                        <>
                            {
                                orders?.filter(elm => (elm.status === "processing" || elm.status === "open") && (elm?.shippingPaymentStatus === "paid" || elm?.consolidationFeeStatus === "paid"))?.length === 0 ?
                                    <Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                        <h1 style={{ color: "#0e284d" }}>No Processing Orders!</h1>
                                    </Box>
                                    :
                                    null
                            }
                        </>
                }
                {
                    orders &&
                    orders.filter(elm => (elm.status === "processing" || elm.status === "open") && (elm?.shippingPaymentStatus === "paid" || elm?.consolidationFeeStatus === "paid"))
                        .filter(elm => textSearch ? elm?.orderNo.toLowerCase().includes(textSearch.toLowerCase()) : elm)
                        .filter(elm => selectedCourierCompany ? elm?.selectedCarrier?.service === selectedCourierCompany?.service : elm)
                        .map((elm, ind) => (
                            <div key={ind} className="row" style={{ flexWrap: "initial", cursor: "pointer" }} onClick={() => navigate(`/order-details/${elm?.orderNo}`)}>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Order No.</div>
                                        <div className='odr-de-value'>{elm?.orderNo}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Package ID</div>
                                        <div className='odr-de-value'>
                                            {
                                                elm?.packages?.length ?
                                                    <>
                                                        {
                                                            elm?.packages?.length > 1 ?
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <div className='text-wrap' style={{ maxWidth: "124px", marginRight: "6px" }}>{elm?.packages[0]?.packageId}</div>
                                                                    <Chip label={`+${elm?.packages?.length - 1}`} size="small" />
                                                                </div>
                                                                :
                                                                <div className='text-wrap' style={{ maxWidth: "172px" }}>{elm?.packages[0]?.packageId}</div>
                                                        }
                                                    </>
                                                    :
                                                    "-"
                                            }
                                        </div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Date Received</div>
                                        <div className='odr-de-value'>{new Date(elm?.dateReceived || elm?.createdAt)?.toLocaleDateString("en-US")}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Customer ID</div>
                                        <div className='odr-de-value'>{elm?.customer?.userId}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Customer Name</div>
                                        <div className='odr-de-value'>{`${elm?.customer?.firstName} ${elm?.customer?.lastName}`}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Shipping Company</div>
                                        <div className='odr-de-value'>{elm?.selectedCarrier?.service || "-"}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Package Content</div>
                                        <div className='odr-de-value'>
                                            {
                                                elm?.packages?.length ?

                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <div className='text-wrap' style={{ maxWidth: "124px", marginRight: "6px" }}>{elm?.packages[0]?.packageItems[0]?.description}</div>
                                                        {elm?.packages.reduce((acc, curr) => {
                                                            return acc + curr.packageItems.length}, 0) > 1 ? <Chip label={`+${elm?.packages.reduce((acc, curr) => {
                                                            return acc + curr.packageItems.length}, 0)-1}`} size="small" /> : ""}
                                                    </div>
                                                    :
                                                    "-"
                                            }
                                        </div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Sender</div>
                                        <div className='odr-de-value'>
                                            {
                                                elm?.packages?.length ? elm?.packages[0]?.sender?.senderName: "-"
                                            }
                                            </div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Order type</div>
                                        <div className='odr-de-value'>{elm?.orderType}</div>
                                    </span>
                                    <span className='odr-de-item'>
                                        <div className='odr-de-label'>Package Size</div>
                                        <div className='odr-de-value'>
                                            {elm?.packages?.length ? `${elm?.packages.reduce((acc, curr) => {
                                                            return acc + curr.weight}, 0)}kg ${elm?.packages.reduce((acc, curr) => {
                                                                return acc + curr.depth}, 0)} x ${elm?.packages.reduce((acc, curr) => {
                                                                    return acc + curr.width}, 0)} x ${elm?.packages.reduce((acc, curr) => {
                                                                        return acc + curr.height}, 0)} cm` : "-"}
                                            </div>
                                    </span>
                                </div>
                            </div>
                        ))
                }

            </div>

        </div>
    );
}

export default Processing;