import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import {
    CardElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";

const Checkout = ({
    selectedShippingOpt,
    packageFee,
    totalStorageFee,
    requireExtendedInsurance,
    totalCost,
    onAgreeTerms,
    agreeTerms,
    loading,
    handleCheckout
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [cardHolderName, setCardHolderName] = useState("");

    const handlePayment = (e) => {
        e.preventDefault();

        if (!stripe || !elements || !cardHolderName) {
            return;
        }

        stripe.createToken(elements.getElement(CardElement))
            .then((res) => {
                if (res.error) {
                    console.log(res.error);
                }
                else {
                    handleCheckout(cardHolderName, res.token?.id)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <div>
            <h4>Enter Payment Details</h4>
            <form onSubmit={handlePayment} className='checkout-form'>
                <div>
                    <TextField
                        sx={{ width: '100%', marginBottom: "16px" }}
                        id="outlined-basic"
                        label="Card Holder Name"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="name"
                        value={cardHolderName}
                        onChange={(e) => setCardHolderName(e.target.value)}
                        required
                    />
                    <div style={{ border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "4px", padding: "11px 6px" }}>
                        <CardElement options={{ hidePostalCode: true }} />
                    </div>
                </div>
                <div style={{ width: "340px" }}>
                    <div>
                        <p className='total-item' style={{ fontWeight: "normal", marginTop: "16px" }}>Shipping Cost: &#163;{selectedShippingOpt?.rate}</p>
                        {requireExtendedInsurance && <p className='total-item' style={{ fontWeight: "normal", marginTop: "16px" }}>{`Extended insurance fee: £${packageFee?.insuranceFee?.fixed}`}</p>}
                        <p className='total-item' style={{ fontWeight: "normal", marginTop: "16px" }}>{`Storage fee: £${totalStorageFee}`}</p>
                        <p className='total-item' style={{ marginTop: "16px" }}>Total Cost: <span style={{ fontSize: "22px" }}>&#163;{totalCost}</span></p>
                    </div>
                    <div style={{ marginTop: '32px', textAlign: "center" }} >
                        <FormControlLabel className="note" onChange={onAgreeTerms} checked={agreeTerms} control={<Checkbox />} label="Do you agree our terms and conditions?" />
                    </div>
                    <Button type='submit' disabled={!agreeTerms || loading || !cardHolderName} variant="contained" disableElevation sx={{ backgroundColor: '#1565c0', marginTop: "16px", width: "100%" }} onClick={handlePayment}>
                        {loading ? "Loading..." : "Next"}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default Checkout;