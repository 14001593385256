import * as React from 'react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import CalculateIcon from '@mui/icons-material/Calculate';
import InventoryIcon from '@mui/icons-material/Inventory';
import BallotIcon from '@mui/icons-material/Ballot';
import "./Layout.styles.css";
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Collapse } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { handleUserRole } from '../../utils/userRole';
import { USER_ROLE } from '../../constants/userRole';

const SideBar = () => {

  const userData = JSON.parse(localStorage.getItem("KToken"));
  const userRole = userData?.user?.role || userData?.admin?.role
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };


  return (
    <div className="sidebar-cont">
      <MenuList>
        {handleUserRole([USER_ROLE.packager, USER_ROLE.superAdmin], userRole) && <Link to={'/packages'} style={{ textDecoration: 'none' }}>
          <MenuItem className={location.pathname === "/packages" ? 'active-item' : 'sidebar-menu-item'} selected={location.pathname === "/packages"}>
            <ListItemIcon>
              <InventoryIcon />
            </ListItemIcon>
            <ListItemText>Packages</ListItemText>
          </MenuItem>
        </Link>}
        
        {handleUserRole([USER_ROLE.customer], userRole) && <Link to={'/awaiting-actions'} style={{ textDecoration: 'none' }}>
          <MenuItem className={location.pathname === "/awaiting-actions" ? 'active-item' : 'sidebar-menu-item'} selected={location.pathname === "/awaiting-actions"}>
            <ListItemIcon>
              <InventoryIcon />
            </ListItemIcon>
            <ListItemText>Awiting Actions</ListItemText>
          </MenuItem>
        </Link>}
          {handleUserRole([USER_ROLE.customer], userRole) &&<Link to={'/ship-consolidate'} style={{ textDecoration: 'none' }}>
            <MenuItem className={location.pathname === "/ship-consolidate" ? 'active-item' : 'sidebar-menu-item'} selected={location.pathname === "/ship-consolidate"}>
              <ListItemIcon>
                <BallotIcon />
              </ListItemIcon>
              <ListItemText>Ship/Consolidate</ListItemText>
            </MenuItem>
          </Link>}
          {handleUserRole([USER_ROLE.customer], userRole) && <Link to={'/processing'} style={{ textDecoration: 'none' }}>
            <MenuItem className={location.pathname === "/processing" ? 'active-item' : 'sidebar-menu-item'} selected={location.pathname === "/processing"}>
              <ListItemIcon>
                <BallotIcon />
              </ListItemIcon>
              <ListItemText>Processing</ListItemText>
            </MenuItem>
          </Link>}
          {handleUserRole([USER_ROLE.customer], userRole) && <Link to={'/completed'} style={{ textDecoration: 'none' }}>
            <MenuItem className={location.pathname === "/completed" ? 'active-item' : 'sidebar-menu-item'} selected={location.pathname === "/completed"}>
              <ListItemIcon>
                <BallotIcon />
              </ListItemIcon>
              <ListItemText>Completed</ListItemText>
            </MenuItem>
          </Link>}
        {handleUserRole([USER_ROLE.packager, USER_ROLE.superAdmin], userRole) && <Link to={'/orders'} style={{ textDecoration: 'none' }}>
          <MenuItem className={location.pathname === "/orders" ? 'active-item' : 'sidebar-menu-item'} selected={location.pathname === "/orders"}>
            <ListItemIcon>
              <BallotIcon />
            </ListItemIcon>
            <ListItemText>Orders</ListItemText>
          </MenuItem>
        </Link>}
        {handleUserRole([USER_ROLE.packager, USER_ROLE.superAdmin], userRole) &&
          <MenuItem className='sidebar-menu-item' onClick={handleClick}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
            {open ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
          </MenuItem>
        }
        {handleUserRole([USER_ROLE.packager, USER_ROLE.superAdmin], userRole) && <Collapse in={open} timeout="auto" unmountOnExit>
          <MenuList>
            {handleUserRole([USER_ROLE.superAdmin], userRole) &&
              <Link to={'/settings-users'} style={{ textDecoration: 'none' }}>
                <MenuItem className={location.pathname === "/settings-users" ? 'active-sub-item' : 'sidebar-sub-menu-item'} selected={location.pathname === "/settings-users"}>
                  <ListItemText>Users</ListItemText>
                </MenuItem>
              </Link>}
            {handleUserRole([USER_ROLE.superAdmin], userRole) && <Link to={'/settings-shipping'} style={{ textDecoration: 'none' }}>
              <MenuItem className={location.pathname === "/settings-shipping" ? 'active-sub-item' : 'sidebar-sub-menu-item'} selected={location.pathname === "/settings-shipping"}>
                <ListItemText>Shipping</ListItemText>
              </MenuItem>
            </Link>
            }
            <Link to={'/settings-export-import'} style={{ textDecoration: 'none' }}>
              <MenuItem className={location.pathname === "/settings-export-import" ? 'active-sub-item' : 'sidebar-sub-menu-item'} selected={location.pathname === "/settings-export-import"}>
                <ListItemText>Export/Import</ListItemText>
              </MenuItem>
            </Link>
            {handleUserRole([USER_ROLE.superAdmin], userRole) &&
              <Link to={'/settings-package'} style={{ textDecoration: 'none' }}>
                <MenuItem className={location.pathname === "/settings-package" ? 'active-sub-item' : 'sidebar-sub-menu-item'} selected={location.pathname === "/settings-package"}>
                  <ListItemText>Package</ListItemText>
                </MenuItem>
              </Link>}
            {handleUserRole([USER_ROLE.superAdmin], userRole) && <Link to={'/settings-fees'} style={{ textDecoration: 'none' }}>
              <MenuItem className={location.pathname === "/settings-fees" ? 'active-sub-item' : 'sidebar-sub-menu-item'} selected={location.pathname === "/settings-fees"}>
                <ListItemText>Fees</ListItemText>
              </MenuItem>
            </Link>}
            {handleUserRole([USER_ROLE.superAdmin], userRole) &&
              <Link to={'/settings-site'} style={{ textDecoration: 'none' }}>
                <MenuItem className={location.pathname === "/settings-site" ? 'active-sub-item' : 'sidebar-sub-menu-item'} selected={location.pathname === "/settings-site"}>
                  <ListItemText>Site</ListItemText>
                </MenuItem>
              </Link>}
          </MenuList>
        </Collapse>}
        <Link to={'/shipping-calculator'} style={{ textDecoration: 'none' }}>
          <MenuItem className={location.pathname === "/shipping-calculator" ? 'active-item' : 'sidebar-menu-item'} selected={location.pathname === "/shipping-calculator"}>
            <ListItemIcon>
              <CalculateIcon />
            </ListItemIcon>
            <ListItemText>Shipping Calculator</ListItemText>
          </MenuItem>
        </Link>
        {handleUserRole([USER_ROLE.customer], userRole) && <Link to={'/help'} style={{ textDecoration: 'none' }}>
          <MenuItem className={location.pathname === "/help" ? 'active-item' : 'sidebar-menu-item'} selected={location.pathname === "/help"}>
            <ListItemIcon>
              <BallotIcon />
            </ListItemIcon>
            <ListItemText>Help</ListItemText>
          </MenuItem>
        </Link>}
      </MenuList>
    </div>
  );
}

export default SideBar;