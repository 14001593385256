import React from 'react';
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Chip, CircularProgress, Box, Checkbox, Button, Modal } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import "../../theme.styles.css";
import "../Packages/Packages.styles.css";
import { getAllUsers, sendResetEmails, updateUserData, deleteUsers } from "../../apis";
import { errorToast, successToast } from '../../components/toast/Toast';
import DeleteConfirmationDialog from '../../components/dialogs/DeleteConfirmation';


const handleChipColors = (label) => {
    let styles = {}
    switch (label) {
        case "Yes":
            styles = { backgroundColor: "#06d6a0", color: "#fff" }
            break;
        case "No":
            styles = { backgroundColor: "#ef476f", color: "#fff" }
            break;
        default:
            break;
    }
    return styles;
}



const Users = () => {
    const [users, setUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filterByUser, setFilterByUser] = React.useState("All Users");
    const [filterByHavePackages, setFilterByHavePackages] = React.useState(false);
    const [filterByValidated, setFilterByValidated] = React.useState(false);
    const [filterByUserId, setFilterByUserId] = React.useState("");
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [emailLoading, setEmailLoading] = React.useState(false);
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const [userData, setUserData] = React.useState(null);
    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = React.useState(false);

    React.useEffect(() => {
        fetchUsers();
    }, [])

    async function fetchUsers() {
        const data = await getAllUsers();
        if (data.status === 200) {
            setLoading(false);
            setUsers(data.data?.users)
        }
        else setLoading(false);
    }

    const handleUserSelection = (e, user) => {
        const copyArr = [...selectedUsers];
        const exist = copyArr.findIndex(elm => elm?._id === user?._id)

        if (exist > -1) {
            copyArr.splice(exist, 1);
            setSelectedUsers([
                ...copyArr
            ])
        }
        else {
            setSelectedUsers([
                ...copyArr,
                user
            ])
        }
    }

    const handleSendEmail = () => {
        if (selectedUsers?.length) {
            setEmailLoading(true)
            const data = {
                userIds: selectedUsers.map(elm => elm?._id)
            }
            sendResetEmails(data)
                .then((res) => {
                    if (res.status === 200) {
                        setEmailLoading(false)
                        setSelectedUsers([])
                        successToast("Emails Sent Successfully")
                    }
                })
                .catch(() => {
                    setEmailLoading(false)
                    errorToast("There was an error sending emails. Please try again later!")
                })
        }
    }

    const handleDeleteUsers = () => {
        if (selectedUsers?.length) {
            setDeleteLoading(true)
            const data = {
                _ids: selectedUsers.map(elm => elm?._id)
            }
            deleteUsers(data)
                .then((res) => {
                    if (res.status === 200) {
                        setDeleteLoading(false)
                        setSelectedUsers([])
                        fetchUsers();
                        successToast("User deleted Successfully")
                        setOpenDeleteConfirmationDialog(false)
                    }
                })
                .catch((err) => {
                    setDeleteLoading(false)
                    errorToast(err?.response?.data?.error)
                })
        }
    }

    return (
        <div>
            <div style={{ marginBottom: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 className="main-heading">User Settings</h1>
            </div>

            <div className="card-container">
                <TextField
                    sx={{ width: '100%' }}
                    id="outlined-basic"
                    label="Search By User ID"
                    variant="outlined"
                    defaultValue=""
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setFilterByUserId(e.target.value)}
                    value={filterByUserId}
                />

                <div style={{ display: 'flex', marginTop: '32px' }} >
                    <FormControl fullWidth sx={{ width: '100%', marginRight: '16px' }}>
                        <InputLabel id="demo-simple-select-label">Filter By User Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filterByUser}
                            label="Filter By User Type"
                            onChange={(e) => setFilterByUser(e.target.value)}
                            size="small"
                        >
                            <MenuItem value={"All Users"}>All Users</MenuItem>
                            <MenuItem value={"Packager"}>Packager</MenuItem>
                            <MenuItem value={"Customer"}>Customer</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ width: '100%', marginRight: '16px' }}>
                        <InputLabel id="demo-simple-select-label">Filter By Have Packages</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filterByHavePackages}
                            label="Filter By Have Packages"
                            onChange={(e) => setFilterByHavePackages(e.target.value)}
                            size="small"
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-label">Filter By Validated</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filterByValidated}
                            label="Filter By Validated"
                            onChange={(e) => setFilterByValidated(e.target.value)}
                            size="small"
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>

            <div className="card-container" style={{ marginTop: '32px' }}>
                {selectedUsers?.length ? <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <Button disabled={!selectedUsers?.length || deleteLoading} variant="contained" disableElevation color="error" onClick={()=>setOpenDeleteConfirmationDialog(true)}>
                        Delete
                    </Button>
                </div> : null}
                {
                    loading ?
                        (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>)
                        :
                        <>
                            {
                                users?.length === 0 ?
                                    <Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                        <h1 style={{ color: "#0e284d" }}>No User Found!</h1>
                                    </Box>
                                    :
                                    null
                            }
                        </>
                }
                {
                    users &&
                    users
                        .filter(elm => filterByUser === "All Users" ? elm : elm.role === filterByUser)
                        .filter(elm => elm.havePackages === filterByHavePackages)
                        .filter(elm => elm.validated === filterByValidated)
                        .filter(elm => elm?.userId.includes(filterByUserId))
                        .map((elm, ind) => (
                            <div key={ind} className="row row-hover" style={{ flexWrap: "initial" }} onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }} onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>
                                    <span className='odr-de-item'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>
                                        <div className='odr-de-label'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>User ID</div>
                                        <div className='odr-de-value'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>{elm.userId}</div>
                                    </span>
                                    <span className='odr-de-item'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>
                                        <div className='odr-de-label'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>User Name</div>
                                        <div className='odr-de-value'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>{`${elm.firstName} ${elm.lastName}`}</div>
                                    </span>
                                    <span className='odr-de-item'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>
                                        <div className='odr-de-label'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>User Email</div>
                                        <div className='odr-de-value'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>{elm.email}</div>
                                    </span>
                                    <span className='odr-de-item'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>
                                        <div className='odr-de-label'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>User Type</div>
                                        <div className='odr-de-value'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>
                                            {
                                                elm.role === "Super Admin" ?
                                                    <Chip sx={{ backgroundColor: "#118ab2", color: "#fff" }} label={elm.role} size="small" />
                                                    :
                                                    elm.role
                                            }
                                        </div>
                                    </span>
                                    <span className='odr-de-item'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>
                                        <div className='odr-de-label'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>Have Packages</div>
                                        <div className='odr-de-value'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>{elm.havePackages ? "Yes" : "No"}</div>
                                    </span>
                                    <span className='odr-de-item'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>
                                        <div className='odr-de-label'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>Last Login</div>
                                        <div className='odr-de-value'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>{elm.lastLogin ? new Date(elm.lastLogin)?.toLocaleDateString("en-US") : "-"}</div>
                                    </span>
                                    <span className='odr-de-item'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>
                                        <div className='odr-de-label'  onClick={(e) => { if (e.target === e.currentTarget) setUserData(elm) }}>Validated</div>
                                        <Chip sx={handleChipColors(elm.validated ? "Yes" : "No")} label={elm.validated ? "Yes" : "No"} size="small" />
                                    </span>
                                </div>

                                {!filterByValidated &&
                                    <div>
                                        <Checkbox checked={selectedUsers.findIndex(item => item?._id === elm?._id) !== -1} onChange={(e) => handleUserSelection(e, elm)} />
                                    </div>
                                }
                            </div>
                        ))
                }
            </div>

            {!filterByValidated && <div className="card-container" style={{ marginTop: '32px' }}>
                <p className='note'><span style={{ fontWeight: "bolder" }}>Send Password Reset Email</span></p>
                <div style={{ display: 'flex', marginTop: '32px' }} >
                    <Button disabled={!selectedUsers?.length || emailLoading} variant="contained" disableElevation sx={{ width: '300px', backgroundColor: '#1565c0', border: "2px solid", marginRight: "36px", fontWeight: 600 }} onClick={handleSendEmail}>
                        Send Email
                    </Button>
                </div>
            </div>}

            <EditUser
                open={!!userData}
                userData={userData}
                onClose={() => setUserData(null)}
                onSave={() => { fetchUsers(); setUserData(null) }}
            />
            <DeleteConfirmationDialog
                open={openDeleteConfirmationDialog}
                title={"Delete"}
                description={"Are you sure you want to delete selected users?"}
                primaryCta={{
                    label: "Delete",
                    onClick: ()=>handleDeleteUsers()
                }}
                secondaryCta={{
                    label: "Cancel",
                    onClick: ()=>setOpenDeleteConfirmationDialog(false)
                }}

            />
        </div>
    )
}

const EditUser = ({ open, onClose, onSave, userData }) => {

    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (userData) {
            setUser({
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                email: userData?.email,
                userId: userData?.userId,
                role: userData?.role,
                businessName: userData?.businessName,
                taxId: userData?.taxId,
                address1: userData?.address1,
                address2: userData?.address2,
                city: userData?.city,
                zip: userData?.zip,
                state: userData?.state,
                country: userData?.country,
                contactNo: userData?.contactNo,
            })
        }
    }, [userData])

    const handleChange = (e) => {
        setUser(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleUpdate = () => {
        setLoading(true)
        const data = {
            _id: userData?._id,
            ...user
        }
        updateUserData(data)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    successToast("User Updated Successfully")
                    onSave();
                }
            })
            .catch(() => {
                setLoading(false)
                errorToast("There was an error updating user. Please try again later!")
            })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <div style={{ backgroundColor: "white", padding: "32px 40px", borderRadius: "8px", width: "532px" }}>
                <h3 style={{ color: "#0e284d", marginBottom: "32px" }}>Edit User</h3>
                <div style={{ display: 'flex' }} >
                    <TextField
                        sx={{ width: '100%', marginRight: '16px' }}
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="firstName"
                        value={user?.firstName || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="lastName"
                        value={user?.lastName || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
                <div style={{ display: 'flex', marginTop: '32px' }} >
                    <TextField
                        sx={{ width: '100%', marginRight: '16px' }}
                        id="outlined-basic"
                        label="User ID"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="userId"
                        value={user?.userId || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="email"
                        value={user?.email || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
                <div style={{ display: 'flex', marginTop: '32px' }} >
                    <TextField
                        sx={{ width: '100%', marginRight: '16px' }}
                        id="outlined-basic"
                        label="Role"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="role"
                        value={user?.role || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="Business Name"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="businessName"
                        value={user?.businessName || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
                <div style={{ display: 'flex', marginTop: '32px' }} >
                    <TextField
                        sx={{ width: '100%', marginRight: '16px' }}
                        id="outlined-basic"
                        label="Address 1"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="address1"
                        value={user?.address1 || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="Address 2"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="address2"
                        value={user?.address2 || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
                <div style={{ display: 'flex', marginTop: '32px' }} >
                    <TextField
                        sx={{ width: '100%', marginRight: '16px' }}
                        id="outlined-basic"
                        label="Country"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="country"
                        value={user?.country || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="State"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="state"
                        value={user?.state || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
                <div style={{ display: 'flex', marginTop: '32px' }} >
                    <TextField
                        sx={{ width: '100%', marginRight: '16px' }}
                        id="outlined-basic"
                        label="City"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="city"
                        value={user?.city || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="Zip"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="zip"
                        value={user?.zip || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
                <div style={{ display: 'flex', marginTop: '32px' }} >
                    <TextField
                        sx={{ width: '100%', marginRight: '16px' }}
                        id="outlined-basic"
                        label="Tax Id"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="taxId"
                        value={user?.taxId || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="Contact No"
                        variant="outlined"
                        defaultValue=""
                        size="small"
                        name="contactNo"
                        value={user?.contactNo || ""}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
                <div style={{ display: 'flex', marginTop: '32px', justifyContent: "flex-end" }} >
                    <Button sx={{ marginRight: "12px" }} onClick={onClose}>Cancel</Button>
                    <Button disabled={loading} variant='contained' disableElevation onClick={handleUpdate}>{loading ? "Loading..." : "Save"}</Button>
                </div>
            </div>
        </Modal>
    )
}

export default Users;