import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { useNavigate, useParams } from 'react-router';
import "./ConfirmPayment.styles.css";
import { useEffect } from 'react';
import { markOrderPaid } from "../../apis";

const ConfirmPayment = () => {
    const { orderNo, status } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(status === "success=true") {
            const data = {
                orderNo
            }
            markOrderPaid(data)
            .then((res)=>{
                if(res?.status === 200) {
                        navigate("/processing")
                }
            })
            .catch((err)=>{
                setTimeout(() => {
                    navigate("/ship-consolidate")
                }, 2000)
            })
        }
        else {
            setTimeout(() => {
                navigate("/ship-consolidate")
            }, 2000)
        }
    }, [orderNo, status, navigate])

    return (
        <div className={`container ${status === "success=true" ? "successfull" : "error"}`}>
            {
                status === "success=true" ?
                    <TaskAltRoundedIcon />
                    :
                    <ErrorOutlineRoundedIcon />
            }
            <h1 className='payment-heading'>{status === "cancel=true" ? "Your Payment was Unsuccessfull, Redirecting..." : "Your Order is Successfull, Redirecting..."}</h1>
        </div>
    )
};

export default ConfirmPayment;