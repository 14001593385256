import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DeleteConfirmationDialog({open,title, description, primaryCta, secondaryCta}) {

  return (
      <Dialog
        open={open}
        onClose={secondaryCta.onClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={secondaryCta.onClick}>{secondaryCta?.label}</Button>
          <Button onClick={primaryCta.onClick} variant='contained' disableElevation color="error">
            {primaryCta?.label}
          </Button>
        </DialogActions>
      </Dialog>
  );
}