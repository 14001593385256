import { Button, TextField, Drawer, FormControlLabel, Checkbox, Radio, RadioGroup, Chip, FormControl, Autocomplete, Divider, Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import "../../theme.styles.css";
import "../AwaitingActions/AwaitingActions.styles.css";
import "./ShippingOrConsolidate.styles.css";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { getShippingRates, orderCheckout, checkoutShipment, markOrderPaid } from "../../apis";
import { errorToast, successToast } from "../../components/toast/Toast";
import { Country, State, City } from "country-state-city";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Checkout from "./Checkout";
import { useNavigate } from "react-router";

const steps = [
    "Shipping Address",
    "Shipping Options",
    "Checkout"
];

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Shipping = ({
    open,
    handleClose,
    packages,
    packageFee
}) => {
    const navigate = useNavigate();
    const [isBillingAddress, setIsBillingAddress] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [receiverAdd, setReceiverAdd] = useState({
        name: "",
        company: "",
        street1: "",
        street2: "",
        city: "",
        zip: "",
        state: "",
        country: "",
        email: "",
        phone: "",
        federal_tax_id: "",
    })
    const [billingAdd, setBillingAdd] = useState({
        name: "",
        company: "",
        street1: "",
        street2: "",
        city: "",
        zip: "",
        state: "",
        country: "",
        email: "",
        phone: "",
        federal_tax_id: "",
    })
    const [rateList, setRateList] = useState([]);
    const [shippingOption, setShippingOption] = useState("");
    const [exportPurpose, setExportPurpose] = useState("");
    const [requireExtendedInsurance, setRequireExtendedInsurance] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [easypostOrderId, setEasypostOrderId] = useState("");
    const [selectedShippingOpt, setSelectedShippingOpt] = useState(null);
    const [combinedPkgWeight, setCombinedPkgWeight] = useState(0);
    const [totalStorageFee, setTotalStorageFee] = useState(0);
    const [countiresList, setCountriesList] = useState([]);
    const [selectedRecCountry, setSelectedRecCountry] = useState(null);
    const [selectedBillCountry, setSelectedBillCountry] = useState(null);
    const [statesList, setStatesList] = useState([]);
    const [billStatesList, setBillStatesList] = useState([]);
    const [selectedRecState, setSelectedRecState] = useState(null);
    const [selectedBillState, setSelectedBillState] = useState(null);
    const [citiesList, setCitiesList] = useState([]);
    const [billCitiesList, setBillCitiesList] = useState([]);
    const [selectRecCity, setSelectedRecCity] = useState(null);
    const [selectBillCity, setSelectedBillCity] = useState(null);

    const calculateTotalStorageFee = useCallback(() => {
        let total = 0;
        if (packages?.length) {
            packages?.forEach(elm => {
                const numberOfDays = calculateInStoreDays(elm?.dateReceived)
                if (numberOfDays > packageFee?.freeStorageDays) {
                    const diff = numberOfDays - packageFee?.freeStorageDays;
                    total = (diff * packageFee?.storageFee) + total;
                }
            });
        }
        setTotalStorageFee(total);
    }, [packageFee, packages])

    useEffect(() => {
        if (packages?.length) {
            const total = packages.reduce((a, b) => Number(a) + Number(b.weight), 0);
            setCombinedPkgWeight(total);
            calculateTotalStorageFee();
        }
    }, [packages, calculateTotalStorageFee])

    const calculateInStoreDays = (receivedDate) => {
        let numberOfDays = 0;
        if (receivedDate) {
            const diff = Math.abs(new Date() - new Date(receivedDate));
            numberOfDays = Math.ceil(diff / (1000 * 3600 * 24))
        }
        return numberOfDays;
    }

    const handleReceiverAddChange = (e) => {
        setReceiverAdd((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleBillingAddChange = (e) => {
        setBillingAdd((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleNext = async () => {
        setLoading(true)
        const billObj = {
            ...billingAdd,
            country: selectedBillCountry?.isoCode,
            state: selectedBillState?.name || selectBillCity?.name,
            city: selectBillCity?.name || selectedBillState?.name
        }

        const recObj = {
            ...receiverAdd,
            country: selectedRecCountry?.isoCode,
            state: selectedRecState?.name || selectRecCity?.name,
            city: selectRecCity?.name || selectedRecState?.name
        }
        
        const data = {
                packages: packages.map(elm => elm?._id),
                receiver: {
                    billingAddress: isBillingAddress ? recObj : billObj,
                    receivingAddress: recObj
                }
            }
        const shippingRates = await getShippingRates(data);
            if(shippingRates?.status === 200 && shippingRates?.data?.easypostOrderId) {
                const royalMailRates = shippingRates?.data?.royalMailRates ? shippingRates?.data?.royalMailRates : []
            setEasypostOrderId(shippingRates?.data?.easypostOrderId);
        const easyPostRate = shippingRates?.data?.rates?.map(elm => ({
            ...elm,
            rate: handleRateCalculation(elm?.rate)
        }))
        const easyPostRMRate = royalMailRates?.map(elm => ({
            ...elm,
            rate: handleRateCalculation(elm?.rate)
        }))
        setRateList([...easyPostRMRate, ...easyPostRate])
        setLoading(false)
    }
        else setLoading(false)
    }

useEffect(() => {
    if (rateList?.length) {
        setActiveStep(1)
    }
}, [rateList])

const handleRateCalculation = (value) => {
    let total = 0;
    const surchargeFee = packageFee?.surchageFee?.find(elm => elm.provider === value?.carrier)
    const countryTax = packageFee?.countryTax.find(elm => elm?.countries.includes(receiverAdd?.country?.toUpperCase()))

    if (value && packageFee) {
        if (combinedPkgWeight <= packageFee?.small?.weight) {
            total = ((Number(value) + (surchargeFee?.perPackage || 0) + (combinedPkgWeight * (surchargeFee?.perKg || 1)) + packageFee?.small?.fee) * ((packageFee?.markup / 100) + 1) * ((packageFee?.cardFee / 100) + 1) * (((countryTax?.rate / 100) + 1) || 1));
        }
        else if (combinedPkgWeight <= packageFee?.medium?.weight) {
            total = ((Number(value) + (surchargeFee?.perPackage || 0) + (combinedPkgWeight * (surchargeFee?.perKg || 1)) + packageFee?.medium?.fee) * ((packageFee?.markup / 100) + 1) * ((packageFee?.cardFee / 100) + 1) * (((countryTax?.rate / 100) + 1) || 1));
        }
        else if (combinedPkgWeight <= packageFee?.large?.weight) {
            total = ((Number(value) + (surchargeFee?.perPackage || 0) + (combinedPkgWeight * (surchargeFee?.perKg || 1)) + packageFee?.large?.fee) * ((packageFee?.markup / 100) + 1) * ((packageFee?.cardFee / 100) + 1) * (((countryTax?.rate / 100) + 1) || 1));
        }
        else {
            total = ((Number(value) + (surchargeFee?.perPackage || 0) + (combinedPkgWeight * (surchargeFee?.perKg || 1)) + packageFee?.extraLarge?.fee) * ((packageFee?.markup / 100) + 1) * ((packageFee?.cardFee / 100) + 1) * (((countryTax?.rate / 100) + 1) || 1));
        }
    }

    return Number(total?.toFixed(2));
}

const handleTotalCost = React.useCallback(() => {
    let total = 0;
    total = (Number(selectedShippingOpt?.rate) || 0) + totalStorageFee;

    if (requireExtendedInsurance) total = Number(total) + Number(packageFee?.insuranceFee?.fixed);

    return Number(total);
}, [packageFee, requireExtendedInsurance, totalStorageFee, selectedShippingOpt])

useEffect(() => {
    if (isBillingAddress && receiverAdd?.name && receiverAdd?.street1 && selectedRecCountry && receiverAdd?.zip && receiverAdd?.email && receiverAdd?.phone) {
        setDisableButton(false);
    }
    else if (!isBillingAddress && receiverAdd?.name && receiverAdd?.street1 && selectedRecCountry && receiverAdd?.zip && receiverAdd?.email && receiverAdd?.phone
        && billingAdd?.name && billingAdd?.street1 && selectedBillCountry && billingAdd?.zip && billingAdd?.email && billingAdd?.phone
    ) {
        setDisableButton(false);
    }
    else setDisableButton(true);
}, [receiverAdd, isBillingAddress, billingAdd, selectedBillCountry, selectedRecCountry])

const handleCheckout = (res, token) => {

    setLoading(true);
    const billObj = {
        ...billingAdd,
        country: selectedBillCountry?.isoCode,
        state: selectedBillState?.name || selectBillCity?.name,
        city: selectBillCity?.name || selectedBillState?.name
    }

    const recObj = {
        ...receiverAdd,
        country: selectedRecCountry?.isoCode,
        state: selectedRecState?.name || selectRecCity?.name,
        city: selectRecCity?.name || selectedRecState?.name
    }

    const data = {
        easypostOrderId: easypostOrderId,
        packages: packages.map(elm => elm?._id),
        receiver: {
            billingAddress: isBillingAddress ? recObj : billObj,
            receivingAddress: recObj
        },
        purpose: exportPurpose,
        shippingFee: handleTotalCost(),
        extendedInsurance: requireExtendedInsurance,
        extendedInsuranceFee: requireExtendedInsurance ? packageFee?.insuranceFee?.fixed : 0,
        storageFee: totalStorageFee,
        selectedCarrier: {
            rate: Number(selectedShippingOpt?.rate),
            service: selectedShippingOpt?.service,
            carrier: selectedShippingOpt?.carrier,
            serviceCode: selectedShippingOpt?.serviceCode
        }
    }


    checkoutShipment(data)
        .then((chkout) => {
            if (chkout.status === 200) {
                handleStripeCheckout({
                    orderNo: chkout?.data?.orderNo,
                    source: token,
                    cardHolderName: res
                })
            }
        })
        .catch(() => {
            errorToast("There was an error checking out. Please try again later!")
            setLoading(false);
        })
}

const handleStripeCheckout = (checkoutData) => {

    const data = {
        customer: {
            name: checkoutData?.cardHolderName
        },
        source: checkoutData.source,
        amount: handleTotalCost(),
        orderNo: checkoutData.orderNo
    }

    orderCheckout(data)
        .then((chkout) => {
            if (chkout.status === 200) {
                handleMarkOrderPaid(checkoutData.orderNo)
            }
        })
        .catch(() => {
            errorToast("There was an error checking out. Please try again later!")
            setLoading(false);
        })
}

const handleMarkOrderPaid = (orderNo) => {
    const data = {
        orderNo
    }
    markOrderPaid(data)
        .then((res) => {
            if (res?.status === 200) {
                successToast("Order Paid Successfully")
                navigate("/processing")
                setLoading(false);
            }
        })
        .catch((err) => {
            setTimeout(() => {
                navigate("/ship-consolidate")
                setLoading(false);
            }, 2000)
        })
}

useEffect(() => {
    const list = Country.getAllCountries();
    if (list?.length) setCountriesList(list);
}, [])

// Set states list for receiver
useEffect(() => {
    if (selectedRecCountry) {
        const list = State.getStatesOfCountry(selectedRecCountry?.isoCode)
        if (list?.length) {
            setStatesList(list);
        }
        else {
            const cities = City.getCitiesOfCountry(selectedRecCountry?.isoCode)
            setCitiesList(cities);
        }
    }
    else {
        setStatesList([])
        setSelectedRecState(null)
    }
}, [selectedRecCountry])

// Set cities list for receiver
useEffect(() => {
    if (selectedRecState && selectedRecCountry) {
        const list = City.getCitiesOfState(selectedRecCountry?.isoCode, selectedRecState?.isoCode)
        if (list?.length) setCitiesList(list);
    }
    else {
        setCitiesList([])
        setSelectedRecCity(null)
    }
}, [selectedRecState, selectedRecCountry])

// Set states list for Biller
useEffect(() => {
    if (selectedBillCountry) {
        const list = State.getStatesOfCountry(selectedBillCountry?.isoCode)
        if (list?.length) setBillStatesList(list);
    }
    else {
        setBillStatesList([])
        setSelectedBillState(null)
    }
}, [selectedBillCountry])

// Set cities list for Biller
useEffect(() => {
    if (selectedBillState && selectedBillCountry) {
        const list = City.getCitiesOfState(selectedBillCountry?.isoCode, selectedBillState?.isoCode)
        if (list?.length) setBillCitiesList(list);
    }
    else {
        setBillCitiesList([])
        setSelectedBillCity(null)
    }
}, [selectedBillState, selectedBillCountry])


return (
    <Drawer
        anchor={"bottom"}
        open={open}
        onClose={handleClose}
        PaperProps={{
            style: {
                height: 'calc(100vh - 80px)',
                padding: '40px'
            }
        }}
    >
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '32px' }}>
                    <Typography component="h2" sx={{display: {xs:"none", md:"block"}, fontSize: "1.5rem", fontWeight: "bold"}} className='modal-heading'>Shipping</Typography>
                    <Stepper activeStep={activeStep} alternativeLabel sx={{ width: "50%" }}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <span style={{ cursor: 'pointer' }} onClick={handleClose}><CloseIcon /></span>
                </div>
                {/* <p className='note' style={{ margin: "32px 0" }}><span style={{ fontWeight: "bolder", fontSize: "18px" }}>Note:</span> Customer Note
                    </p> */}
                <div className="ship-consolidate-modal-body">
                    {
                        activeStep === 0 &&
                        <>
                            <div className='pkg-details-body' style={{ width: "100%", marginTop: 0 }}>
                                {
                                    packages &&
                                    packages.map((elm, ind) => (
                                        <div key={ind} className="row">
                                            <span className='odr-de-item'>
                                                <div className='odr-de-label'>Package No.</div>
                                                <div className='odr-de-value'>{elm.trackingNumber}</div>
                                            </span>
                                            <span className='odr-de-item'>
                                                <div className='odr-de-label'>Package ID</div>
                                                <div className='odr-de-value'>{elm.packageId}</div>
                                            </span>
                                            <span className='odr-de-item'>
                                                <div className='odr-de-label'>Date Received</div>
                                                <div className='odr-de-value'>{new Date(elm.dateReceived)?.toLocaleDateString("en-US")}</div>
                                            </span>
                                            <span className='odr-de-item'>
                                                <div className='odr-de-label'>Instore (days)</div>
                                                <div className='odr-de-value'>{calculateInStoreDays(elm?.dateReceived)}</div>
                                            </span>
                                            <span className='odr-de-item'>
                                                <div className='odr-de-label'>Customer ID</div>
                                                <div className='odr-de-value'>{elm.customer.userId}</div>
                                            </span>
                                            <span className='odr-de-item'>
                                                <div className='odr-de-label'>Customer Name</div>
                                                <div className='odr-de-value'>{`${elm.customer.firstName} ${elm.customer.lastName}`}</div>
                                            </span>
                                            <span className='odr-de-item'>
                                                <div className='odr-de-label'>Delivery Company</div>
                                                <div className='odr-de-value'>{elm.courierCompany.companyName}</div>
                                            </span>
                                            <span className='odr-de-item'>
                                                <div className='odr-de-label'>Package Size</div>
                                                <div className='odr-de-value'>{`${elm.weight}kg ${elm.depth} x ${elm.width} x ${elm.height} cm`}</div>
                                            </span>
                                            <span className='odr-de-item'>
                                                <div className='odr-de-label'>Package Content</div>
                                                <div className='odr-de-value'>
                                                    {
                                                        elm?.packageItems?.length ?
                                                            <>
                                                                {
                                                                    elm?.packageItems?.length > 1 ?
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <div className='text-wrap' style={{ maxWidth: "124px", marginRight: "6px" }}>{elm?.packageItems[0]?.item?.itemDescription}</div>
                                                                            <Chip label={`+${elm?.packageItems?.length - 1}`} size="small" />
                                                                        </div>
                                                                        :
                                                                        <div className='text-wrap' style={{ maxWidth: "172px" }}>{elm?.packageItems[0]?.item?.itemDescription}</div>
                                                                }
                                                            </>
                                                            :
                                                            "-"
                                                    }
                                                </div>
                                            </span>
                                            <span className='odr-de-item'>
                                                <div className='odr-de-label'>Sender</div>
                                                <div className='odr-de-value'>{elm.sender?.senderName}</div>
                                            </span>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="right-sec" style={{ marginTop: 0 }}>
                                <div>
                                    <div className='total-item'>
                                        No. of packages to be shipped: {packages?.length}
                                    </div>
                                    <div style={{ marginTop: "32px" }}>
                                        <div style={{ display: 'flex', marginTop: '16px' }} >
                                            <TextField
                                                sx={{ width: '100%' }}
                                                id="outlined-basic"
                                                label="Receiver's Name*"
                                                variant="outlined"
                                                defaultValue=""
                                                size="small"
                                                name="name"
                                                value={receiverAdd?.name}
                                                onChange={handleReceiverAddChange}
                                            />
                                            <TextField
                                                sx={{ width: '100%', marginLeft: "8px" }}
                                                id="outlined-basic"
                                                label="Receiver's Company"
                                                variant="outlined"
                                                defaultValue=""
                                                size="small"
                                                name="company"
                                                value={receiverAdd?.company}
                                                onChange={handleReceiverAddChange}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', marginTop: '16px' }} >
                                            <TextField
                                                sx={{ width: '100%' }}
                                                id="outlined-basic"
                                                label="Receiver's Tax ID"
                                                variant="outlined"
                                                defaultValue=""
                                                size="small"
                                                name="federal_tax_id"
                                                value={receiverAdd?.federal_tax_id}
                                                onChange={handleReceiverAddChange}
                                            />
                                            <TextField
                                                sx={{ width: '100%', marginLeft: "8px" }}
                                                id="outlined-basic"
                                                label="Receiver's Address 1*"
                                                variant="outlined"
                                                defaultValue=""
                                                size="small"
                                                name="street1"
                                                value={receiverAdd?.street1}
                                                onChange={handleReceiverAddChange}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', marginTop: '16px' }} >
                                            <TextField
                                                sx={{ width: '100%' }}
                                                id="outlined-basic"
                                                label="Receiver's Address 2"
                                                variant="outlined"
                                                defaultValue=""
                                                size="small"
                                                name="street2"
                                                value={receiverAdd?.street2}
                                                onChange={handleReceiverAddChange}
                                            />
                                            <TextField
                                                sx={{ width: '100%', marginLeft: "8px" }}
                                                id="outlined-basic"
                                                label="Receiver's Email*"
                                                variant="outlined"
                                                defaultValue=""
                                                size="small"
                                                name="email"
                                                value={receiverAdd?.email}
                                                onChange={handleReceiverAddChange}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', marginTop: '16px' }} >
                                            <FormControl fullWidth sx={{ width: '100%' }}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={countiresList?.map(elm => ({
                                                        label: elm.name,
                                                        value: elm
                                                    }))}
                                                    name="country"
                                                    value={selectedRecCountry?.name}
                                                    renderInput={(params) => <TextField {...params} label="Receiver's Country*" />}
                                                    onChange={(e, val) => {
                                                        if (!val) setSelectedRecCountry(null)
                                                        else setSelectedRecCountry(val.value)
                                                    }}
                                                    size="small"
                                                />
                                            </FormControl>
                                            <FormControl fullWidth sx={{ width: '100%', marginLeft: "8px" }}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={selectedRecCountry ? statesList?.map(elm => ({
                                                        label: elm.name,
                                                        value: elm
                                                    })) : []}
                                                    name="state"
                                                    value={selectedRecState?.name}
                                                    renderInput={(params) => <TextField {...params} label="Receiver's State" />}
                                                    onChange={(e, val) => {
                                                        if (!val) setSelectedRecState(null)
                                                        else setSelectedRecState(val.value)
                                                    }}
                                                    size="small"
                                                />
                                            </FormControl>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: '16px' }} >
                                            <FormControl fullWidth sx={{ width: '100%' }}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={(selectedRecState || selectedRecCountry) ? citiesList?.map(elm => ({
                                                        label: elm.name,
                                                        value: elm
                                                    })) : []}
                                                    name="city"
                                                    value={selectRecCity?.name}
                                                    renderInput={(params) => <TextField {...params} label="Receiver's City" />}
                                                    onChange={(e, val) => {
                                                        if (!val) setSelectedRecCity(null)
                                                        else setSelectedRecCity(val.value)
                                                    }}
                                                    size="small"
                                                />
                                            </FormControl>
                                            <TextField
                                                sx={{ width: '100%', marginLeft: "8px" }}
                                                id="outlined-basic"
                                                label="Receiver's Zip*"
                                                variant="outlined"
                                                defaultValue=""
                                                size="small"
                                                name="zip"
                                                value={receiverAdd?.zip}
                                                onChange={handleReceiverAddChange}
                                            />
                                        </div>
                                        <TextField
                                            sx={{ width: '100%', marginTop: "16px" }}
                                            id="outlined-basic"
                                            label="Receiver's Contact No.*"
                                            variant="outlined"
                                            defaultValue=""
                                            size="small"
                                            name="phone"
                                            value={receiverAdd?.phone}
                                            onChange={handleReceiverAddChange}
                                        />
                                        <FormControlLabel sx={{ marginTop: '16px' }} className="note" onChange={() => setIsBillingAddress(!isBillingAddress)} checked={isBillingAddress} control={<Checkbox checked={isBillingAddress} defaultChecked={isBillingAddress} />} label="Billing address same as shipping address?" />

                                        {!isBillingAddress &&
                                            <>
                                                <div style={{ display: 'flex', marginTop: '16px' }} >
                                                    <TextField
                                                        sx={{ width: '100%' }}
                                                        id="outlined-basic"
                                                        label="Biller's Name*"
                                                        variant="outlined"
                                                        defaultValue=""
                                                        size="small"
                                                        name="name"
                                                        value={billingAdd?.name}
                                                        onChange={handleBillingAddChange}
                                                    />
                                                    <TextField
                                                        sx={{ width: '100%', marginLeft: "8px" }}
                                                        id="outlined-basic"
                                                        label="Biller's Company"
                                                        variant="outlined"
                                                        defaultValue=""
                                                        size="small"
                                                        name="company"
                                                        value={billingAdd?.company}
                                                        onChange={handleBillingAddChange}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', marginTop: '16px' }} >
                                                    <TextField
                                                        sx={{ width: '100%' }}
                                                        id="outlined-basic"
                                                        label="Biller's Tax ID"
                                                        variant="outlined"
                                                        defaultValue=""
                                                        size="small"
                                                        name="federal_tax_id"
                                                        value={billingAdd?.federal_tax_id}
                                                        onChange={handleBillingAddChange}
                                                    />
                                                    <TextField
                                                        sx={{ width: '100%', marginLeft: "8px" }}
                                                        id="outlined-basic"
                                                        label="Biller's Address 1*"
                                                        variant="outlined"
                                                        defaultValue=""
                                                        size="small"
                                                        name="street1"
                                                        value={billingAdd?.street1}
                                                        onChange={handleBillingAddChange}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', marginTop: '16px' }} >
                                                    <TextField
                                                        sx={{ width: '100%' }}
                                                        id="outlined-basic"
                                                        label="Biller's Address 2"
                                                        variant="outlined"
                                                        defaultValue=""
                                                        size="small"
                                                        name="street2"
                                                        value={billingAdd?.street2}
                                                        onChange={handleBillingAddChange}
                                                    />
                                                    <TextField
                                                        sx={{ width: '100%', marginLeft: "8px" }}
                                                        id="outlined-basic"
                                                        label="Biller's Email*"
                                                        variant="outlined"
                                                        defaultValue=""
                                                        size="small"
                                                        name="email"
                                                        value={billingAdd?.email}
                                                        onChange={handleBillingAddChange}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', marginTop: '16px' }} >
                                                    <FormControl fullWidth sx={{ width: '100%' }}>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={countiresList?.map(elm => ({
                                                                label: elm.name,
                                                                value: elm
                                                            }))}
                                                            name="country"
                                                            value={selectedBillCountry?.name}
                                                            renderInput={(params) => <TextField {...params} label="Receiver's Country*" />}
                                                            onChange={(e, val) => {
                                                                if (!val) setSelectedBillCountry(null)
                                                                else setSelectedBillCountry(val.value)
                                                            }}
                                                            size="small"
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth sx={{ width: '100%', marginLeft: "8px" }}>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={selectedBillCountry ? billStatesList?.map(elm => ({
                                                                label: elm.name,
                                                                value: elm
                                                            })) : []}
                                                            name="state"
                                                            value={selectedBillState?.name}
                                                            renderInput={(params) => <TextField {...params} label="Receiver's State" />}
                                                            onChange={(e, val) => {
                                                                if (!val) setSelectedBillState(null)
                                                                else setSelectedBillState(val.value)
                                                            }}
                                                            size="small"
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div style={{ display: 'flex', marginTop: '16px' }} >
                                                    <FormControl fullWidth sx={{ width: '100%' }}>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={selectedBillState ? billCitiesList?.map(elm => ({
                                                                label: elm.name,
                                                                value: elm
                                                            })) : []}
                                                            name="city"
                                                            value={selectBillCity?.name}
                                                            renderInput={(params) => <TextField {...params} label="Receiver's City" />}
                                                            onChange={(e, val) => {
                                                                if (!val) setSelectedBillCity(null)
                                                                else setSelectedBillCity(val.value)
                                                            }}
                                                            size="small"
                                                        />
                                                    </FormControl>
                                                    <TextField
                                                        sx={{ width: '100%', marginLeft: "8px" }}
                                                        id="outlined-basic"
                                                        label="Biller's Zip*"
                                                        variant="outlined"
                                                        defaultValue=""
                                                        size="small"
                                                        name="zip"
                                                        value={billingAdd?.zip}
                                                        onChange={handleBillingAddChange}
                                                    />
                                                </div>
                                                <TextField
                                                    sx={{ width: '100%', margin: "16px 0" }}
                                                    id="outlined-basic"
                                                    label="Biller's Contact No.*"
                                                    variant="outlined"
                                                    defaultValue=""
                                                    size="small"
                                                    name="phone"
                                                    value={billingAdd?.phone}
                                                    onChange={handleBillingAddChange}
                                                />
                                            </>}
                                    </div>
                                </div>
                                <Button disabled={disableButton || loading} onClick={handleNext} variant="contained" disableElevation sx={{ backgroundColor: '#1565c0' }}>
                                    {loading ? "Loading..." : "Next"}
                                </Button>
                            </div>
                        </>
                    }
                    {
                        activeStep === 1 &&
                        <div style={{ width: "100%" }}>
                            <Box sx={{ display: "flex", justifyContent: "space-evenly", width: "100%", flexDirection: {xs:"column", md: "row"} }}>

                                <div className="right-sec" style={{ marginTop: 0 }}>
                                    <div>
                                        <div className='total-item'>
                                            Please select a shipping option:
                                        </div>
                                        <div style={{ marginTop: "32px", display: "flex", flexDirection: "column" }}>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="standard"
                                                name="radio-buttons-group"
                                                onChange={(e) => setShippingOption(e.target.value)}
                                                value={shippingOption}
                                            >
                                                {
                                                    rateList &&
                                                    rateList?.sort((a, b) => a?.rate - b?.rate)
                                                        .map(elm => (
                                                            <FormControlLabel key={elm.id} sx={{ marginTop: '16px' }} onChange={() => setSelectedShippingOpt(elm)} className="note" value={elm?.carrier + elm?.service} control={<Radio />} label={`${elm?.carrier} ${elm?.service} - £${elm?.rate}`} />

                                                        ))
                                                }
                                            </RadioGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className="right-sec" style={{ marginTop: 0 }}>
                                    <Box sx={{marginTop: {xs: "32px", md: 0}}}>
                                        <div className='total-item'>
                                            Purpose of Export:
                                        </div>
                                        <div style={{ marginTop: "32px", display: "flex", flexDirection: "column" }}>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="standard"
                                                name="radio-buttons-group"
                                                onChange={(e) => setExportPurpose(e.target.value)}
                                                value={exportPurpose}
                                            >
                                                <FormControlLabel sx={{ marginTop: '16px' }} className="note" value={"gift"} control={<Radio />} label="Gift" />
                                                <FormControlLabel sx={{ marginTop: '16px' }} className="note" value={"commercial"} control={<Radio />} label="Commercial" />
                                                <FormControlLabel sx={{ marginTop: '16px' }} className="note" value={"merchandise"} control={<Radio />} label="Merchandise" />
                                                <FormControlLabel sx={{ marginTop: '16px' }} className="note" value={"personal"} control={<Radio />} label="Personal" />
                                                <FormControlLabel sx={{ marginTop: '16px' }} className="note" value={"return"} control={<Radio />} label="Return" />
                                            </RadioGroup>
                                        </div>
                                    </Box>
                                </div>
                            </Box>
                            <div style={{ marginTop: '32px', textAlign: "center" }} >
                                <FormControlLabel className="note" onChange={() => setRequireExtendedInsurance(!requireExtendedInsurance)} checked={requireExtendedInsurance} control={<Checkbox />} label="Do you require extended shipping insurance cover to the total value of the shipment? Click for shipping insurance terms" />
                            </div>
                            <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                                <div style={{ width: "340px" }}>
                                    <div>
                                        <p className='total-item' style={{ fontWeight: "normal", marginTop: "16px" }}>Shipping Cost: &#163;{selectedShippingOpt?.rate}</p>
                                        {requireExtendedInsurance && <p className='total-item' style={{ fontWeight: "normal", marginTop: "16px" }}>{`Extended insurance fee: £${packageFee?.insuranceFee?.fixed}`}</p>}
                                        <p className='total-item' style={{ fontWeight: "normal", marginTop: "16px" }}>{`Storage fee: £${totalStorageFee}`}</p>
                                        <p className='total-item' style={{ marginTop: "16px" }}>Total Cost: <span style={{ fontSize: "22px" }}>&#163;{handleTotalCost()?.toLocaleString()}</span></p>
                                    </div>
                                    <Button disabled={!exportPurpose || !shippingOption} variant="contained" disableElevation sx={{ backgroundColor: '#1565c0', marginTop: "16px", width: "100%" }} onClick={() => setActiveStep(2)}>
                                        Next
                                    </Button>
                                </div>

                            </div>
                        </div>
                    }
                    {
                        activeStep === 2 &&
                        <div className="payment-container">
                            <div style={{ width: "100%", marginRight: "64px", height: "calc(100vh - 140px)", overflowY: "auto" }}>
                                <h3 style={{ marginTop: '32px' }} className='sub-heading'>Shipping Address</h3>
                                <div style={{ display: 'flex', justifyContent: "space-between", marginTop: '14px' }}>
                                    <div style={{ width: "50%" }} >
                                        <div className="pkg-dtl-item">
                                            <label>Receiver Name:</label>
                                            <div>{receiverAdd.name}</div>
                                        </div>
                                        <div className="pkg-dtl-item">
                                            <label>Company:</label>
                                            <div>{receiverAdd.company || "-"}</div>
                                        </div>
                                        <div className="pkg-dtl-item">
                                            <label>Address1:</label>
                                            <div>{receiverAdd.street1}</div>
                                        </div>
                                        <div className="pkg-dtl-item">
                                            <label>Address2:</label>
                                            <div>{receiverAdd.street2 || "-"}</div>
                                        </div>
                                        <div className="pkg-dtl-item">
                                            <label>City:</label>
                                            <div>{selectRecCity?.name || "-"}</div>
                                        </div>
                                    </div>
                                    <div style={{ width: "50%" }} >
                                        <div className="pkg-dtl-item">
                                            <label>State:</label>
                                            <div>{selectedRecState?.name || "-"}</div>
                                        </div>
                                        <div className="pkg-dtl-item">
                                            <label>Country:</label>
                                            <div>{selectedRecCountry?.name || "-"}</div>
                                        </div>
                                        <div className="pkg-dtl-item">
                                            <label>Zip:</label>
                                            <div>{receiverAdd.zip || "-"}</div>
                                        </div>
                                        <div className="pkg-dtl-item">
                                            <label>Email:</label>
                                            <div>{receiverAdd.email || "-"}</div>
                                        </div>
                                        <div className="pkg-dtl-item">
                                            <label>Phone No.:</label>
                                            <div>{receiverAdd.phone || "-"}</div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !isBillingAddress &&
                                    <>
                                        <Divider sx={{ marginTop: "32px" }} />
                                        <h3 style={{ marginTop: '32px' }} className='sub-heading'>Billing Address</h3>
                                        <div style={{ display: 'flex', justifyContent: "space-between", marginTop: '14px' }}>
                                            <div style={{ width: "50%" }} >
                                                <div className="pkg-dtl-item">
                                                    <label>Billing Name:</label>
                                                    <div>{billingAdd.name}</div>
                                                </div>
                                                <div className="pkg-dtl-item">
                                                    <label>Company:</label>
                                                    <div>{billingAdd.company || "-"}</div>
                                                </div>
                                                <div className="pkg-dtl-item">
                                                    <label>Address1:</label>
                                                    <div>{billingAdd.street1}</div>
                                                </div>
                                                <div className="pkg-dtl-item">
                                                    <label>Address2:</label>
                                                    <div>{billingAdd.street2 || "-"}</div>
                                                </div>
                                                <div className="pkg-dtl-item">
                                                    <label>City:</label>
                                                    <div>{selectBillCity?.name || "-"}</div>
                                                </div>
                                            </div>
                                            <div style={{ width: "50%" }} >
                                                <div className="pkg-dtl-item">
                                                    <label>State:</label>
                                                    <div>{selectedBillState?.name || "-"}</div>
                                                </div>
                                                <div className="pkg-dtl-item">
                                                    <label>Country:</label>
                                                    <div>{selectedBillCountry?.name || "-"}</div>
                                                </div>
                                                <div className="pkg-dtl-item">
                                                    <label>Zip:</label>
                                                    <div>{billingAdd.zip || "-"}</div>
                                                </div>
                                                <div className="pkg-dtl-item">
                                                    <label>Email:</label>
                                                    <div>{billingAdd.email || "-"}</div>
                                                </div>
                                                <div className="pkg-dtl-item">
                                                    <label>Phone No.:</label>
                                                    <div>{billingAdd.phone || "-"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <Divider sx={{ marginTop: "32px" }} />
                                <h3 style={{ marginTop: '32px' }} className='sub-heading'>Shipping Options</h3>
                                <div style={{ display: 'flex', justifyContent: "space-between", marginTop: '14px' }}>
                                    <div>
                                        <div className="pkg-dtl-item">
                                            <label>Shipping Option:</label>
                                            <div>{shippingOption}</div>
                                        </div>
                                        <div className="pkg-dtl-item">
                                            <label>Purpose of Export:</label>
                                            <div>{exportPurpose}</div>
                                        </div>
                                        <div className="pkg-dtl-item">
                                            <label>Require Extended Shipping Insurance:</label>
                                            <div>{requireExtendedInsurance ? "Yes" : "No"}</div>
                                        </div>
                                    </div>
                                </div>

                                <Divider sx={{ marginTop: "32px" }} />
                                <h3 style={{ marginTop: '32px' }} className='sub-heading'>Pakcages</h3>
                                <div className='pkg-details-body' style={{ width: "100%", marginTop: 0, height: "calc(100vh - 432px)" }}>
                                    {
                                        packages &&
                                        packages.map((elm, ind) => (
                                            <div key={ind} className="row">
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Package No.</div>
                                                    <div className='odr-de-value'>{elm.trackingNumber}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Package ID</div>
                                                    <div className='odr-de-value'>{elm.packageId}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Date Received</div>
                                                    <div className='odr-de-value'>{new Date(elm.dateReceived)?.toLocaleDateString("en-US")}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Instore (days)</div>
                                                    <div className='odr-de-value'>{calculateInStoreDays(elm?.dateReceived)}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Customer ID</div>
                                                    <div className='odr-de-value'>{elm.customer.userId}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Customer Name</div>
                                                    <div className='odr-de-value'>{`${elm.customer.firstName} ${elm.customer.lastName}`}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Delivery Company</div>
                                                    <div className='odr-de-value'>{elm.courierCompany.companyName}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Package Size</div>
                                                    <div className='odr-de-value'>{`${elm.weight}kg ${elm.depth} x ${elm.width} x ${elm.height} cm`}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Package Content</div>
                                                    <div className='odr-de-value'>
                                                        {
                                                            elm?.packageItems?.length ?
                                                                <>
                                                                    {
                                                                        elm?.packageItems?.length > 1 ?
                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                <div className='text-wrap' style={{ maxWidth: "124px", marginRight: "6px" }}>{elm?.packageItems[0]?.item?.itemDescription}</div>
                                                                                <Chip label={`+${elm?.packageItems?.length - 1}`} size="small" />
                                                                            </div>
                                                                            :
                                                                            <div className='text-wrap' style={{ maxWidth: "172px" }}>{elm?.packageItems[0]?.item?.itemDescription}</div>
                                                                    }
                                                                </>
                                                                :
                                                                "-"
                                                        }
                                                    </div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Sender</div>
                                                    <div className='odr-de-value'>{elm.sender?.senderName}</div>
                                                </span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div>
                                <Elements stripe={stripePromise}>
                                    <Checkout
                                        options={{ hidePostalCode: true }}
                                        selectedShippingOpt={selectedShippingOpt}
                                        requireExtendedInsurance={requireExtendedInsurance}
                                        packageFee={packageFee}
                                        totalStorageFee={totalStorageFee}
                                        totalCost={handleTotalCost()?.toLocaleString()}
                                        onAgreeTerms={() => setAgreeTerms(!agreeTerms)}
                                        agreeTerms={agreeTerms}
                                        loading={loading}
                                        handleCheckout={(res, token) => handleCheckout(res, token)}
                                    />
                                </Elements>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>

    </Drawer>
);
}

export default Shipping;