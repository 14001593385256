import { toast } from 'react-toastify';

export const errorToast = (msg) => toast.error(msg, {
    position: toast.POSITION.TOP_CENTER
})

export const warningToast = (msg) => toast.warn(msg, {
    position: toast.POSITION.TOP_CENTER
})

export const infoToast = (msg) => toast.info(msg, {
    position: toast.POSITION.TOP_CENTER
})

export const successToast = (msg) => toast.success(msg, {
    position: toast.POSITION.TOP_CENTER
})