export function convertPackagesToCSV(objArray) {
    let tempArr = [
        ["Package No.", "Package ID", "Date Received", "Instore (days)", "Customer ID", "Customer Name", "Delivery Company", "Package Size", "Sender", "Status"]
    ]

    objArray.forEach((elm, ind) => {
        tempArr.push([
            ind + 1, elm?.packageId, new Date(elm.dateReceived)?.toLocaleDateString("en-US"), calculateInStoreDays(elm?.dateReceived), elm?.customer?.userId, `${elm.customer?.firstName} ${elm.customer?.lastName}`, elm?.courierCompany?.companyName, `${elm.weight}kg ${elm.depth} x ${elm.width} x ${elm.height} cm`, elm?.sender?.senderName, elm?.status
        ])
    });

    let csvContent = "data:text/csv;charset=utf-8,"
        + tempArr.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Packages.csv`);
    document.body.appendChild(link);

    link.click();
}

export function convertOrdersToCSV(objArray) {
    let tempArr = [
        ["Package ID", "Full Name", "Company Name", "Address Line 1", "Address Line 2", "City", "County", "Post Code", "Country", "Phone", "Email", "Service Code", "Package Size", "Weight", "Detail Description", "Unit Weight", "Unit Price", "Quantity", "Value of contents", "Customs code", "Country Of Origin", "Currency", "Shipping cost", "Total cost", "Category", "Explanation of nature of item"]
    ]

    objArray.forEach((elm) => {
        elm.packages.forEach((pkg) => {
            const quantityTotal = pkg.packageItems.reduce((a, b) => a + b.quantity, 0)
            pkg.packageItems.forEach((item, index) => {
                tempArr.push([
                    index === 0 ? pkg.packageId : " ",
                    index === 0 ? elm.receiver?.receivingAddress?.name : " ",
                    index === 0 ? elm.receiver?.receivingAddress?.company : " ",
                    index === 0 ? elm.receiver?.receivingAddress?.street1 : " ",
                    index === 0 ? elm.receiver?.receivingAddress?.street2 : " ",
                    index === 0 ? elm.receiver?.receivingAddress?.city : " ",
                    index === 0 ? elm.receiver?.receivingAddress?.state : " ",
                    index === 0 ? elm.receiver?.receivingAddress?.zip : " ",
                    index === 0 ? elm.receiver?.receivingAddress?.country : " ",
                    index === 0 ? elm.receiver?.receivingAddress?.phone : " ",
                    index === 0 ? elm?.receiver?.receivingAddress?.email : " ",
                    index === 0 ? elm?.selectedCarrier?.serviceCode || elm?.selectedCarrier?.service : " ",
                    index === 0 ? "Parcel" : " ",
                    index === 0 ? pkg.weight : " ",
                    item.description,
                    ((pkg.weight * item?.quantity) / quantityTotal)?.toFixed(2),
                    item?.valueEach,
                    item?.quantity,
                    item?.valueEach * item?.quantity || " ",
                    item?.item?.exportCode?.replace(".", "") || " ",
                    "UK",
                    index === 0 ? "GBP" : " ",
                    index === 0 ? elm?.selectedCarrier?.rate : " ",
                    index === 0 ? elm?.shippingFee : " ",
                    index === 0 ? "O" : " ",
                    index === 0 ? "gift" : " ",
                ])
            })
        })
    });

    let csvContent = "data:text/csv;charset=utf-8,"
        + tempArr.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Royal Mail Orders.csv`);
    document.body.appendChild(link);

    link.click();
}

export function convertExportCodesToCSV(objArray) {
    let tempArr = [
        ["Export code", "Item description", "Item type"]
    ]

    objArray.forEach((elm) => {
        tempArr.push([
            elm?.exportCode, elm?.itemDescription, elm?.itemType
        ])
    });

    let csvContent = "data:text/csv;charset=utf-8,"
        + tempArr.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Export Codes.csv`);
    document.body.appendChild(link);

    link.click();
}

export function convertSendersToCSV(objArray) {
    let tempArr = [
        ["Sender name / company", "Address line 1", "Address line 2", "City", "Postcode/Zip", "State", "Country", "Sender's VAT"]
    ]

    objArray.forEach((elm) => {
        tempArr.push([
            elm?.senderName, elm?.address1, elm?.address2, elm?.city, elm?.zip, elm?.state, elm?.country, elm?.vat
        ])
    });

    let csvContent = "data:text/csv;charset=utf-8,"
        + tempArr.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Senders.csv`);
    document.body.appendChild(link);

    link.click();
}

export function convertUsersToCSV(objArray) {
    let tempArr = [
        ["first Name", "last Name", "email", "customer Reference", "Business name", "Tax ID", "Address line 1", "Address line 2", "City", "Postcode/Zip", "State", "Country", "contact Number", "role"]
    ]

    objArray.forEach((elm) => {
        tempArr.push([
            elm?.firstName, elm?.lastName, elm?.email, "", elm?.businessName, elm?.taxId, elm?.address1, elm?.address2, elm?.city, elm?.zip, elm?.state, elm?.country, elm?.contactNo, elm?.role
        ])
    });

    let csvContent = "data:text/csv;charset=utf-8,"
        + tempArr.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Users.csv`);
    document.body.appendChild(link);

    link.click();
}

export function convertShippingCompaniesToCSV(objArray) {
    let tempArr = [
        ["Service", "Delivery Time", "tracked"]
    ]

    objArray.forEach((elm) => {
        tempArr.push([
            elm?.service, elm?.deliveryTime, elm?.tracked ? "Tracked" : "Not Tracked"
        ])
    });

    let csvContent = "data:text/csv;charset=utf-8,"
        + tempArr.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Shipping Companies.csv`);
    document.body.appendChild(link);

    link.click();
}


export function convertUKDeliveryCompaniesToCSV(objArray) {
    let tempArr = [
        ["Company name"]
    ]

    objArray.forEach((elm) => {
        tempArr.push([
            elm?.companyName
        ])
    });

    let csvContent = "data:text/csv;charset=utf-8,"
        + tempArr.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `UK Delivery Companies.csv`);
    document.body.appendChild(link);

    link.click();
}

const calculateInStoreDays = (receivedDate) => {
    let numberOfDays = 0;
    if (receivedDate) {
        const diff = Math.abs(new Date() - new Date(receivedDate));
        numberOfDays = Math.ceil(diff / (1000 * 3600 * 24))
    }
    return numberOfDays;
}