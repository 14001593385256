import { Button, TextField, Drawer } from "@mui/material";
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import "../../theme.styles.css";
import "./AwaitingActions.styles.css";
import { addPackageItems } from "../../apis";
import { successToast, errorToast } from "../../components/toast/Toast";

const PackageDetails = ({
    open,
    handleClose,
    selectedPackage,
    onSave,
    numberOfItems
}) => {
    const [packageDetails, setPackageDetails] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (selectedPackage) setPackageDetails(selectedPackage);
    }, [selectedPackage])

    const handleValueChange = (index, value) => {
        if (packageDetails) {
            const copyObj = JSON.parse(JSON.stringify(packageDetails));
            copyObj.packageItems[index]["valueEach"] = value;
            setPackageDetails(copyObj)
        }
    }

    const handleQuantityChange = (index, value) => {
        if (packageDetails) {
            const copyObj = JSON.parse(JSON.stringify(packageDetails));
            copyObj.packageItems[index]["quantity"] = value;
            setPackageDetails(copyObj)
        }
    }

    const handleTotalCount = React.useCallback(() => {
        let total = 0;
        if (packageDetails) {
            total = packageDetails.packageItems.reduce((a, b) => Number(a) + Number((b?.valueEach * (b?.quantity || 1)) || 0), 0);
        }

        return total;
    }, [packageDetails])

    React.useEffect(() => {
        if (packageDetails) handleTotalCount();
    }, [packageDetails, handleTotalCount])

    const savePackage = async () => {
        const data = {
            package_id: packageDetails?._id,
            items: packageDetails.packageItems.map(elm => ({
                ...elm,
                item: elm?.item?._id,
                valueEach: Number(elm?.valueEach),
                quantity: Number(elm?.quantity),
            })),
            totalItemsCost: handleTotalCount()
        }
        
        const isQtyValid = data?.items.filter(elm=>elm?.quantity ===0)
        const isValueValid = data?.items.filter(elm=>elm?.valueEach ===0)
        
        if(isQtyValid?.length === 0 && isValueValid?.length === 0) {
            setLoading(true)
            addPackageItems(data)
                .then((pkg) => {
                    if (pkg.status === 201) {
                        setLoading(false);
                        onSave();
                        successToast("Items updated successfully")
                    }
                })
                .catch(() => {
                    setLoading(false)
                    errorToast("There was an error updating items. Please try again later!")
                })
        }
        else {
            errorToast("Value each or quantity is empty!")
        }
    }

    return (
        <Drawer
            anchor={"bottom"}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    height: 'calc(100vh - 80px)',
                    padding: '40px'
                }
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '32px' }}>
                        <h2 className='modal-heading'>Package Details &#10095; {packageDetails?.packageId}</h2>
                        <span style={{ cursor: 'pointer' }} onClick={handleClose}><CloseIcon /></span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <div className='pkg-details-body'>
                            {
                                packageDetails &&
                                packageDetails?.packageItems?.map((elm, ind) => (
                                    <div key={elm?.item?._id + ind} className="row">
                                        <span className='odr-de-item-2'>
                                            <div className='odr-de-label'>Item No.</div>
                                            <div className='odr-de-value'>{ind + 1}</div>
                                        </span>
                                        <span className='odr-de-item-2'>
                                            <div className='odr-de-label'>Export Code</div>
                                            <div className='odr-de-value'>{elm?.item?.exportCode}</div>
                                        </span>
                                        <span className='odr-de-item-2'>
                                            <div className='odr-de-label'>Item Details</div>
                                            <div className='odr-de-value text-wrap' style={{ maxWidth: "220px" }}>{elm?.item?.itemDescription}</div>
                                        </span>
                                        <span className='odr-de-item-2'>
                                            <div className='odr-de-label'>*Quantity</div>
                                            <div className='odr-de-value'>
                                                <div className='odr-de-value'>
                                                <TextField sx={{backgroundColor: "white"}} id="outlined-basic" variant="outlined" size="small" value={elm?.quantity} onChange={(e) => handleQuantityChange(ind, e.target.value)} autoComplete="off" />
                                            </div>
                                            </div>
                                        </span>
                                        <span className='odr-de-item-2'>
                                            <div className='odr-de-label'>*Value Each (£)</div>
                                            <div className='odr-de-value'>
                                                <TextField sx={{backgroundColor: "white"}} id="outlined-basic" variant="outlined" size="small" value={elm?.valueEach} onChange={(e) => handleValueChange(ind, e.target.value)} autoComplete="off" />
                                            </div>
                                        </span>
                                        <span className='odr-de-item-2'>
                                            <div className='odr-de-label'>Item Type</div>
                                            <div className='odr-de-value'>{elm?.item?.itemType}</div>
                                        </span>
                                    </div>
                                ))
                            }
                            <div className='total-item' style={{ marginTop: "32px" }}>
                                Total Item Cost: £{handleTotalCount() || 0}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='modal-bottom-ctas' style={{ justifyContent: "flex-end" }}>
                    <Button variant="contained" disabled={(isNaN(handleTotalCount()) || handleTotalCount() < 0) || loading} disableElevation sx={{ width: '234px', backgroundColor: '#1565c0' }} onClick={savePackage}>
                        {numberOfItems > 1 ? "Save & Goto Next" : "Save"}
                    </Button>
                </div>
            </div>

        </Drawer>
    );
}

export default PackageDetails;