import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const userData = JSON.parse(localStorage.getItem("KToken"));

export const getAllUsers = (queryParams="") => axios.get(`${BASE_URL}/user${queryParams}`, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const resetPassword = (data) => axios.post(`${BASE_URL}/user/resetPassword`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const sendResetEmails = (data) => axios.post(`${BASE_URL}/user/sendResetEmails`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const updateUserData = (data) => axios.patch(`${BASE_URL}/user/`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const deleteUsers = (data) => axios.delete(`${BASE_URL}/user/`, {headers: {
    "Authorization": `Bearer ${userData?.token}`
},
data
});

export const resetPasswordEmail = (data) => axios.post(`${BASE_URL}/user/ResetPasswordEmail`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});