import { useCallback, useEffect, useState } from "react"
import { getOrderByNo, getAllSenders, releasePackage, addPackage, markOrderComplete, refundOrder } from "../../apis";
import { useNavigate, useParams } from "react-router";
import { Button, FormControl, TextField, MenuItem, InputLabel, Select, Checkbox, Dialog, DialogTitle, DialogActions } from "@mui/material";
import "./ClaimOrders.styles.css";
import { errorToast, successToast } from "../../components/toast/Toast";
import { handleUserRole } from '../../utils/userRole';
import { USER_ROLE } from '../../constants/userRole';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';

const ClaimConsolidationOrder = () => {
    const { orderNo } = useParams();
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("KToken"));
    const userRole = userData?.user?.role || userData?.admin?.role
    const [selectedSender, setSelectedSender] = useState(null);
    const [sendersList, setSendersList] = useState([]);
    const [orderDetails, setOrderDetails] = useState(null);
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [packageDetails, setPackageDetails] = useState({
        trackingNumber: "",
        packageWeight: 0,
        packageWidth: 0,
        packageDepth: 0,
        packageHeight: 0
    });
    const [createNewPackage, setCreateNewPackage] = useState([]);
    const [newPackage, setNewPackage] = useState([]);
    const [createNewLoading, setCreateNewLoading] = useState(false);
    const [completeOrderLoading, setCompleteOrderLoading] = useState(false);
    const [disableNextCta, setDisableNextCta] = useState(true);
    const [scannedPkg] = useState("");
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);


    const fetchOrder = useCallback(() => {
        getOrderByNo(orderNo)
            .then((res) => {
                if (res?.status === 200) {
                    setOrderDetails(res?.data?.order)
                    setSelectedSender(res?.data?.order?.packages[0]?.sender)
                }
            })
    }, [orderNo])

    useEffect(() => {
        if (orderNo) {
            fetchOrder()
        }
        fetchSenders()
    }, [orderNo, setOrderDetails, fetchOrder])

    const fetchSenders = async () => {
        const data = await getAllSenders();
        if (data.status === 200) {
            setSendersList(data.data.sender);
        }
    }

    const handleSenderSelect = (event) => {
        event.preventDefault();
        setSelectedSender(event.target.value)
    }

    const handleReleasePkg = (packageId) => {
        const data = {
            orderNo,
            packageId
        }
        releasePackage(data)
            .then((res) => {
                if (res.status === 200) {
                    fetchOrder()
                }
            })
    }

    const handleCheckbox = (pkg) => {
        const copyArr = [...selectedPackages];
        const exist = copyArr.findIndex(elm => elm?._id === pkg?._id)

        if (exist > -1) {
            copyArr.splice(exist, 1);
            setSelectedPackages([
                ...copyArr
            ])
        }
        else {
            setSelectedPackages([
                ...copyArr,
                pkg
            ])
        }
    }

    useEffect(() => {
        console.log(selectedSender);
        if (packageDetails.packageWeight &&
            packageDetails.packageDepth && packageDetails.packageWidth && packageDetails.packageHeight && selectedSender && selectedPackages?.length) setDisableNextCta(false);
        else setDisableNextCta(true);

    }, [selectedPackages, selectedSender, packageDetails])

    const handleCreatePkg = () => {
        if (selectedPackages?.length > 0) {
            setCreateNewLoading(true);
            let packages = [];
            selectedPackages?.forEach(elm => {
                elm?.packageItems.forEach(item => {
                    const exist = packages.findIndex(data => data?.item === item?.item._id)
                    if (exist !== -1) {
                        const extracted = packages.splice(exist, 1)[0]
                        const tempObj = {
                            ...extracted,
                            quantity: extracted?.quantity + 1
                        }
                        packages.push(tempObj);
                    }
                    else {
                        packages.push({
                            item: item?.item._id,
                            quantity: item.quantity,
                            valueEach: item.valueEach,
                            description: item.description,
                            variant: item.variant || ""
                        })
                    }
                })
            })

            const data = {
                package: {
                    customer: selectedPackages[0]?.customer,
                    sender: selectedSender._id,
                    courierCompany: selectedPackages[0]?.courierCompany?._id,
                    trackingNumber: packageDetails.trackingNumber,
                    weight: packageDetails.packageWeight,
                    height: packageDetails.packageHeight,
                    depth: packageDetails.packageDepth,
                    width: packageDetails.packageWidth,
                    packageItems: packages,
                },
                isConsolidate: true
            }

            addPackage(data)
                .then((res) => {
                    if (res.status === 200) {
                        setCreateNewPackage(prev => [...prev, ...selectedPackages])
                        setSelectedPackages([]);
                        setNewPackage(prev => [...prev, res.data?.package])
                        successToast("Package Created Successfully")
                    }
                    setCreateNewLoading(false)
                })
                .catch((err) => {
                    errorToast("There was an error creating package. Please try again later!")
                    setCreateNewLoading(false)
                })

        }
    }

    const handlePackageDetails = (event) => {
        event.preventDefault();
        setPackageDetails((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const handleCompleteOrder = async () => {
        setCompleteOrderLoading(true)
        const data = {
            orderNo,
            orderType: orderDetails?.orderType
        }
        markOrderComplete(data)
            .then((res) => {
                if (res.status === 200) {
                    setCompleteOrderLoading(false)
                    successToast("Order is Completed")
                    navigate("/orders")
                }
            })
            .catch(() => {
                setCompleteOrderLoading(false)
                errorToast("There was an error completing order. Please try again later!")
            })
    }

    const handleScannedPkg = (e) => {
        e.preventDefault();
        const value = e.target.value;
        if (value) {
            const tempArr = orderDetails?.packages.filter(item =>
                createNewPackage.every((elm) => {
                    return elm?._id !== item?._id
                })
            )
            const checkArr = tempArr.filter(elm => elm?.packageId === value)
            if (checkArr?.length) {
                setSelectedPackages((prev) => [...prev, checkArr[0]])
            }
            else {
                setOpenErrorDialog(true);
            }
        }
    }

    const handleCancelOrder = () => {
        setCancelLoading(true)
        const data = {
            orderNo
        }
        refundOrder(data)
            .then((res) => {
                if (res.status === 200) {
                    navigate("/orders")
                    successToast("Order Cancelled Successfuly and Refund In-process")
                    setCancelLoading(false)
                }
            })
            .catch((err) => {
                errorToast(err?.response?.data?.message)
                setCancelLoading(false)
            })
    }

    const handleDialogConfirmation = () => {
        handleCancelOrder();
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '32px' }}>
                <h2 className='modal-heading'>Order No: {orderNo}</h2>
            </div>
            <div className="card-container" style={{ marginTop: '32px' }}>
                <h3 style={{ marginBottom: '32px' }} className='sub-heading'>Sender Details</h3>
                <div >
                    <FormControl fullWidth sx={{ marginRight: '16px', marginBottom: '42px' }}>
                        <InputLabel id="demo-simple-select-label">Search Sender</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedSender}
                            label="Search Sender"
                            onChange={handleSenderSelect}
                        >
                            {
                                sendersList && sendersList.map(elm => (
                                    <MenuItem key={elm._id} value={elm}>{elm.senderName}</MenuItem>

                                ))
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        required
                        sx={{ width: '100%', marginRight: '16px', marginBottom: '22px' }}
                        id="outlined-basic"
                        label="Sender/Company Name"
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={selectedSender && selectedSender?.senderName}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '22px' }}>
                        <TextField
                            sx={{ width: '49%' }}
                            id="outlined-basic"
                            label="Sender's Address 1"
                            variant="outlined"
                            size="small"
                            disabled
                            InputLabelProps={{ shrink: true }}
                            value={selectedSender && selectedSender?.address1}
                        />
                        <TextField
                            sx={{ width: '49%' }}
                            id="outlined-basic"
                            label="Sender's Address 2"
                            variant="outlined"
                            size="small"
                            disabled
                            InputLabelProps={{ shrink: true }}
                            value={selectedSender && selectedSender?.address1}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '22px' }}>
                        <TextField
                            sx={{ width: '49%' }}
                            id="outlined-basic"
                            label="Sender's Country"
                            variant="outlined"
                            size="small"
                            disabled
                            InputLabelProps={{ shrink: true }}
                            value={selectedSender && selectedSender?.country}
                        />
                        <TextField
                            sx={{ width: '49%' }}
                            id="outlined-basic"
                            label="Sender's City"
                            variant="outlined"
                            size="small"
                            disabled
                            InputLabelProps={{ shrink: true }}
                            value={selectedSender && selectedSender?.city}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '22px' }}>
                        <TextField
                            sx={{ width: '49%' }}
                            id="outlined-basic"
                            label="Sender's Zip"
                            variant="outlined"
                            size="small"
                            disabled
                            InputLabelProps={{ shrink: true }}
                            value={selectedSender && selectedSender?.zip}
                        />
                        <TextField
                            sx={{ width: '49%' }}
                            id="outlined-basic"
                            label="Sender's VAT"
                            variant="outlined"
                            size="small"
                            disabled
                            InputLabelProps={{ shrink: true }}
                            value={selectedSender && selectedSender?.vat}
                        />
                    </div>
                </div>
            </div>
            <div className="card-container" style={{ marginTop: '32px' }}>
                <h3 style={{ marginBottom: '32px' }} className='sub-heading'>Package Details</h3>
                {/* <TextField
                    required
                    sx={{ width: '100%', marginBottom: '22px' }}
                    id="outlined-basic"
                    label="Tracking Number"
                    variant="outlined"
                    name="trackingNumber"
                    size="small"
                    value={packageDetails.trackingNumber}
                    onChange={handlePackageDetails}
                /> */}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '22px' }}>
                    <TextField
                        type="number"
                        required
                        sx={{ width: '100%', mr: 2 }}
                        id="outlined-basic"
                        label="Package Weight (kg)"
                        variant="outlined"
                        name="packageWeight"
                        size="small"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        value={packageDetails.packageWeight}
                        onChange={handlePackageDetails}
                    />
                    <TextField
                        type="number"
                        required
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="Package Width (cm)"
                        variant="outlined"
                        name="packageWidth"
                        size="small"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        value={packageDetails.packageWidth}
                        onChange={handlePackageDetails}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TextField
                        type="number"
                        required
                        sx={{ width: '100%', mr: 2 }}
                        id="outlined-basic"
                        label="Package Depth (cm)"
                        variant="outlined"
                        name="packageDepth"
                        size="small"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        value={packageDetails.packageDepth}
                        onChange={handlePackageDetails}
                    />
                    <TextField
                        type="number"
                        required
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="Package Height (cm)"
                        variant="outlined"
                        name="packageHeight"
                        size="small"
                        value={packageDetails.packageHeight}
                        onChange={handlePackageDetails}
                    />
                </div>
            </div>

            <div className="card-container" style={{ marginTop: '32px' }}>
                <h3 style={{ marginBottom: '32px' }} className='sub-heading'>Scan Packages</h3>
                <TextField
                    required
                    sx={{ width: '100%' }}
                    id="outlined-basic"
                    label="Scan Packages"
                    variant="outlined"
                    size="small"
                    value={scannedPkg}
                    onChange={handleScannedPkg}
                />
            </div>

            {orderDetails?.packages.filter(item =>
                createNewPackage.every((elm) => {
                    return elm?._id !== item?._id
                })
            )?.length ?
                <div className="card-container" style={{ marginTop: '32px' }}>
                    {
                        orderDetails &&
                        orderDetails?.packages.filter(item =>
                            createNewPackage.every((elm) => {
                                return elm?._id !== item?._id
                            })
                        )
                            .map((elm, ind) => (
                                <div key={ind} className="row" style={{ flexWrap: "initial" }}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Package No.</div>
                                            <div className='odr-de-value'>{ind + 1}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Package ID</div>
                                            <div className='odr-de-value'>{elm.packageId}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Date Received</div>
                                            <div className='odr-de-value'>{new Date(elm.dateReceived)?.toLocaleDateString("en-US")}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Customer ID</div>
                                            <div className='odr-de-value'>{orderDetails?.customer?.userId}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Customer Name</div>
                                            <div className='odr-de-value'>{orderDetails?.customer?.firstName} {orderDetails?.customer?.lastName}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Delivery Company</div>
                                            <div className='odr-de-value'>{elm?.courierCompany?.companyName}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Order type</div>
                                            <div className='odr-de-value'>{orderDetails?.orderType}</div>
                                        </span>
                                        <span className='odr-de-item'>
                                            <div className='odr-de-label'>Package Size</div>
                                            <div className='odr-de-value'>{`${elm.weight}kg ${elm.depth} x ${elm.width} x ${elm.height} cm`}</div>
                                        </span>
                                    </div>
                                    {
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end" }}>
                                            <Checkbox checked={selectedPackages.findIndex(item => item?._id === elm?._id) !== -1} onClick={() => handleCheckbox(elm)} />
                                            {orderDetails?.packages.filter(item =>
                                                createNewPackage.every((elm) => {
                                                    return elm?._id !== item?._id
                                                })
                                            ).length > 2 && <Button onClick={() => handleReleasePkg(elm?._id)} sx={{ color: '#1565c0', borderColor: '#1565c0', marginBottom: "18px" }} disableElevation variant="outlined" size="small">Release</Button>}
                                        </div>
                                    }
                                </div>
                            ))
                    }
                </div>
                :
                null
            }
            {
                newPackage?.length ?
                    <>
                        {
                            <div className="card-container" style={{ marginTop: '32px' }}>
                                <h3 style={{ marginBottom: '32px' }} className='sub-heading'>Consolidated Packages</h3>
                                {
                                    newPackage?.map((elm, ind) => (
                                        <div key={ind} className="row" style={{ flexWrap: "initial" }}>
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Package No.</div>
                                                    <div className='odr-de-value'>{ind + 1}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Package ID</div>
                                                    <div className='odr-de-value'>{elm.packageId}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Date Received</div>
                                                    <div className='odr-de-value'>{new Date(elm.dateReceived)?.toLocaleDateString("en-US")}</div>
                                                </span>
                                                <span className='odr-de-item'>
                                                    <div className='odr-de-label'>Package Size</div>
                                                    <div className='odr-de-value'>{`${elm.weight}kg ${elm.depth} x ${elm.width} x ${elm.height} cm`}</div>
                                                </span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </>
                    :
                    null
            }

            <div className="card-container" style={{ marginTop: '32px' }}>
                <div style={{ display: 'flex', justifyContent: "flex-end" }} >
                    {handleUserRole([USER_ROLE.superAdmin], userRole) && <Button disabled={cancelLoading} variant="outlined" color="error" disableElevation sx={{ width: '300px', borderWidth: "2px", fontWeight: 600, marginRight: "18px" }} onClick={() => { setOpenDialog(true); }}>
                        {cancelLoading ? "Loading..." : "Refund"}
                    </Button>}
                    <Button variant="outlined" disabled={(createNewPackage?.length !== orderDetails?.packages?.length) || completeOrderLoading} disableElevation sx={{ width: '300px', borderWidth: "2px", fontWeight: 600, marginRight: "18px" }} onClick={handleCompleteOrder}>
                        Complete Order
                    </Button>
                    <Button variant="contained" disabled={disableNextCta || createNewLoading} disableElevation sx={{ width: '300px', backgroundColor: '#1565c0', border: "2px solid", fontWeight: 600 }} onClick={handleCreatePkg}>
                        Create New Package
                    </Button>
                </div>
            </div>

            <ConfirmationDialog
                handleClose={() => setOpenDialog(false)}
                handleAgree={handleDialogConfirmation}
                open={openDialog}
            />
            <Dialog
                open={openErrorDialog}
                onClose={() => setOpenErrorDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    No Package found with the given ID!
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpenErrorDialog(false)} autoFocus>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

};

export default ClaimConsolidationOrder;


const ConfirmationDialog = ({ handleClose, open, handleAgree }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{ display: "flex", alignItems: "center" }}>
                <DangerousRoundedIcon sx={{ fontSize: "54px", color: "#d92e2e" }} /> {"Are you sure, you want to cancel order?"}
            </DialogTitle>
            <DialogActions sx={{ padding: "16px 24px" }}>
                <Button onClick={handleClose}>Disagree</Button>
                <Button disableElevation variant="contained" onClick={handleAgree} autoFocus sx={{ backgroundColor: '#1565c0', borderRadius: "20px" }}>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    )
}