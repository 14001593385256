import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import ImageLogo from "../../assets/logo.png"

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: 16,
        justifyContent: "flex-start",
    },
    invoiceHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 62,
        height: "0px"
    },
    invoiceNumber: {
        fontSize: 16
    },
    logo: {
        width: "36px",
        height: "36px",
        marginRight: 6
    },
    addressSection: {
        flexDirection: "row",
        width: "100%"
    },
    tableContainer: {
        flexGrow: 1
    },
    BillingAddress: {
        borderWidth: "1px",
        borderColor: "#000",
        borderLeftWidth: 0,
        padding: 16,
        paddingTop: 26,
        flexGrow: 1,
    },
    rowBorder: {
        borderBottomWidth: "1px",
        borderColor: "#000",
    },
    boldHeading: {
        fontWeight: "bold",
        fontSize: 12,
        marginBottom: 12,
        marginTop: 12
    },
    directionRow: {
        flexDirection: "row",
    },
    label: {
        fontWeight: 700,
        fontSize: 10,
        marginRight: 6,
        padding: 6
    },
    value: {
        fontSize: 10,
        padding: 6
    },
    borderLeftRight: {
        borderRightWidth: "1px",
        borderLeftWidth: "1px",
        borderColor: "#000"
    },
    borderRight: {
        borderRightWidth: "1px",
        borderColor: "#000"
    },
    borderTop: {
        borderTopWidth: "1px",
        borderColor: "#000"
    }
});

// Create Document Component
export const ProformaInvoice = ({ data, companyData }) => {
    const senderData = data?.packages[0]?.sender;
    const totalValue = [];
    let totalSum = 0;

    if (data) {
        data?.packages?.forEach(elm => {
            elm?.packageItems.forEach(item => {
                totalValue.push(item?.valueEach * item?.quantity)
            })
        })
        totalSum = totalValue.reduce((a, b) => a + b, 0)
    }

    return (<Document>
        <Page size="A3" style={styles.page}>
            {/* Invoice Heade */}
            <View style={styles.invoiceHeader}>
                <View style={{ flexDirection: 'row', alignItems: "center", marginTop: 16 }}>
                    <Image style={styles.logo} src={`${ImageLogo}`} />
                    <Text>KShip</Text>
                </View>
                <Text style={styles.invoiceNumber} >Proforma Invoice</Text>
            </View>

            {/* References */}
            <View>
                <View style={{...styles.directionRow, marginBottom: 3}}>
                    <Text style={{ ...styles.label, padding: 0, fontSize: 12 }}>Our Ref:</Text>
                    <Text style={{ ...styles.value, padding: 0 }}>{data?.orderNo || "-"}</Text>
                </View>
                <View style={{...styles.directionRow, marginBottom: 3}}>
                    <Text style={{ ...styles.label, padding: 0, fontSize: 12 }}>Package Ref:</Text>
                    <Text style={{ ...styles.value, padding: 0 }}>{data?.packages.map(elm => (`${elm?.packageId}${data?.packages?.length > 1 ? ", " : ""}`))}</Text>
                </View>
                <View style={{...styles.directionRow, marginBottom: 3}}>
                    <Text style={{ ...styles.label, padding: 0, fontSize: 12 }}>Customer Name:</Text>
                    <Text style={{ ...styles.value, padding: 0 }}>{`${data?.customer?.firstName} ${data?.customer?.lastName}`}</Text>
                </View>
                <View style={{...styles.directionRow, marginBottom: 3}}>
                    <Text style={{ ...styles.label, padding: 0, fontSize: 12 }}>Customer Reference:</Text>
                    <Text style={{ ...styles.value, padding: 0 }}>{`${data?.customer?.userId}`}</Text>
                </View>
            </View>

            {/* Customer Details & Address Section */}
            <View style={{ ...styles.directionRow }}>

                <View style={{ ...styles.tableContainer, marginRight: 16 }}>
                    <Text style={styles.boldHeading} >Sender/Agent Details (True Shipper)</Text>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder, ...styles.borderTop }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Company</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{senderData?.senderName || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Address 1</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{senderData?.address1 || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Address 2</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{senderData?.address2 || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Postcode/City</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{senderData?.city || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Country</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{senderData?.country || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Sender Name</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{senderData?.senderName}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Telephone</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>-</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Email</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>-</Text>
                    </View>
                </View>

                <View style={{ ...styles.tableContainer }}>
                    <Text style={styles.boldHeading} > </Text>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder, ...styles.borderTop }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Invoice # (optional)</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{data?.orderNo || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Shipping Date</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>-</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Shipment Number</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{data?.orderNo || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Currency</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>GBP Pound Sterling</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Reason for Export</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{data?.purpose}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>&nbsp;</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>&nbsp;</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Receiver VAT Number</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>-</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>TOS (Incoterms)</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>DAP (Delivered at place)</Text>
                    </View>
                </View>
            </View>

            {/* Receiver's Details & Shipper details Section */}
            <View style={{ ...styles.directionRow }}>

                <View style={{ ...styles.tableContainer, marginRight: 16 }}>
                    <Text style={styles.boldHeading} >Receiver's Details (Consignee)</Text>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder, ...styles.borderTop }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Company</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{data?.receiver?.receivingAddress?.company || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>VAT / EORI</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>-</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Address line 1</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{data?.receiver?.receivingAddress?.street1 || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Address line 2</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{data?.receiver?.receivingAddress?.street2 || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Postcode/City</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{data?.receiver?.receivingAddress?.city || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Country</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{data?.receiver?.receivingAddress?.country || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Receiver Name</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{data?.receiver?.receivingAddress?.name || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Telephone</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{data?.receiver?.receivingAddress?.phone || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Email</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{data?.receiver?.receivingAddress?.email || "-"}</Text>
                    </View>
                </View>

                <View style={{ ...styles.tableContainer }}>
                    <Text style={styles.boldHeading} >Shipper's Detail (Agent)</Text>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder, ...styles.borderTop }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Company</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{companyData?.company || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>VAT / EORI</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{companyData?.vat || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Address line 1</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{companyData?.street1 || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Address line 2</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{companyData?.street2 || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Postcode/City</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{companyData?.city || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Country</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{companyData?.country || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Exporter Name</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{companyData?.company || "-"}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Telephone</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>-</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Email</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>{companyData?.email || "-"}</Text>
                    </View>
                </View>
            </View>

            {/* Items List */}
            <View>
                <View style={{ ...styles.directionRow, ...styles.rowBorder, ...styles.borderTop, marginTop: 16 }}>
                    <Text style={{ ...styles.label, margin: 0, width: "28%", ...styles.borderLeftRight }}>Description of goods</Text>
                    <Text style={{ ...styles.label, margin: 0, width: "28%", ...styles.borderRight }}>Retailer name</Text>
                    <Text style={{ ...styles.label, margin: 0, width: "10%", ...styles.borderRight }}>Qty</Text>
                    <Text style={{ ...styles.label, margin: 0, width: "10%", ...styles.borderRight }}>Unit Weight (kg)</Text>
                    <Text style={{ ...styles.label, margin: 0, width: "10%", ...styles.borderRight }}>Unit Value</Text>
                    <Text style={{ ...styles.label, margin: 0, width: "10%", ...styles.borderRight }}>HS Code</Text>
                    <Text style={{ ...styles.label, margin: 0, width: "10%", ...styles.borderRight }}>Country of Origin</Text>
                    <Text style={{ ...styles.label, margin: 0, width: "10%", ...styles.borderRight }}>Total Weight (kg)</Text>
                    <Text style={{ ...styles.label, margin: 0, width: "10%", ...styles.borderRight }}>Total Value</Text>
                </View>
                {
                    data?.packages.map(elm => {
                        const unitWeight = elm.packageItems.reduce((a, b) => a + b.quantity, 0) / elm?.weight

                        return elm.packageItems.map(item => (
                            <View key={item?.item?._id} style={{ ...styles.directionRow, ...styles.rowBorder }}>
                                <Text style={{ ...styles.value, width: "28%", ...styles.borderLeftRight }}>{item?.item?.itemDescription || "-"}</Text>
                                <Text style={{ ...styles.value, width: "28%", ...styles.borderRight }}>{elm?.sender?.senderName || "-"}</Text>
                                <Text style={{ ...styles.value, width: "10%", ...styles.borderRight }}>{item?.quantity}</Text>
                                <Text style={{ ...styles.value, width: "10%", ...styles.borderRight }}>{unitWeight}</Text>
                                <Text style={{ ...styles.value, width: "10%", ...styles.borderRight }}>£{item?.valueEach}</Text>
                                <Text style={{ ...styles.value, width: "10%", ...styles.borderRight }}>{item?.item?.exportCode}</Text>
                                <Text style={{ ...styles.value, width: "10%", ...styles.borderRight }}>UK</Text>
                                <Text style={{ ...styles.value, width: "10%", ...styles.borderRight }}>{unitWeight * item?.quantity}</Text>
                                <Text style={{ ...styles.value, width: "10%", ...styles.borderRight }}>£{item?.valueEach * item?.quantity}</Text>
                            </View>
                        ))
                    })
                }
            </View>

            {/* Totals Table */}
            <View style={{ ...styles.directionRow, marginTop: 16 }}>

                <View style={{ ...styles.tableContainer, width: "50%" }}>
                </View>

                <View style={{ ...styles.tableContainer, width: "50%" }}>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder, ...styles.borderTop }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Total shipment value</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>£{totalSum}</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Discount</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>&nbsp;</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Subtotal</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>&nbsp;</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Insurance costs</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>&nbsp;</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Other costs</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>&nbsp;</Text>
                    </View>
                    <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                        <Text style={{ ...styles.label, width: "40%", ...styles.borderLeftRight }}>Total declared value</Text>
                        <Text style={{ ...styles.value, width: "60%", ...styles.borderRight }}>£{totalSum}</Text>
                    </View>
                </View>
            </View>

            {/* Decleration */}
            <View>
                <Text style={{ fontSize: 18, marginBottom: 6 }} >Decleration</Text>
                <View style={styles.directionRow}>
                    <Text style={{ width: "50%", fontSize: 14 }} >The exporter of the products covered by this document declares that,
                        except where otherwise clearly indicated, these products are of UK
                        preferential origin. The exporter declares that the content of this
                        invoice is true and correct.
                    </Text>
                    <Text style={{ width: "50%" }} ></Text>
                </View>
            </View>

            {/* Company Table */}
            <View>
                <View style={{ ...styles.directionRow, ...styles.rowBorder, ...styles.borderTop, marginTop: 16 }}>
                    <Text style={{ ...styles.label, margin: 0, width: "35%", ...styles.borderLeftRight }}>Name of Exporter</Text>
                    <Text style={{ ...styles.label, margin: 0, width: "35%", ...styles.borderRight }}>Company and Job Title</Text>
                    <Text style={{ ...styles.label, margin: 0, width: "30%", ...styles.borderRight }}>Date</Text>
                </View>
                <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                    <Text style={{ ...styles.value, width: "35%", ...styles.borderLeftRight }}>&nbsp;</Text>
                    <Text style={{ ...styles.value, width: "35%", ...styles.borderRight }}>&nbsp;</Text>
                    <Text style={{ ...styles.value, width: "30%", ...styles.borderRight }}>&nbsp;</Text>
                </View>
            </View>

            {/* Additional Info Table */}
            <View>
                <View style={{ ...styles.directionRow, ...styles.rowBorder, ...styles.borderTop, marginTop: 16 }}>
                    <Text style={{ ...styles.label, margin: 0, width: "100%", ...styles.borderLeftRight }}>Additional Information</Text>
                </View>
                <View style={{ ...styles.directionRow, ...styles.rowBorder }}>
                    <Text style={{ ...styles.value, width: "100%", ...styles.borderLeftRight }}>&nbsp;</Text>
                </View>
            </View>
        </Page>
    </Document>)
};