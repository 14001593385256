import React from 'react';
import { Button, TextField, Divider, Box, CircularProgress } from "@mui/material";
import "../../theme.styles.css";
import "../Packages/Packages.styles.css";
import { getPackageFeeSize, updatePackageFeeSize } from '../../apis';

const Package = () => {
    const [wholePkgData, setWholePkgData] = React.useState(null);
    const [pkgData, setPkgData] = React.useState({
        smallPkg: 0,
        medPkg: 0,
        largePkg: 0,
        xLarge: 0,
        freeStorageDays: 0,
        maxStorageDays: 0
    });
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetchPackageFeeSizeData();
    }, [])

    const fetchPackageFeeSizeData = async () => {
        const pkgData = await getPackageFeeSize();
        if (pkgData.status === 200) {
            setLoading(false);
            setWholePkgData(pkgData?.data?.packageFee);
            const tempData = pkgData?.data?.packageFee;
            setPkgData({
                smallPkg: tempData?.small?.weight,
                medPkg: tempData?.medium?.weight,
                largePkg: tempData?.large?.weight,
                xLarge: tempData?.extraLarge?.weight,
                freeStorageDays: tempData?.freeStorageDays,
                maxStorageDays: tempData?.maxStorageDays
            })
            setLoading(false);
        }
    }

    const handlePkgData = (event) => {
        event.preventDefault();
        setPkgData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const handleSave = async () => {
        setLoading(true);
        const data = {
            ...wholePkgData,
            small: {
                ...wholePkgData.small,
                weight: Number(pkgData?.smallPkg)
            },
            medium: {
                ...wholePkgData.medium,
                weight: Number(pkgData?.medPkg)
            },
            large: {
                ...wholePkgData.large,
                weight: Number(pkgData?.largePkg)
            },
            extraLarge: {
                ...wholePkgData.extraLarge,
                weight: Number(pkgData?.xLarge)
            },
            freeStorageDays: Number(pkgData?.freeStorageDays),
            maxStorageDays: Number(pkgData?.maxStorageDays)
        }

        const tempData = await updatePackageFeeSize(data);
        if (tempData.status === 200) {
            fetchPackageFeeSizeData();
        }
        else setLoading(false);
    }

    return (
        <div>
            <div style={{ marginBottom: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 className="main-heading">Package Settings</h1>
            </div>



            <div className="card-container">
                <h3 className='sub-heading'>Set Package Size</h3>
                <p className='note' style={{ marginTop: '10px' }}>Set package size for shipping base fee and storage fee</p>

                {
                    loading ?
                        (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>)
                        :
                        <>

                            <div style={{ display: 'flex', marginTop: '32px' }} >
                                <TextField
                                    sx={{ width: '100%', marginRight: '16px' }}
                                    id="outlined-basic"
                                    type='number'
                                    label="Small Package (kg)"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="smallPkg"
                                    value={pkgData?.smallPkg}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="outlined-basic"
                                    type='number'
                                    label="Medium Package (kg)"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="medPkg"
                                    value={pkgData?.medPkg}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>

                            <div style={{ display: 'flex', marginTop: '32px' }} >
                                <TextField
                                    sx={{ width: '100%', marginRight: '16px' }}
                                    id="outlined-basic"
                                    type='number'
                                    label="Large Package (kg)"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="largePkg"
                                    value={pkgData?.largePkg}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="outlined-basic"
                                    type='number'
                                    label="XL Package (kg)"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="xLarge"
                                    value={pkgData?.xLarge}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>

                            <Divider sx={{ marginTop: "32px" }} />

                            <h3 style={{ marginTop: '32px' }} className='sub-heading'>Package Storage</h3>

                            <div style={{ display: 'flex', marginTop: '32px' }} >
                                <TextField
                                    sx={{ width: '100%', marginRight: '16px' }}
                                    id="outlined-basic"
                                    type='number'
                                    label="Free Storage Days"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="freeStorageDays"
                                    value={pkgData?.freeStorageDays}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="outlined-basic"
                                    type='number'
                                    label="Maximum Storage Days"
                                    variant="outlined"
                                    defaultValue=""
                                    size="small"
                                    name="maxStorageDays"
                                    value={pkgData?.maxStorageDays}
                                    onChange={handlePkgData}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }} >
                                <Button onClick={handleSave} variant="contained" disableElevation sx={{ width: '200px', backgroundColor: '#1565c0' }} >
                                    Save
                                </Button>
                            </div>
                        </>
                }
            </div>

        </div>
    )
}

export default Package;