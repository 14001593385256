import React, { useEffect } from 'react';
import { CircularProgress, Box, Chip } from "@mui/material";
import "../../theme.styles.css";
import "./AwaitingActions.styles.css";
import PackageDetails from './PackageDetails';
import { getAllPackages } from "../../apis";

const AwaitingActions = () => {

    const [open, setOpen] = React.useState(false);
    const [packages, setPackages] = React.useState([]);
    const [selectedPackage, setSelectedPackage] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetchPackages();
        // eslint-disable-next-line
    }, [])

    const fetchPackages = () => {
        getAllPackages("")
        .then((data)=>{
            if (data.status === 200) {
                setPackages(data.data?.packages)
                setLoading(false);
                if(open && data?.data?.packages?.length) {
                    const filtered = data?.data?.packages?.filter(elm => elm.status === "open" && (elm?.shippingPaymentStatus !== "paid" || elm?.consolidationFeeStatus !== "paid"))
                    if(filtered?.length) setSelectedPackage(filtered[0]);
                }
            }
            else setLoading(false);
        })
        .catch(()=>{
            setLoading(false);
        })
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setSelectedPackage(null);
        setOpen(false);
    }

    const calculateInStoreDays = (receivedDate) => {
        let numberOfDays = 0;
        if (receivedDate) {
            const diff = Math.abs(new Date() - new Date(receivedDate));
            numberOfDays = Math.ceil(diff / (1000 * 3600 * 24))
        }
        return numberOfDays;
    }

    const handlePackageSelection = (pkg) => {
        setSelectedPackage(pkg)
    }

    useEffect(() => {
        if (selectedPackage) handleOpen();
    }, [selectedPackage])

    const onSave = () => {
        fetchPackages();
        if(packages?.filter(elm => elm.status === "open" && (elm?.shippingPaymentStatus !== "paid" || elm?.consolidationFeeStatus !== "paid"))?.length === 1) {
            handleClose()
        }
    };

    return (
        <div>
            <div style={{ marginBottom: '32px' }}>
                <h1 className="main-heading">Awaiting Actions</h1>
            </div>

            <div className="card-container" style={{ marginTop: '32px' }}>
                {
                    loading ?
                        (<Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>)
                        :
                        <>
                            {
                                packages.filter(elm => elm.status === "open" && (elm?.shippingPaymentStatus !== "paid" || elm?.consolidationFeeStatus !== "paid"))?.length === 0 ?
                                    <Box sx={{ display: 'flex', width: "100%", justifyContent: "center", height: "300px", alignItems: "center" }}>
                                        <h1 style={{ color: "#0e284d" }}>No Awaiting Packages!</h1>
                                    </Box>
                                    :
                                    null
                            }
                        </>
                }
                {
                    packages &&
                    packages.filter(elm => elm.status === "open" && (elm?.shippingPaymentStatus !== "paid" || elm?.consolidationFeeStatus !== "paid")) // Same condition for processing but with ===
                        .map((elm, ind) => (
                            <div key={ind} className="row row-hover" onClick={() => handlePackageSelection(elm)}>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Package No.</div>
                                    <div className='odr-de-value'>{ind + 1}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Package ID</div>
                                    <div className='odr-de-value'>{elm.packageId}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Date Received</div>
                                    <div className='odr-de-value'>{new Date(elm.dateReceived)?.toLocaleDateString("en-US")}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Instore (days)</div>
                                    <div className='odr-de-value'>{calculateInStoreDays(elm?.dateReceived)}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Customer ID</div>
                                    <div className='odr-de-value'>{elm.customer.userId}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Customer Name</div>
                                    <div className='odr-de-value'>{`${elm.customer.firstName} ${elm.customer.lastName}`}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Delivery Company</div>
                                    <div className='odr-de-value'>{elm.courierCompany.companyName}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Package Size</div>
                                    <div className='odr-de-value'>{`${elm.weight}kg ${elm.depth} x ${elm.width} x ${elm.height} cm`}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Package Content</div>
                                    <div className='odr-de-value'>
                                    {
                                        elm?.packageItems?.length ?
                                            <>
                                                {
                                                    elm?.packageItems?.length > 1 ?
                                                    <div style={{display:"flex", alignItems:"center"}}>
                                                        <div className='text-wrap' style={{ maxWidth: "124px", marginRight: "6px" }}>{elm?.packageItems[0]?.item?.itemDescription}</div>
                                                        <Chip label={`+${elm?.packageItems?.length-1}`} size="small" />
                                                    </div>
                                        :
                                        <div className='text-wrap' style={{ maxWidth: "172px" }}>{elm?.packageItems[0]?.item?.itemDescription}</div>
                                                }
                                            </>
                                            :
                                            "-"
                                    }
                                    </div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Sender</div>
                                    <div className='odr-de-value'>{elm.sender?.senderName}</div>
                                </span>
                                <span className='odr-de-item'>
                                    <div className='odr-de-label'>Status</div>
                                    <div className='odr-de-value'>Awaiting Action</div>
                                </span>
                            </div>
                        ))
                }
            </div>

            <PackageDetails
                open={open}
                handleClose={handleClose}
                selectedPackage={selectedPackage}
                onSave={onSave}
                numberOfItems={packages?.filter(elm => elm.status === "open" && (elm?.shippingPaymentStatus !== "paid" || elm?.consolidationFeeStatus !== "paid"))?.length}
            />

        </div>
    );
}

export default AwaitingActions;