import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const userData = JSON.parse(localStorage.getItem("KToken"));

export const getUkAllDeliveryCompanies = ()=> axios.get(`${BASE_URL}/deliveryCompany`, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});

export const deleteUkDeliveryCompanies = (data) => axios.delete(`${BASE_URL}/deliveryCompany`, {headers: {
    "Authorization": `Bearer ${userData?.token}`
},
data
});

export const uploadUkDeliveryCompaniesCsv = (data) => axios.post(`${BASE_URL}/deliveryCompany/csv`, data, {headers: {
    "Authorization": `Bearer ${userData?.token}`
}});